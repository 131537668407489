import { gql } from '@apollo/client';
import legacyCartFragment from '../fragments/legacyCart';

const recoverLegacyCart = gql`
  mutation recoverBasketMutation($basketId: String!) {
    recoverBasket(basketId: $basketId) {
      status
      message
      response {
        ...legacyCartFragment
      }
    }
  }
  ${legacyCartFragment}
`;

export default recoverLegacyCart;
