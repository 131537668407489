import { useTheme } from '@emotion/react';

export default function useDotColor(delivery) {
  const theme = useTheme();
  if (delivery?.dateInterval) {
    return theme.palette.PRIMARY_GREEN;
  }

  return theme.palette.WARNING;
}
