import PropTypes from 'prop-types';
import Entry from './Entry';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import {
  DEVICE_LIST,
  FAMILY_SLIDER,
  GRID_CONTAINER,
  IFRAME,
  NOTIFICATION_BAR,
  ONE_BLOCK,
  PRODUCT_LIST,
  ROW_CONTAINER,
  PAGE_HEADER,
} from './constants';
import findFirstH1BlockIndex from './utility/findFirstH1BlockIndex';

const noSpaceBlocks = [IFRAME, PRODUCT_LIST, DEVICE_LIST, NOTIFICATION_BAR, FAMILY_SLIDER];
const selfSpacingBlocks = [GRID_CONTAINER, ROW_CONTAINER, ONE_BLOCK, PAGE_HEADER];

const classes = {
  blockSpacing: (theme) => ({
    padding: '25px 0 25px 0',
    [theme.breakpoints.up('md')]: {
      padding: '50px 0 50px 0',
    },
  }),
  topBlockSpacing: (theme) => ({
    paddingBottom: '25px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '50px',
    },
  }),
};

// In general, only the first block on content pages should not have top spacing.
// However, if entries are placed below other content (ex. subscriptionsPage), the first block should also have top spacing.
const getSpacingStyle = (isFirstBlock, isBelowContent) =>
  isFirstBlock && !isBelowContent ? classes.topBlockSpacing : classes.blockSpacing;

const SectionSpacer = ({ children, className }) => <div className={className}>{children}</div>;

// Fragment is used for nospacing- or selfspacing-blocks
const Fragment = ({ children }) => <>{children}</>;

const PageEntries = (props) => {
  const { blocks, isAgent, isBelowContent } = props;
  const indexOfFirstH1Element = findFirstH1BlockIndex(blocks);

  return (
    <>
      {blocks.map((block, index) => {
        const isFirstBlock = index === 0;
        const spacingStyle = getSpacingStyle(isFirstBlock, isBelowContent);
        const isSelfSpacingBlock = selfSpacingBlocks.includes(block?.type);
        const isNoSpaceBlock = noSpaceBlocks.includes(block.type);
        const Container = isSelfSpacingBlock || isNoSpaceBlock ? Fragment : SectionSpacer;
        return (
          <ErrorBoundary key={block.id}>
            <Container css={spacingStyle}>
              <Entry isAgent={isAgent} {...block} setH1Tag={index === indexOfFirstH1Element} pageStyle={spacingStyle} />
            </Container>
          </ErrorBoundary>
        );
      })}
    </>
  );
};

PageEntries.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  isAgent: PropTypes.bool,
  isBelowContent: PropTypes.bool,
};

PageEntries.defaultProps = {
  blocks: [],
  isAgent: false,
  isBelowContent: false,
};

SectionSpacer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

SectionSpacer.defaultProps = {
  children: null,
  className: null,
};

Fragment.propTypes = {
  children: PropTypes.node,
};

Fragment.defaultProps = {
  children: null,
};

export default PageEntries;
