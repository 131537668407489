import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CartLine, InputField, LoadingButton, SECONDARY_BLACK, Small } from '@3dk/3style';
import { getLuhnRemainder } from './helpers';
import { failedFraudCheck, passedFraudCheck, SmartWatchItemGroupId, VERIFY_STATUS } from './consts';
import { ProductType } from './TradeInPropTypes';
import setDueDiligenceCheck from '../../apollo/mutations/setDueDiligenceCheck';
import getDueDiligenceCheck from '../../apollo/queries/getDueDiligenceCheck';
import verifyModelQuery from '../../apollo/queries/verifyModel';

const ErrorText = 'Et device med dette Imei nummer er allerede tilføjet';
const FraudErrorText = 'Der er et eller andet galt med dit IMEI, måske er der sket en taskefejl?';

const IMEIlength = 15;
const SerialNumberMaxLength = 50;

const styles = {
  buttonContainer: {
    '&.MuiButton-root': {
      display: 'flex',
      margin: '10px auto',
    },
  },
};

const TradeInIMEIField = ({ product, removeProduct, selectedProducts, confirmImei, reservedImeiNumbers }) => {
  const [imei, setImei] = useState('');
  const [error, setError] = useState(false);
  const [failedFraud, setFailedFraud] = useState(false);
  const [loading, setLoading] = useState(false);

  const [postDueDiligence] = useMutation(setDueDiligenceCheck);
  const [getDueDiligence] = useLazyQuery(getDueDiligenceCheck);
  const [verifyModel] = useLazyQuery(verifyModelQuery);

  const validateIMEI = (value) => value.length === IMEIlength && getLuhnRemainder(value);

  const dueDiligenceCheck = async (foxwayModel) => {
    const { data: reportWithId } = await postDueDiligence({ variables: { foxwayModel } });
    // If the reportId === 00000000-0000-0000-0000-000000000000, then foxway has disabled the check
    // hence the report will give nothing usefull and there decline the user
    if (reportWithId?.dueDiligenceCheck === '00000000-0000-0000-0000-000000000000') {
      return {
        fraudCheckResults: [
          {
            result: passedFraudCheck,
          },
        ],
      };
    }

    const { data: report } = await getDueDiligence({
      variables: {
        reportId: reportWithId?.dueDiligenceCheck,
      },
    });
    return report?.dueDiligenceReport;
  };

  const confirmImeiValidation = (imeiValue) => {
    setLoading(true);
    const imeiString = imeiValue.toString();
    const modelDetails = { modelId: product?.id || 0, itemIdentifier: imeiString };
    const modelValidationPromise = verifyModel({ variables: { modelDetails } })
      .then((result) => result.data?.verifyModel === VERIFY_STATUS.VALID)
      .catch(() => false);
    const dueDiligencePromise = dueDiligenceCheck(modelDetails)
      .then(
        (report) =>
          !report || !report.fraudCheckResults[0]?.result || report.fraudCheckResults[0]?.result === failedFraudCheck,
      )
      .catch(() => false);

    Promise.all([modelValidationPromise, dueDiligencePromise])
      .then(([isValidImei, isFraud]) => {
        if (isFraud) {
          setFailedFraud(true);
          return;
        }
        if (isValidImei) {
          confirmImei(imeiString);
          return;
        }
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  const onImeiInputKeyPress = (event) => {
    const currentImei = event.target.value;
    if (event?.key === 'Enter' && validateIMEI(currentImei)) {
      confirmImeiValidation(currentImei);
    }
  };

  const onWatchInputKeyPress = (event) => {
    if (event?.key === 'Enter') {
      confirmImeiValidation(event.target.value);
    }
  };

  const onWatchInputKeyChange = (event) => {
    setFailedFraud(false);
    const currentText = event?.target?.value;
    setImei(currentText);
  };

  const onImeiInputChange = (event) => {
    setFailedFraud(false);
    const currentText = event?.target?.value;
    const noLetters = /^[0-9]*$/;
    if (currentText === '' || noLetters.test(currentText)) {
      setImei(currentText);
      setError(false);
    }
  };

  const productProps = useMemo(() => {
    const productIsWatch = product && product.itemGroupId === SmartWatchItemGroupId;
    if (productIsWatch) {
      return {
        inputProps: {
          type: 'search',
          placeholder: 'IMEI- eller serienummer',
          inputProps: { maxLength: SerialNumberMaxLength },
          onKeyPress: onWatchInputKeyPress,
          handleChange: onWatchInputKeyChange,
        },
        getButtonState: (hasFraudError) => hasFraudError,
      };
    }
    return {
      inputProps: {
        type: 'tel',
        placeholder: 'IMEI nummer',
        inputProps: { maxLength: IMEIlength },
        pattern: '[0-9]{15}',
        onKeyPress: onImeiInputKeyPress,
        handleChange: onImeiInputChange,
      },
      getButtonState: (hasFraudError, imeiValue) => hasFraudError || !validateIMEI(imeiValue),
    };
  }, [product]);

  const acceptImei = () => {
    const alreadyUsedImei = selectedProducts.some((p) => p.imei === imei) || (reservedImeiNumbers || []).includes(imei);
    if (alreadyUsedImei) {
      setError(true);
    } else {
      confirmImeiValidation(imei);
    }
  };

  return (
    <div>
      {product && (
        <CartLine
          image={
            product.pictureUrl
              ? {
                  url: product.pictureUrl,
                  alt: product.productNameShort,
                  title: product.productNameShort,
                }
              : undefined
          }
          productTitle={product.productNameShort}
          removeFromCart={() => removeProduct()}
          price={product.price}
          priceUnit="kr"
          priceDescription="Kontant"
        />
      )}

      <InputField
        {...productProps.inputProps}
        fullWidth
        value={imei}
        error={error || failedFraud}
        helperText={(error && ErrorText) || (failedFraud && FraudErrorText)}
      />

      <Small color={SECONDARY_BLACK}>{t('Trade_In.General.Explanation_Text')}</Small>
      <LoadingButton
        css={styles.buttonContainer}
        disabled={productProps.getButtonState(failedFraud, imei)}
        onClick={() => acceptImei()}
        pending={loading}
      >
        {t('Trade_In.General.Add_Discount_Button_Label')}
      </LoadingButton>
    </div>
  );
};

TradeInIMEIField.propTypes = {
  product: ProductType,
  removeProduct: PropTypes.func.isRequired,
  selectedProducts: PropTypes.arrayOf(ProductType),
  reservedImeiNumbers: PropTypes.arrayOf(PropTypes.string),
  confirmImei: PropTypes.func.isRequired,
};

TradeInIMEIField.defaultProps = {
  product: {},
  selectedProducts: [],
  reservedImeiNumbers: [],
};

export default TradeInIMEIField;
