import { Body, Small } from '@3dk/3style';
import PropTypes from 'prop-types';
import { INCLUDED_PRODUCTS_TYPE } from '../utils/types';
import getIncludedProductText from '../../../../utils/subscriptionHelpers/getIncludedProductText';

const classes = {
  includedProductText: {
    display: 'flex',
    height: '18px',
  },
  finePrint: (theme) => ({
    color: theme.palette.SECONDARY_BLACK,
  }),
  includedProductName: (theme) => ({
    color: theme.palette.SECONDARY_BLACK,
  }),
};

const IncludedProductInfo = ({ includedProducts }) => {
  // The data structure currently supports multiple included products, but the design doesn't
  const selectedIncludedProduct = (includedProducts || [])[0];
  const { termsAndConditions, model, inventoryType, subType } = selectedIncludedProduct || {};

  const finePrint = termsAndConditions?.terms;

  const includedProductText = getIncludedProductText(inventoryType, subType);

  return (
    <>
      <div css={classes.includedProductText}>
        <Body>{includedProductText}</Body>
        &nbsp;
        <Body css={classes.includedProductName}>({model.name})</Body>
      </div>
      <Small css={classes.finePrint}>{finePrint}</Small>
    </>
  );
};

IncludedProductInfo.propTypes = {
  includedProducts: PropTypes.arrayOf(INCLUDED_PRODUCTS_TYPE),
};

IncludedProductInfo.defaultProps = {
  includedProducts: [],
};

export default IncludedProductInfo;
