import { gql } from '@apollo/client';
import linkFragment from './link';
import legalTextFragment from './legalText';

const familySliderFragment = gql`
  fragment FamilySliderFragment on FamilySlider {
    header
    defaultSliderValue
    buttonLabel
    imageTemplate
    hideMobileImage
    images {
      layer
      primary
      size
      topOffset
      image {
        description
        mediaType
        title
        url
      }
    }
    maxAmount
    primarySubscription
    secondarySubscription
    subHeader
    tableHeader
    tableResult
    legalText {
      ...LegalTextFragment
    }
    title
    type
    readMoreLink {
      ...LinkFragment
    }
  }
  ${linkFragment}
  ${legalTextFragment}
`;

export default familySliderFragment;
