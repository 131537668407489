import { gql } from '@apollo/client';

const submitFormstackFormMutation = gql`
  mutation submitForm($submission: FormstackFormSubmission!) {
    submitForm(submission: $submission) {
      id
    }
  }
`;

export default submitFormstackFormMutation;
