// Accepts time stamps as defined by the Date object:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#the_epoch_timestamps_and_invalid_date
const getTimeStampDiff = (startTimeStamp, endTimeStamp) => {
  const differenceInMilliseconds = endTimeStamp - startTimeStamp;
  const differenceInSeconds = differenceInMilliseconds / 1000;
  const differenceInMinutes = differenceInSeconds / 60;
  const differenceInHours = differenceInMinutes / 60;
  const differenceInDays = differenceInHours / 24;
  return {
    days: differenceInDays > 1 ? Math.floor(differenceInDays) : 0,
    hours: differenceInHours > 1 ? Math.floor(differenceInHours % 24) : 0,
    minutes: differenceInMinutes > 1 ? Math.floor(differenceInMinutes % 60) : 0,
    seconds: differenceInSeconds >= 1 ? Math.floor(differenceInSeconds % 60) : 0,
  };
};

export default getTimeStampDiff;
