import { gql } from '@apollo/client';

const productListFragment = gql`
  fragment ProductListFragment on ProductList {
    title
    marketingCards {
      id
      type
      headlineText
      headlineColor
      headlineAlignment
      bodyText
      bodyAlignment
      buttonText
      linkPicker {
        url
        openAs
      }
      buttonColor
      buttonAlignment
      legalText
      legalAlignment
      legalColor
      backgroundColor
      backgroundImage {
        url
      }
      splashText
      splashTextColor
      splashBackgroundColor
      splashXPosition
      splashYPosition
    }
    marketingCardPositions
    filterHeadline
    appliedFilters {
      categories {
        filterKey
        filterOptions
      }
      secondaryCategories {
        filterKey
        order
        headline
        filterOptions
      }
      brands {
        filterKey
        order
        headline
        filterOptions
      }
      colours {
        filterKey
        order
        headline
        filterOptions
      }
      compatibleHardware {
        filterKey
        order
        headline
        filterOptions
      }
    }
  }
`;

export default productListFragment;
