import { Body, Headline, Splash } from '@3dk/3style';
import React from 'react';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import LegalText from '../LegalText';
import Image from '../../../../../../components/Image';
import RenderLink from '../../../../../../utils/richTextRenderOptions/renderLink';
import { accountLevelDiscountProps, imageProps } from './fwaPropTypes';
import linkPropType from '../../../../../../utils/propTypes/link';
import legalTextPropType from '../../../../../../utils/propTypes/legalTextPropType';

const classes = {
  container: (theme) => ({
    display: 'grid',
    gap: '15px',
    textAlign: 'center',
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      margin: 'unset',
      gridAutoColumns: 'min-content',
    },
  }),
  imageContainer: {
    position: 'relative',
    padding: '0 15px',
    '& > *': {
      flexGrow: '1',
    },
  },
  splash: {
    position: 'absolute',
    top: '10px',
    right: '-60px',
    padding: '25px',
  },
  discountContainer: (theme) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 50px',
    borderBottom: `2px dashed ${theme.palette.SECONDARY_BLACK}`,
    marginTop: '10px',
    paddingBottom: '5px',
    textAlign: 'left',
    '& *:last-of-type': {
      textAlign: 'right',
    },
  }),
  button: {
    minHeight: '50px',
    margin: 'auto',
  },
  legalText: (theme) => ({
    color: theme.palette.SECONDARY_BLACK,
  }),
};

const FwaBundleCard = ({ image, accountLevelDiscount, link, legalText }) => {
  const theme = useTheme();
  return (
    <div css={classes.container}>
      <div css={classes.imageContainer}>
        {image && (
          <Image
            sizes={[{ mediaQueryMinWidth: 0, width: image.width, height: image.height }]}
            src={image.url}
            alt={image.description}
          />
        )}
        {accountLevelDiscount && (
          <Splash backgroundColor={theme.palette.PRIMARY_ORANGE} css={classes.splash} size="large">
            <Headline color={theme.palette.TYPOGRAPHY_WHITE}>{accountLevelDiscount.splash}</Headline>
          </Splash>
        )}
      </div>
      {accountLevelDiscount && (
        <div>
          {(accountLevelDiscount.lines || []).map((line) => (
            <div css={classes.discountContainer} key={line.id}>
              <Body>{line.header}</Body>
              <Body>{line.price}</Body>
            </div>
          ))}
        </div>
      )}
      <RenderLink {...link} css={classes.button} />
      <div>
        {(legalText || accountLevelDiscount) && (
          <LegalText
            text={legalText?.text}
            textAlignment={legalText?.textAlignment}
            additionalText={accountLevelDiscount?.disclaimer}
          />
        )}
      </div>
    </div>
  );
};

FwaBundleCard.propTypes = {
  image: PropTypes.shape(imageProps),
  accountLevelDiscount: PropTypes.shape(accountLevelDiscountProps),
  link: PropTypes.shape(linkPropType),
  legalText: PropTypes.shape(legalTextPropType),
};

FwaBundleCard.defaultProps = {
  image: undefined,
  accountLevelDiscount: undefined,
  link: undefined,
  legalText: undefined,
};

export default FwaBundleCard;
