import { useQuery } from '@apollo/client';
import WidthWrapper from 'components/WidthWrapper';
import getHelpPageFooterQuery from 'apollo/queries/getHelpPageFooter';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import { Banner } from '@3dk/3style';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import HelpFooter from './HelpFooter';

const classes = {
  root: (theme) => ({
    marginTop: '20px',
    backgroundColor: theme.palette.PRIMARY_GREY,
    paddingBottom: '20px',
  }),
  headline: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
};

const HelpFooterContainer = ({ isBusiness }) => {
  const { data, error, loading } = useQuery(getHelpPageFooterQuery);

  if (loading) return <Loading />;
  if (error) return <ApolloErrorHandler error={error} />;

  const { helpPageFooter } = data || {};

  return (
    <div css={classes.root}>
      <WidthWrapper>
        <Banner css={classes.headline}>Stadig brug for hjælp?</Banner>
        <HelpFooter footerTabs={helpPageFooter} isBusiness={isBusiness} />
      </WidthWrapper>
    </div>
  );
};

HelpFooterContainer.propTypes = {
  isBusiness: PropTypes.bool,
};

HelpFooterContainer.defaultProps = {
  isBusiness: false,
};

export default HelpFooterContainer;
