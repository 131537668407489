import { useEffect } from 'react';
import loadable from '@loadable/component';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { authenticationStateVar } from './VerifyAuth';
import generateAuthenticationUrl from '../../auth/helpers/generateAuthenticationUrl';
import { CONFIRMATION_SELECTION } from '../../constants/urls';
import Loading from '../../components/Loading';
import ErrorBoundary from '../../components/ErrorBoundary';
import useIsLoggedIn from '../../utils/useIsLoggedIn';
import getConfirmationsQuery from '../../apollo/queries/getConfirmations';
import getCheckoutPageQuery from '../../apollo/queries/getCheckoutPage';
import { CustomerConfirmProvider } from './ConfirmationContainer/CustomerConfirmContext';
import updateCwaCaseIdMutation from '../../apollo/mutations/updateCwaCaseId';
import parseString from '../../utils/parseString';
import URL_SEARCH_PARAMETERS from '../../constants/urlSearchParameters';

const MitIdErrorPage = loadable(() => import('./ConfirmationContainer/MitIdErrorPage'));
const NoOffers = loadable(() => import('./NoOffers'));
const ConfirmationSelection = loadable(() => import('./ConfirmationContainer/ConfirmationSelection'));
const CustomerConfirm = loadable(() => import('../../components/CustomerConfirm'));

const ConfirmationContainer = () => {
  const location = useLocation();
  const params = useParams();
  const { orderReference } = params;
  const searchParams = new URLSearchParams(location.search);
  const caseId = parseString(searchParams.get(URL_SEARCH_PARAMETERS.CASE_ID));
  const { isPublicLogin, loginLoading, loginError } = useIsLoggedIn();
  const [getConfirmations, { loading, data, error }] = useLazyQuery(getConfirmationsQuery, { ssr: false });
  const confirmations = data?.confirmations;

  const {
    loading: checkoutPageLoading,
    error: checkoutPageError,
    data: checkoutPageData,
  } = useQuery(getCheckoutPageQuery);

  const [updateCwaCaseId, { loading: updateCwaCaseIdLoading, error: updateCwaCaseIdError }] =
    useMutation(updateCwaCaseIdMutation);

  useEffect(() => {
    if (loginLoading) return;

    if (isPublicLogin && caseId) {
      updateCwaCaseId({ variables: { caseId } }).then(getConfirmations);
    } else if (isPublicLogin) {
      getConfirmations();
    } else if (!authenticationStateVar().authFailed) {
      localStorage.setItem(
        'authDestinationUrl',
        orderReference ? generatePath(CONFIRMATION_SELECTION, { orderReference }) : CONFIRMATION_SELECTION,
      );
      window.location = generateAuthenticationUrl();
    }
  }, [caseId, getConfirmations, isPublicLogin, loginLoading, orderReference, updateCwaCaseId]);

  const checkoutPage = checkoutPageData?.checkoutPage;

  // isPublicLogin is included to keep showing loading while a navigation occur
  if (loginLoading || loading || !isPublicLogin || checkoutPageLoading || updateCwaCaseIdLoading) {
    return <Loading />;
  }

  if (error || loginError || checkoutPageError || updateCwaCaseIdError) {
    return <ApolloErrorHandler error={error ?? loginError ?? checkoutPageError ?? updateCwaCaseIdError} />;
  }

  if (authenticationStateVar().authFailed) {
    return <MitIdErrorPage />;
  }

  const hasConfirmation = orderReference
    ? confirmations?.some((confirmation) => confirmation.orderRef === orderReference)
    : confirmations;

  if (!hasConfirmation || confirmations?.length === 0) {
    return (
      <ErrorBoundary>
        <NoOffers />
      </ErrorBoundary>
    );
  }

  return (
    <CustomerConfirmProvider
      confirmations={confirmations}
      orderReference={orderReference}
      checkoutPageCms={checkoutPage}
      isAgent={false}
    >
      {orderReference ? <CustomerConfirm /> : <ConfirmationSelection />}
    </CustomerConfirmProvider>
  );
};

export default ConfirmationContainer;
