import { useLocation } from 'react-router-dom';
import parseString from '../parseString';

const useQueryParams = (...params) => {
  const location = useLocation();

  try {
    const parameters = new URLSearchParams(location.search);
    return params.reduce((res, key) => ({ ...res, [key]: parseString(parameters.get(key)) }), {});
  } catch {
    return {};
  }
};

export default useQueryParams;
