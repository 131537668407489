import PropTypes from 'prop-types';
import { Autocomplete, Alert, CrosshairIcon, Box } from '@3dk/3style';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import debounce from 'lodash.debounce';
import { useLazyQuery } from '@apollo/client';
import { useState, useCallback } from 'react';
import { parseURL } from 'ufo';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';
import useAddressPredictions from '../../utils/hooks/useAddressPredictionsHook';
import checkCoverageQuery from '../../apollo/queries/checkCoverage';
import useAccountLevelDiscountPreview from '../../utils/useAccountLevelDiscountPreview';
import AldInformationView from '../AldInformationView/AldInformationView';
import { SEARCH_BAR } from '../../app/routes/pageContainer/page/constants';

const CURRENT_LOCATION = 'currentLocation';

const classes = {
  aldLink: {
    marginTop: '14px',
  },
};

const isOptionEqualToValue = (option, value) =>
  (option.displayName && value.displayName && option.displayName === value.displayName) || false;

const AddressCoverageCheck = ({
  setSelectedAddress,
  setSelectedAddressCoverage,
  includeCurrentLocation,
  label,
  redirectToLandingPages,
  resultingProductPdbId,
}) => {
  const history = useHistory();
  const [getAddressCoverage, { loading: coverageLoading, error: coverageError }] = useLazyQuery(checkCoverageQuery);
  const [addressPredictions, setAddressPredictions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { getAddressesByCurrentLocation, getAddressesBySearchValue } = useAddressPredictions();
  const accountLevelDiscountPreview = useAccountLevelDiscountPreview({ items: [resultingProductPdbId] });

  // useCallback makes sure the same instance of the debounced function is returned between re-renderings.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateAddressPredictions = useCallback(
    debounce((value) => {
      if (!value) {
        setAddressPredictions([]);
      } else {
        getAddressesBySearchValue(value).then((data) => setAddressPredictions(data));
      }
    }, 300),
    [],
  );

  if (coverageLoading) {
    return <Loading colored />;
  }

  // TODO (NOV-2452): create better error handling
  if (coverageError) {
    return <Alert severity="warning" title="Beklager, der er sket en fejl" variant="filled" />;
  }

  const onAddressCheck = (value) => {
    if (value) {
      setSelectedAddress(value.displayName);
      const location = { latitude: `${value.latitude}`, longitude: `${value.longitude}` };
      getAddressCoverage({
        variables: {
          location,
        },
      }).then((data) => {
        const { uploadSpeed, downloadSpeed, recommendedTechnology, landingPage } = data.data.checkCoverage || {};
        if (redirectToLandingPages) {
          const { id, latitude, longitude } = value;
          const query = new URLSearchParams({ addressId: id, latitude, longitude }).toString();
          const { host } = parseURL(landingPage);
          if (host) {
            window.location.href = `${landingPage}?${query}`;
          } else {
            history.push({ pathname: landingPage, search: query });
          }
        }
        setSelectedAddressCoverage({ uploadSpeed, downloadSpeed, recommendedTechnology });
      });
    }
  };

  const handleClose = (event, reason) => {
    // reason can be "toggleInput", "escape", "selectOption", "removeOption", or "blur"
    if (reason === 'toggleInput') {
      setIsOpen(false);
    }
  };

  const onSelection = (event, value, reason) => {
    // reason can be "createOption", "selectOption", "removeOption", "blur" or "clear".
    if (reason === 'selectOption') {
      if (value?.id === CURRENT_LOCATION) {
        setInputValue('');
        getAddressesByCurrentLocation().then((data) => setAddressPredictions(data || []));
        setIsOpen(true);
      } else {
        setInputValue(value.displayName);
        setIsOpen(false);
        onAddressCheck(value);
      }
    }
  };

  const onInputChange = (event, value, reason) => {
    // reason can be "input" (user input), "reset" (programmatic change), or "clear"
    if (reason === 'input') {
      setInputValue(value);
      updateAddressPredictions(value);
    }
  };

  const options = [];
  if (includeCurrentLocation) {
    options.push({ icon: <CrosshairIcon />, displayAlways: true, displayName: 'Tæt på mig', id: CURRENT_LOCATION });
  }

  options.push(...addressPredictions);
  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpen(false);
      }}
    >
      {/* This div is needed to avoid triggering click away event when selecting option in popper component */}
      <div>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
          <Autocomplete
            size="medium"
            iconPosition="end"
            label={label}
            options={options}
            getOptionLabel={(option) => option.displayName}
            onSelection={onSelection}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={(o) => o}
            inputValue={inputValue}
            onInputChange={onInputChange}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            disableCloseOnSelect
            openOnFocus
          />
        </Box>
        <Box sx={{ display: { lg: 'none' } }}>
          <Autocomplete
            size="large"
            iconPosition="end"
            label={label}
            options={options}
            getOptionLabel={(option) => option.displayName}
            onSelection={onSelection}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={(o) => o}
            inputValue={inputValue}
            onInputChange={onInputChange}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={handleClose}
            disableCloseOnSelect
            openOnFocus
          />
        </Box>
        {resultingProductPdbId && (
          <AldInformationView
            css={classes.aldLink}
            accountLevelDiscountPreview={accountLevelDiscountPreview}
            useCase={SEARCH_BAR}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

AddressCoverageCheck.propTypes = {
  setSelectedAddress: PropTypes.func,
  setSelectedAddressCoverage: PropTypes.func,
  includeCurrentLocation: PropTypes.bool,
  label: PropTypes.string,
  redirectToLandingPages: PropTypes.bool,
  resultingProductPdbId: PropTypes.string,
};

AddressCoverageCheck.defaultProps = {
  setSelectedAddress: () => null,
  setSelectedAddressCoverage: () => null,
  label: 'Tjek din adresse',
  includeCurrentLocation: true,
  redirectToLandingPages: false,
  resultingProductPdbId: undefined,
};

export default AddressCoverageCheck;
