import { gql } from '@apollo/client';

const faqFragment = gql`
  fragment FaqFragment on Faq {
    sectionTitle: header
    questions {
      itemId: anchor
      question
      answer
    }
  }
`;

export default faqFragment;
