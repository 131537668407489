import { useCallback, useState } from 'react';

const useGlobalHeaderPanelState = () => {
  // Change name
  const [showSidePanelChangeName, setSidePanelChangeName] = useState(false);
  const toggleSidePanelChangeName = useCallback(
    () => setSidePanelChangeName(!showSidePanelChangeName),
    [showSidePanelChangeName, setSidePanelChangeName],
  );

  // Change password
  const [showSidePanelChangePassword, setSidePanelChangePassword] = useState(false);
  const toggleSidePanelChangePassword = useCallback(
    () => setSidePanelChangePassword(!showSidePanelChangePassword),
    [showSidePanelChangePassword, setSidePanelChangePassword],
  );

  const changeNameSidePanelProps = {
    showSidePanelChangeName,
    toggleSidePanelChangeName,
  };

  const changePasswordSidePanelProps = {
    showSidePanelChangePassword,
    toggleSidePanelChangePassword,
  };

  return {
    changeNameSidePanelProps,
    changePasswordSidePanelProps,
  };
};

export default useGlobalHeaderPanelState;
