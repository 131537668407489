import { Container } from '@3dk/3style';
import PropTypes from 'prop-types';
import SubscriptionSpecifications from './SubscriptionSpecifications';
import ThreeLikeHomeCountries from './ThreeLikeHomeCountries';
import GRID_CONTAINER_TYPE from '../../../utils/propTypes/gridContainerType';
import SIMPLE_TEXT_TYPE from '../../../utils/propTypes/simpleTextType';
import SIMPLE_IMAGE_TYPE from '../../../utils/propTypes/simpleImageType';
import { countryListProps } from '../../../utils/propTypes/countryListBlockProps';
import PageEntries from '../pageContainer/page/PageEntries';

const classes = {
  accordionContainer: (theme) => ({
    paddingBottom: '25px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '50px',
    },
  }),
};

const SubscriptionPageContent = ({ subscriptionPage }) => {
  const { blocks, specificationsJson, specificationsIndexNumber, countryList } = subscriptionPage || {};

  // specificationsIndexNumber is a natural number. Note that it expects the blocks-array to be 1-based.
  const blocksBeforeSpecifications = blocks?.slice(0, specificationsIndexNumber - 1);
  const blocksAfterSpecifications = blocks?.slice(specificationsIndexNumber - 1);

  return (
    <>
      {blocksBeforeSpecifications?.length > 0 && <PageEntries blocks={blocksBeforeSpecifications} isBelowContent />}
      <Container css={classes.accordionContainer}>
        <SubscriptionSpecifications specificationsJson={specificationsJson} />
        <ThreeLikeHomeCountries countryList={countryList} />
      </Container>
      {blocksAfterSpecifications?.length > 0 && <PageEntries blocks={blocksAfterSpecifications} isBelowContent />}
    </>
  );
};

SubscriptionPageContent.propTypes = {
  subscriptionPage: PropTypes.shape({
    blocks: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(GRID_CONTAINER_TYPE),
        PropTypes.shape(SIMPLE_TEXT_TYPE),
        PropTypes.shape(SIMPLE_IMAGE_TYPE),
      ]),
    ).isRequired,
    specificationsJson: PropTypes.string,
    specificationsIndexNumber: PropTypes.number,
    countryList: countryListProps,
  }),
};

SubscriptionPageContent.defaultProps = {
  subscriptionPage: null,
};

export default SubscriptionPageContent;
