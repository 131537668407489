import { gql } from '@apollo/client';
import linkFragment from './link';

const notificationBarFragment = gql`
  fragment NotificationBarFragment on NotificationBar {
    image {
      url
    }
    text
    button {
      ...LinkFragment
    }
    backgroundColor
    textColor
    sticksToTop
  }
  ${linkFragment}
`;

export default notificationBarFragment;
