import { gql } from '@apollo/client';
import linkFragment from './link';

const iconBlockFragment = gql`
  fragment IconBlockFragment on IconBlock {
    id
    icon
    image {
      url
      width
      height
      description
    }
    text
    color
    textColor
    iconSize
    link {
      ...LinkFragment
    }
  }
  ${linkFragment}
`;

export default iconBlockFragment;
