import Cookies from 'js-cookie';
import safeParse from '../safe-json';

export const DATA_LOCATION = {
  COOKIE: 'cookie',
  WINDOW: 'window',
  URL_QUERY: 'url',
};

const getFromObject = (targetObject, path, defVal = null) =>
  (path || '').split('.').reduce((res, pathItem) => res?.[pathItem] ?? defVal, targetObject);

const getDonorData = (configItem) => {
  try {
    switch (configItem.location) {
      case DATA_LOCATION.COOKIE:
        return Cookies.get(configItem.propertyName);
      case DATA_LOCATION.URL_QUERY:
        return new URLSearchParams(window.location.search).get(configItem.propertyName);
      case DATA_LOCATION.WINDOW:
        return getFromObject(window, configItem.propertyName);
      default:
        return null;
    }
  } catch {
    return null;
  }
};

export const getDataToInject = (config) =>
  Object.fromEntries((safeParse(config) || []).map((item) => [item.id, getDonorData(item)]));
