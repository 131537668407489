import { Grid, USP } from '@3dk/3style';
import PropTypes from 'prop-types';

const classes = {
  container: {
    display: 'grid',
    gridTemplate: 'auto / auto auto auto',
  },
  uspLabel: (theme) => ({
    color: theme.palette.PRIMARY_BLACK,
    fontWeight: 'bold',
  }),
};

const UspGrid = ({ className, usps }) => (
  <Grid className={className} container css={classes.container} spacing={2}>
    {usps.map((usp) => (
      <Grid key={usp.label} spacing={0}>
        <USP classes={{ label: classes.uspLabel }} icon={usp.icon} label={usp.label} size="extrasmall" />
      </Grid>
    ))}
  </Grid>
);

UspGrid.propTypes = {
  className: PropTypes.string,
  usps: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

UspGrid.defaultProps = {
  className: null,
};

export default UspGrid;
