import runtimeConfig from 'utils/config';
import verifyReCaptchaTokenQuery from 'apollo/queries/verifyReCaptchaToken';
import { useLazyQuery } from '@apollo/client';

const { GOOGLE_RECAPTCHA_SITE_KEY } = runtimeConfig;
// this is the default score recommended by reCaptcha
const RECAPTCHA_SCORE_THRESHOLD = 0.5;

const useGoogleReCaptcha = () => {
  const [verifyToken] = useLazyQuery(verifyReCaptchaTokenQuery);

  const verifyUserToken = (callBackFunction, action) =>
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(GOOGLE_RECAPTCHA_SITE_KEY, { action }).then((token) => {
        verifyToken({ variables: { token, expectedAction: action, siteKey: GOOGLE_RECAPTCHA_SITE_KEY } }).then(
          (response) => {
            if (response.data.verifyReCaptchaToken.score > RECAPTCHA_SCORE_THRESHOLD) {
              callBackFunction();
            }
          },
        );
      });
    });

  return {
    verifyUserToken,
  };
};

export default useGoogleReCaptcha;
