const createCludoUrlQuery = (options) => {
  const { category, page, query, referalUrl } = options;
  const params = {
    cludoCategory: category,
    cludoinputtype: 'standard',
    cludopage: page ?? 1,
    cludoQuery: encodeURIComponent(query),
    cludorefurl: referalUrl,
  };

  const filteredParams = Object.entries(params).filter(([_, value]) => value);
  const urlSearchParams = new URLSearchParams(filteredParams).toString();
  return `?${urlSearchParams}`;
};

export default createCludoUrlQuery;
