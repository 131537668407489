import { useEffect } from 'react';

const ACCOUNT_SELECTED_EVENT = 'account:selected';

export const triggerAccountChangeEvent = (data) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dispatchEvent(new CustomEvent(ACCOUNT_SELECTED_EVENT, { detail: data }));
};

export const useAccountChangeEvent = (handler, deps) => {
  useEffect(() => {
    window.addEventListener(ACCOUNT_SELECTED_EVENT, handler);
    return () => {
      window.removeEventListener(ACCOUNT_SELECTED_EVENT, handler);
    };
  }, deps);
};
