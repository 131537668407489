import { createContext, useMemo } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import confirmationPropType from '../../../utils/propTypes/confirmation';
import { AGENT_START_PAGE, CONFIRMATION_SELECTION, CUSTOMER_CONFIRM, VIEW_OFFER } from '../../../constants/urls';

export const CustomerConfirmContext = createContext(null);

export const CustomerConfirmProvider = ({ confirmations, orderReference, checkoutPageCms, isAgent, children }) => {
  let goBackUrl;
  if (isAgent) {
    goBackUrl = AGENT_START_PAGE;
  } else if (confirmations.length > 1) {
    goBackUrl = CONFIRMATION_SELECTION;
  }

  const context = useMemo(
    () => ({
      confirmations,
      confirmation: confirmations?.find((conf) => conf.orderRef === orderReference),
      checkoutPageCms,
      portInInformationText: checkoutPageCms?.confirmStep?.portInInformationText,
      termsOfSaleAndDelivery: checkoutPageCms?.confirmStep?.termsOfSaleAndDelivery,
      customerInformationHeadline: t('Checkout.Contact_Information_Step.Headline'),
      orderConfirmationHeadline: t('Checkout.Accept_Step.Headline'),
      readOnly: isAgent,
      confirmationBaseUrl: isAgent ? VIEW_OFFER : CUSTOMER_CONFIRM,
      goBackUrl,
    }),
    [confirmations, checkoutPageCms, isAgent, goBackUrl, orderReference],
  );

  return <CustomerConfirmContext.Provider value={context}>{children}</CustomerConfirmContext.Provider>;
};

CustomerConfirmProvider.propTypes = {
  confirmations: PropTypes.arrayOf(confirmationPropType).isRequired,
  orderReference: PropTypes.string.isRequired,
  checkoutPageCms: PropTypes.shape({
    confirmStep: PropTypes.shape({
      portInInformationText: PropTypes.string,
      termsOfSaleAndDelivery: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
  isAgent: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
