import { gql } from '@apollo/client';
import confirmationFragment from '../fragments/confirmation';

const getAcceptedConfirmationQuery = gql`
  query AcceptedConfirmation($orderReference: String!) {
    acceptedConfirmation(orderReference: $orderReference) {
      ...Confirmation
    }
  }
  ${confirmationFragment}
`;

export default getAcceptedConfirmationQuery;
