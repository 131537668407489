import { gql } from '@apollo/client';
import linkFragment from './link';

const productSliderFragment = gql`
  fragment ProductSliderFragment on ProductSlider {
    header
    subheaderLink {
      ...LinkFragment
    }
    anchor
    productVariants {
      variantId
      isLegacyDevice
    }
  }
  ${linkFragment}
`;

export default productSliderFragment;
