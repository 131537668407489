import menuConfig from './menuConfig';

const BASKET_UPDATED_DC = 'basket:updated:dc';

const generateEvent = (name, detail) =>
  new CustomEvent(name, {
    detail: { ...detail },
  });

const eventEmitter = {
  updateCountOfProductsInCart: (count) => {
    const { placeholderCart } = menuConfig;

    const event = generateEvent(BASKET_UPDATED_DC, {
      ...placeholderCart,
      count,
    });
    window.dispatchEvent(event);
  },
};

export default eventEmitter;
