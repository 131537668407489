import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import PAGE_TYPE from 'constants/pageTypes';
import getContextFromPage from './page/utility/getContextFromPage';

const ContentfulContext = createContext(null);

const ContentfulProvider = ({ children, data, contentfulPageType }) => {
  const context = getContextFromPage(data, contentfulPageType);
  return <ContentfulContext.Provider value={context}>{children}</ContentfulContext.Provider>;
};

ContentfulProvider.propTypes = {
  children: PropTypes.node.isRequired,
  contentfulPageType: PropTypes.oneOf(Object.values(PAGE_TYPE)).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    pageTitle: PropTypes.string,
  }).isRequired,
};

export const useContentfulContext = () => {
  const contentfulContext = useContext(ContentfulContext);
  const isDeviceListPage = contentfulContext?.pageType?.toLowerCase() === PAGE_TYPE.DEVICE_LIST_PAGE;
  const isCategoryPage = contentfulContext?.pageType?.toLowerCase() === PAGE_TYPE.PAGE_CATEGORY_TYPE;
  return { contentfulContext, isDeviceListPage, isCategoryPage };
};

export default ContentfulProvider;
