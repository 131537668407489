import { useLazyQuery } from '@apollo/client';
import searchAddressQuery from '../../apollo/queries/searchAddress';
import searchAddressByCoordinatesQuery from '../../apollo/queries/searchAddressByCoordinates';
import runtimeConfig from '../config';

const isDevelopment = runtimeConfig.NODE_ENV === 'development';
const positionMock = {
  coords: {
    latitude: 55.6662784,
    longitude: 12.5272064,
  },
};

const useAddressPredictions = () => {
  const [searchAddress] = useLazyQuery(searchAddressQuery);
  const [searchAddressByCoordinates] = useLazyQuery(searchAddressByCoordinatesQuery);

  const getAddressesBySearchValue = async (value) =>
    searchAddress({
      variables: {
        searchQuery: value,
      },
    }).then((data) => data.data.searchAddress);

  const getAddressesCloseToLocation = async (position, expandRadiusByFactor) => {
    const radius = 20 * expandRadiusByFactor;
    if (radius > 160) {
      return null;
    }

    const variables = {
      longitude: position.coords.longitude,
      latitude: position.coords.latitude,
      radius,
    };

    return searchAddressByCoordinates({ variables }).then((data) => {
      const addresses = data.data.searchAddressByCoordinates;
      return addresses.length > 0 ? addresses : getAddressesCloseToLocation(position, expandRadiusByFactor + 1);
    });
  };

  const getAddressesByCurrentLocation = () =>
    new Promise((resolve) => {
      if (isDevelopment) {
        resolve(positionMock);
        return;
      }
      // getCurrentPosition returns undefined, so we wrap it in a promise to allow us waiting for it
      navigator.geolocation.getCurrentPosition((position) => resolve(position));
    }).then((position) => getAddressesCloseToLocation(position, 1));

  return {
    getAddressesByCurrentLocation,
    getAddressesBySearchValue,
  };
};

export default useAddressPredictions;
