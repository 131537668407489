import { gql } from '@apollo/client';

const getPagesQuery = gql`
  query getPages($pageType: String) {
    pages(pagesPayload: { filter: { pageType: $pageType } }) {
      id
      type
      pageTitle
      pageType
      slug
      hideFromSearchEngines
    }
  }
`;

const getCategoryPagesQuery = gql`
  query getCategoryPages {
    pages(pagesPayload: { filter: { pageType: "Category" } }) {
      id
      type
      pageTitle
      slug
      blocks {
        ... on ProductList {
          appliedFilters {
            categories {
              filterKey
              filterOptions
            }
          }
        }
      }
    }
  }
`;

export { getPagesQuery, getCategoryPagesQuery };
