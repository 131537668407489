import { gql } from '@apollo/client';
import accessoryV4Fragment from '../../fragments/accessoryV4';

const getAccessoryBySlugAgentQuery = gql`
  query accessoryBySlugAgent($id: String!) {
    accessoryBySlugAgent(slug: $id) {
      ...AccessoryV4
    }
  }
  ${accessoryV4Fragment}
`;

export default getAccessoryBySlugAgentQuery;
