import { gql } from '@apollo/client';
import seoFieldsFragment from '../fragments/seoFields';
import blockFragment from '../fragments/block';
import gridContainerFragment from '../fragments/gridContainer';

const getExternalPagesQuery = gql`
  query getExternalPage($slug: String!) {
    externalPage(slug: $slug) {
      ...SeoFieldsFragment
      id
      type
      title
      externalPageType
      source
      slug
      topBlocks {
        ...BlockFragment
        ...GridContainerFragment
      }
      bottomBlocks {
        ...BlockFragment
        ...GridContainerFragment
      }
    }
  }
  ${blockFragment}
  ${seoFieldsFragment}
  ${gridContainerFragment}
`;

export default getExternalPagesQuery;
