import { Autocomplete, List, Link } from '@3dk/3style';
import React, { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import getPredictedModels from '../../apollo/queries/getPredictedModels';
import { Descriptions, ThisIsHow } from './consts';

const styles = {
  autocompleteContainer: {
    minHeight: '100px',
    paddingTop: '20px',
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    fontSize: '0.8rem',
  },
};

const TradeInAutoComplete = ({ onProductSelected, exitButtonLabel, onBackClick }) => {
  const [getModels, { loading: modelLoading, data: modelData }] = useLazyQuery(getPredictedModels);

  const handleInput = useCallback(
    debounce((_, input) => {
      if (input) {
        getModels({
          variables: {
            modelName: input.toString(),
          },
        });
      }
    }, 300),
    [getModels],
  );

  const productSelected = (event, option) => {
    // no need to fetch suggestions anymore
    handleInput.cancel();
    // if the user presses "Enter" too fast, the input string is selected, instead of an option
    // One should be able to fix this, by adding {...rest} to auto-complete in styles, and make use of
    // the props "loading" and "loadingText".
    if (!option || typeof option === 'string') {
      return;
    }

    const url = (option.pictureUrl && decodeURI(option.pictureUrl)) || '';

    onProductSelected({
      id: option.id,
      itemGroupId: option.itemGroupId,
      pictureUrl: url,
      price: 0,
      productNameShort: option.productNameShort,
    });
  };

  return (
    <>
      <div css={styles.autocompleteContainer}>
        <Autocomplete
          options={modelData?.predictedModels.data || []}
          onSelection={productSelected}
          placeholder={t('Trade_In.General.Searchbar_Placeholder_Text')}
          onInputChange={handleInput}
          getOptionLabel={(option) => option.productNameShort}
          roundedEdges
          noOptionsText={t('Product_Page.Trade_In.No_Search_Options_Text')}
          loading={modelLoading}
          filterOptions={(option) => option}
          /* eslint-disable jsx-a11y/anchor-is-valid */
        />
        <div css={styles.linkWrapper}>
          <Link onClick={onBackClick} css={styles.link}>
            {exitButtonLabel}
          </Link>
        </div>
      </div>
      <List defaultIconColor="secondary" variant="index" header={ThisIsHow} listItems={Descriptions} />
    </>
    /* eslint-enable jsx-a11y/anchor-is-valid */
  );
};

TradeInAutoComplete.propTypes = {
  onProductSelected: PropTypes.func.isRequired,
  exitButtonLabel: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

TradeInAutoComplete.defaultProps = {};

export default TradeInAutoComplete;
