import PAGE_TYPE from 'constants/pageTypes';

const getContextFromPage = (pageData, pageType) => {
  switch (pageType) {
    case PAGE_TYPE.PAGE:
      return {
        pageTitle: pageData.pageTitle,
        pageType: PAGE_TYPE.PAGE,
      };
    case PAGE_TYPE.CONTENT_PAGE:
      return {
        pageTitle: pageData.title,
        pageType: PAGE_TYPE.CONTENT_PAGE,
      };
    case PAGE_TYPE.PAGE_CONTENT_TYPE:
      return {
        pageTitle: pageData.pageTitle,
        pageType: PAGE_TYPE.PAGE_CONTENT_TYPE,
      };
    case PAGE_TYPE.PAGE_CATEGORY_TYPE:
      return {
        pageTitle: pageData.pageTitle,
        pageType: PAGE_TYPE.PAGE_CATEGORY_TYPE,
      };
    case PAGE_TYPE.EXTERNAL_PAGE:
      return {
        pageTitle: pageData.title,
        pageType: PAGE_TYPE.EXTERNAL_PAGE,
      };
    case PAGE_TYPE.DEVICE_LIST_PAGE:
      return {
        pageTitle: pageData.title,
        pageType: PAGE_TYPE.DEVICE_LIST_PAGE,
      };
    case PAGE_TYPE.SUBSCRIPTION_PAGE:
      return {
        pageTitle: pageData.title,
        pageType: PAGE_TYPE.SUBSCRIPTION_PAGE,
      };
    case PAGE_TYPE.RECEIPT_PAGE:
      return {
        pageTitle: pageData.title,
        pageType: PAGE_TYPE.RECEIPT_PAGE,
      };
    default:
      return {
        pageTitle: null,
        pageType: null,
      };
  }
};
export default getContextFromPage;
