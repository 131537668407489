import { gql } from '@apollo/client';

const getCampaignDetailsQuery = gql`
  query campaignDetails($campaignId: Int) {
    campaignDetails(campaignId: $campaignId) {
      id
      name
      status
    }
  }
`;

export default getCampaignDetailsQuery;
