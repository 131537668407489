import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Body, Caption, PRIMARY_BLACK, SECONDARY_BLACK, Title } from '@3dk/3style';
import { t } from 'i18next';
import TradeInQuestions from './TradeInQuestions';
import { VIEW_STEP, TradeInVariants } from './consts';
import TradeInAutoComplete from './TradeInAutoComplete';
import getPriceQuoteQuery from '../../apollo/queries/getPriceQuote';
import getAllPriceQuotesQuery from '../../apollo/queries/getAllPriceQuotes';
import TradeInIMEIField from './TradeInIMEIField';
import { getBestCampaignDiscount, mapEditingProductToChosen, mapPriceQuoteToCampaign } from './helpers';
import TradeInConfirmView from './TradeInConfirmView';
import { CampaignType, ProductType, TradeInDeviceType } from './TradeInPropTypes';
import addTradeInToBasketMutation from '../../apollo/mutations/addTradeInToBasket';
import legacyCartQuery from '../../apollo/queries/legacyCartQuery';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '50px 20px',
    height: '1px',
    boxSizing: 'content-box',
    fontSize: '1rem',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '4rem',
  },
  title: {
    paddingBottom: '10px',
  },
  bodyText: {
    paddingBottom: '30px',
  },
  legalText: {
    paddingBottom: '5rem',
  },
};

const noTradeIn = {
  variables: {
    tradeInData: {
      campaignId: null,
      devices: [],
      tradeInCampaignDiscount: null,
      tradeInDiscount: 0,
    },
  },
};

const TradeIn = ({ saveToBasket, device, initData, campaignAccepted, onTradeInCanceled }) => {
  // state
  const [editingProduct, setEditingProduct] = useState(undefined);
  const [currentStep, setCurrentStep] = useState(VIEW_STEP.searchStep);
  const [chosenProducts, setChosenProducts] = useState([]);
  const [campaignDiscounts, setCampaignDiscounts] = useState([]);
  // queries
  const [getPriceQuote, { loading: priceQuoteLoading }] = useLazyQuery(getPriceQuoteQuery, {
    fetchPolicy: 'network-only',
  });
  const [getAllPriceQuotes, { loading: priceQuotesLoading }] = useLazyQuery(getAllPriceQuotesQuery, {
    fetchPolicy: 'network-only',
  });
  const [addTradeInDataToBasket] = useMutation(addTradeInToBasketMutation, { refetchQueries: [legacyCartQuery] });

  useEffect(() => {
    setChosenProducts(initData.products);
    setCampaignDiscounts(initData.campaigns);
    if (initData.products.length) {
      setCurrentStep(VIEW_STEP.confirmStep);
    }
  }, [initData, setChosenProducts, setCampaignDiscounts, setCurrentStep]);

  const productSelected = (product) => {
    setEditingProduct(product);
    setCurrentStep(VIEW_STEP.questionStep);
  };

  const cancelSearch = () => {
    setCurrentStep(VIEW_STEP.confirmStep);
  };

  const closeWithoutDevices = () => {
    addTradeInDataToBasket(noTradeIn)
      .then(() => onTradeInCanceled())
      .catch(() => alert('Handlingen kan ikke udføres, prøv igen'));
  };

  const removeTradeInDevicesAndReturnToSearch = () => {
    addTradeInDataToBasket(noTradeIn)
      .then(() => {
        setChosenProducts([]);
        setCurrentStep(VIEW_STEP.searchStep);
      })
      .catch(() => alert('Handlingen kan ikke udføres, prøv igen'));
  };

  const confirmPrice = (product) => {
    setChosenProducts(chosenProducts.concat([mapEditingProductToChosen(product)]));
    setEditingProduct(undefined);
    setCurrentStep(VIEW_STEP.confirmStep);
  };

  const updatePriceQuote = async (answers, tradeInDevice) => {
    const response = await getPriceQuote({
      variables: {
        questionnaireAnswers: {
          modelId: tradeInDevice?.id ?? 0,
          answers,
          campaignId: device.campaignId,
        },
      },
    });

    const priceQuote = response.data?.priceQuote || {};
    const campaign = mapPriceQuoteToCampaign(priceQuote, device);
    if (campaign) {
      setCampaignDiscounts(campaignDiscounts.concat([campaign]));
    }

    return {
      ...tradeInDevice,
      answers,
      price: priceQuote.offeredPrice || 0,
      priceQuoteId: priceQuote.priceQuoteId,
    };
  };

  const questionsAnswered = (answers) => {
    if (editingProduct) {
      updatePriceQuote(
        answers.filter((answer) => answer >= 0),
        editingProduct,
      ).then((editDevice) => {
        setEditingProduct(editDevice);
        // we validate if device is stolen or not so we check imai for that
        // in case of just price check, imai validation is not required
        if (saveToBasket) {
          setCurrentStep(VIEW_STEP.IMEIStep);
        } else {
          confirmPrice(editDevice);
          setCurrentStep(VIEW_STEP.confirmStep);
        }
      });
    }
  };

  const resetEditingProduct = () => {
    setEditingProduct(undefined);
  };

  /**
   * If you are removing product while product is on IMEIStep, it will have price impact
   * if you are on step before that resetting step would be enough
   */
  const removeEditingProduct = () => {
    if (currentStep === VIEW_STEP.IMEIStep) {
      setCampaignDiscounts((discounts) => (discounts || []).slice(0, -1));
    }
    resetEditingProduct();
    setCurrentStep(chosenProducts.length > 0 ? VIEW_STEP.confirmStep : VIEW_STEP.searchStep);
  };

  const confirmImei = async (imei) => {
    confirmPrice({ ...editingProduct, imei });
  };

  const removeProductConfirmView = async (index) => {
    const newProducts = chosenProducts.filter((_product, i) => i !== index);
    setChosenProducts(newProducts);
    const campaignResponse = await getAllPriceQuotes({
      variables: {
        questionnaireAnswers: newProducts
          .filter((product) => product.answers)
          .map((product) => ({
            modelId: product.id || 0,
            answers: product.answers,
            campaignId: device.campaignId,
          })),
      },
    });
    const campaigns = (campaignResponse.data?.priceQuotes || [])
      .map((priceQuote) => mapPriceQuoteToCampaign(priceQuote, device))
      .filter(Boolean);

    setCampaignDiscounts(campaigns);
    if (!newProducts.length) {
      setCurrentStep(VIEW_STEP.searchStep);
    }
  };

  const addAnotherDevice = () => {
    setCurrentStep(VIEW_STEP.searchStep);
  };

  const customerState = () => {
    switch (currentStep) {
      case VIEW_STEP.searchStep:
        return (
          <TradeInAutoComplete
            onProductSelected={productSelected}
            exitButtonLabel={chosenProducts.length ? 'Tilbage' : 'Jeg ønsker ikke at aflevere noget'}
            onBackClick={chosenProducts.length ? cancelSearch : closeWithoutDevices}
          />
        );
      case VIEW_STEP.questionStep:
        return (
          <TradeInQuestions
            confirmQuestions={questionsAnswered}
            removeProduct={removeEditingProduct}
            product={editingProduct}
          />
        );
      case VIEW_STEP.IMEIStep:
        return (
          <TradeInIMEIField
            confirmImei={confirmImei}
            removeProduct={removeEditingProduct}
            product={editingProduct}
            selectedProducts={chosenProducts}
            reservedImeiNumbers={initData.reservedImeiNumbers}
            loadingPrice={priceQuoteLoading || priceQuotesLoading}
          />
        );
      case VIEW_STEP.confirmStep:
        return (
          <TradeInConfirmView
            products={chosenProducts}
            device={device}
            removeProduct={
              chosenProducts.length === 1 ? removeTradeInDevicesAndReturnToSearch : removeProductConfirmView
            }
            addMore={addAnotherDevice}
            campaignDiscount={getBestCampaignDiscount(campaignDiscounts)}
            acceptDiscount={campaignAccepted}
            saveToBasket={saveToBasket}
          />
        );
      default:
        return null;
    }
  };

  const tradeInVariant = saveToBasket ? TradeInVariants.PAGE : TradeInVariants.STANDALONE;

  return (
    <div css={styles.root}>
      <div css={styles.contentContainer}>
        <Title css={styles.title}>{t('Trade_In.General.Header')}</Title>
        <Body css={styles.bodyText}>{t('Trade_In.General.Body_Text')}</Body>
        {customerState()}
      </div>
      <div css={styles.legalText}>
        <Caption color={PRIMARY_BLACK}>{t('Trade_In.General.Bottom_Header')}</Caption>
        <Caption color={SECONDARY_BLACK}>{t(`Trade_In.${tradeInVariant}.Bottom_Legal_Text`)}</Caption>
      </div>
    </div>
  );
};

TradeIn.propTypes = {
  saveToBasket: PropTypes.bool,
  device: TradeInDeviceType.isRequired,
  initData: PropTypes.shape({
    campaigns: PropTypes.arrayOf(CampaignType).isRequired,
    products: PropTypes.arrayOf(ProductType).isRequired,
    reservedImeiNumbers: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  campaignAccepted: PropTypes.func,
  onTradeInCanceled: PropTypes.func,
};

TradeIn.defaultProps = {
  saveToBasket: false,
  campaignAccepted: () => null,
  onTradeInCanceled: () => null,
};

export default TradeIn;
