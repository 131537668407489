import { useQuery } from '@apollo/client';
import getContentPageQuery from 'apollo/queries/getContentPage';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import { HELP_PAGE_BASE, NEW_HELP_PAGE_BASE } from 'constants/urls';
import PAGE_TYPE from 'constants/pageTypes';
import Page from './pageContainer/Page';
import HelpFooterContainer from './helpFooter/HelpFooterContainer';
import ContentfulProvider from './pageContainer/ContentfulContext';

const ContentPage = (props) => {
  const { slug } = props;
  const queryOptions = {
    variables: { slug },
  };

  const { loading, error, data } = useQuery(getContentPageQuery, queryOptions);
  if (loading) return <Loading colored />;
  if (error) return <ApolloErrorHandler error={error} />;

  const { contentPage } = data;

  return (
    <ContentfulProvider data={contentPage} contentfulPageType={PAGE_TYPE.CONTENT_PAGE}>
      <Page {...contentPage} />
      {`/${slug}`.startsWith(HELP_PAGE_BASE) || (`/${slug}`.startsWith(NEW_HELP_PAGE_BASE) && <HelpFooterContainer />)}
    </ContentfulProvider>
  );
};

ContentPage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default ContentPage;
