const answers = [
  { label: 'Ja', theme: 'success' },
  { label: 'Nej', theme: 'danger' },
];

export const watchQuestions = [
  {
    questionId: 0,
    questionText: 'Kan det tænde og slukke?',
    helpText: 'Kan det være tændt uden oplader?',
    possibleAnswers: answers,
  },
  {
    questionId: 1,
    questionText: 'Virker skærmen og er uden revner?',
    helpText: 'Alle funktioner og farver i skærmen virker og er intakte.',
    possibleAnswers: answers,
  },
  {
    questionId: 2,
    questionText: 'Er alle knapper til stede og virker de?',
    helpText: 'Alle knapper skal være originale og intakte og virke uden besvær.',
    possibleAnswers: answers,
  },
  {
    questionId: 3,
    questionText: 'Er uret i god stand?',
    helpText: 'Ingen revner eller brud på skærm, ramme eller bagside. Må ikke være bøjet.',
    possibleAnswers: answers,
  },
  {
    questionId: 4,
    questionText: 'Har du også oplader/dock og urrem?',
    helpText: 'Opladeren/dock er original og virker. Urremmen har ingen skader.',
    possibleAnswers: answers,
  },
];

export const deviceQuestions = [
  {
    questionId: 0,
    questionText: 'Kan den tænde og slukke?',
    helpText: 'Kan den være tændt uden oplader?',
    possibleAnswers: answers,
  },
  {
    questionId: 1,
    questionText: 'Virker skærmen og er uden revner?',
    helpText: 'Alle funktioner og farver i skærmen virker og er intakte.',
    possibleAnswers: answers,
  },
  {
    questionId: 2,
    questionText: 'Er den i god stand?',
    helpText: 'Ingen revner eller brud på skærm, ramme eller bagside. Må ikke været bøjet.',
    possibleAnswers: answers,
  },
  {
    questionId: 3,
    questionText: 'Er alle knapper til stede og virker de?',
    helpText: 'Alle knapper skal være originale og intakte og virke uden besvær.',
    possibleAnswers: answers,
  },
];

export const minimumBindingPeriod = 10;
export const MobileItemGroupId = 1;
export const TabletItemGroupId = 3;
export const SmartWatchItemGroupId = 4;

// fraud check result, there exists 1 more, but it is currently not in use: https://foxway.atlassian.net/wiki/external/5689147393/ODM3MmUyNTk3YjFkNDIxMWE5ZjEyYjI0YTc2MTAyMTE
export const failedFraudCheck = 'Failed';
export const passedFraudCheck = 'Passed';
export const campaignActive = 'Active';
/* *********************** hardcoded texts ********************* */

export const ThisIsHow = 'Sådan får du rabat';
export const Descriptions = [
  'Tjek værdien af din mobil eller tablet og accepter værdien. (Du kan indlevere flere enheder på en gang).',
  'Afslut dit køb og få tilsendt en pakkelabel fra os.',
  'Modtag din nye mobil eller tablet og send den gamle enhed ind til os.',
  'Når vi har modtaget din gamle enhed, bliver din rabat modregnet over 10, 20, 30 eller 40 måneder.',
];

export const VERIFY_STATUS = {
  VALID: 'Valid',
  INVALID: 'Invalid',
};

export const VIEW_STEP = {
  searchStep: 'search',
  questionStep: 'questions',
  IMEIStep: 'IMEI',
  confirmStep: 'confirm',
};

// These const match globalText keys and should not be changed without changing them in globalText
export const TradeInVariants = {
  PAGE: 'Product_Page',
  STANDALONE: 'Standalone',
};
