import { gql } from '@apollo/client';
import pageHeaderFragment from 'apollo/fragments/pageHeader';
import deviceListFragment from 'apollo/fragments/deviceList';
import seoFieldsFragment from '../fragments/seoFields';
import blockFragment from '../fragments/block';

const getDeviceListPageQuery = gql`
  query ($slug: String!) {
    deviceListPage(slug: $slug) {
      type
      title
      slug
      blocks {
        id
        type
        ...PageHeaderFragment
        ...DeviceListFragment
        ...BlockFragment
      }
      ...SeoFieldsFragment
    }
  }
  ${pageHeaderFragment}
  ${deviceListFragment}
  ${seoFieldsFragment}
  ${blockFragment}
`;

export default getDeviceListPageQuery;
