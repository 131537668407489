import { Banner, Divider, Small } from '@3dk/3style';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import Flex from '../Flex/Flex';
import formatPrice from './utils/formatPrice';

const classes = {
  divider: (theme) => ({
    height: '3px',
    backgroundColor: theme.palette.PRIMARY_BLACK,
  }),
  installment: {
    marginLeft: 'auto',
  },
  label: (theme) => ({
    color: theme.palette.SECONDARY_BLACK,
  }),
  number: {
    marginRight: '3px',
    fontSize: '28px',
    fontWeight: '700',
  },
  unit: {
    fontWeight: '700',
  },
};

const PriceSummary = (props) => {
  const { installment, upfront } = props;
  return (
    <div>
      <Flex>
        {upfront > 0 && (
          <div>
            <Small css={classes.label}>{t('Product_Page.Configurator.Payment_Summary_Upfront')}</Small>
            <Flex alignItems="baseline">
              <Banner css={classes.number}>{formatPrice(upfront)}</Banner>
              <Small css={classes.unit}>kr.</Small>
            </Flex>
          </div>
        )}
        {installment > 0 && (
          <Flex css={classes.installment} alignItems="flex-end" flexDirection="column">
            <Small css={classes.label}>{t('Product_Page.Configurator.Payment_Summary_Installment')}</Small>
            <Flex alignItems="baseline" justifyContent="flex-end">
              <Banner css={classes.number}>{formatPrice(installment)}</Banner>
              <Small css={classes.unit}>kr./md.</Small>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Divider css={classes.divider} />
    </div>
  );
};

PriceSummary.propTypes = {
  installment: PropTypes.number,
  upfront: PropTypes.number,
};

PriceSummary.defaultProps = {
  installment: 0,
  upfront: 0,
};

export default PriceSummary;
