import { CountryList, Accordion, Box, Subhead, Title } from '@3dk/3style';
import { useState } from 'react';
import PropTypes from 'prop-types';
import MarkdownComponent from '../../../components/MarkdownComponent';
import { countryListProps } from '../../../utils/propTypes/countryListBlockProps';

const MARKDOWN_HEADER = '##Her kan du bruge din mobil med 3LikeHome';

const ThreeLikeHomeCountries = ({ countryList }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  if (!countryList?.countries?.length) {
    return null;
  }

  return (
    <Accordion
      id="3likehome-accordion"
      expanded={isAccordionOpen}
      onChange={() => setIsAccordionOpen(!isAccordionOpen)}
      header={
        <>
          <Box display={{ xs: 'block', lg: 'none' }}>
            <MarkdownComponent forceComponent={Subhead} forceComponentType="h2">
              {MARKDOWN_HEADER}
            </MarkdownComponent>
          </Box>
          <Box display={{ xs: 'none', lg: 'block' }}>
            <MarkdownComponent forceComponent={Title} forceComponentType="h2">
              {MARKDOWN_HEADER}
            </MarkdownComponent>
          </Box>
        </>
      }
    >
      <CountryList countries={countryList.countries} />
    </Accordion>
  );
};

ThreeLikeHomeCountries.propTypes = {
  countryList: PropTypes.arrayOf(countryListProps),
};

ThreeLikeHomeCountries.defaultProps = {
  countryList: null,
};

export default ThreeLikeHomeCountries;
