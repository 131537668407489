import AUTHENTICATION from '../../constants/authentication';
import { NEM_ID_VERIFICATION } from '../../constants/urls';

const generateAuthenticationUrl = (options = AUTHENTICATION, returnUrl = NEM_ID_VERIFICATION) => {
  const { ORIGIN, AUTH_LOGIN_URL } = options;
  const redirectUri = window.location.origin.concat(returnUrl);
  return AUTH_LOGIN_URL.concat(`?returnUrl=${encodeURIComponent(redirectUri)}&origin=${ORIGIN}`);
};

export default generateAuthenticationUrl;
