import { error as logError } from '@hi3g-access/client-logger';
import { useEffect } from 'react';
import { Container } from '@3dk/3style';

const CheckoutErrorView = () => {
  useEffect(() => {
    logError(`Error Boundry Checkout hit`);
  }, []);
  <Container>Vi har registreret fejlen og forsøger at udbedre den hurtigst muligt.</Container>;
};

export default CheckoutErrorView;
