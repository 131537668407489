import { gql } from '@apollo/client';
import {
  legacyAccessoriesFragment,
  legacyAddonsFragment,
  legacyDeviceFragment,
  legacySimCardFragment,
  legacySubscriptionFragment,
} from '../fragments/legacyCart';

const addProductToLegacyCartPreviewQuery = gql`
  query AddProductToLegacyCartPreview(
    $items: [String!]!
    $upfront: Float
    $bundledProducts: [[String!]]
    $claimTradeIn: Boolean
  ) {
    addProductToLegacyCartPreview(
      items: $items
      upfront: $upfront
      bundledProducts: $bundledProducts
      claimTradeIn: $claimTradeIn
    ) {
      id
      bindingPeriod
      monthlyInstallmentPeriod
      subscription {
        ...legacySubscriptionFragment
      }
      addons {
        ...legacyAddonsFragment
      }
      accessories {
        ...legacyAccessoriesFragment
      }
      device {
        ...legacyDeviceFragment
      }
      simCard {
        ...legacySimCardFragment
      }
    }
  }
  ${legacyAddonsFragment}
  ${legacyAccessoriesFragment}
  ${legacySimCardFragment}
  ${legacyDeviceFragment}
  ${legacySubscriptionFragment}
`;

export default addProductToLegacyCartPreviewQuery;
