import { gql } from '@apollo/client';
import {
  legacyAccessoriesFragment,
  legacyAddonsFragment,
  legacyDeliveryAddressFragment,
  legacyDeviceFragment,
  legacyFreightFragment,
  legacyPriceFragment,
  legacySimCardFragment,
  legacySubscriptionFragment,
} from './legacyCart';

const legacyOrderFragment = gql`
  fragment legacyOrderFragment on LegacyOrder {
    cartId
    minimumPriceSummary
    totalUpfrontPrice
    priceSummary {
      ...legacyPriceFragment
    }
    freight {
      ...legacyFreightFragment
    }
    installments {
      term
      amount
    }
    products {
      id
      bindingPeriod
      monthlyInstallmentPeriod
      eSimSelected
      subscription {
        ...legacySubscriptionFragment
      }
      addons {
        ...legacyAddonsFragment
      }
      accessories {
        ...legacyAccessoriesFragment
      }
      device {
        ...legacyDeviceFragment
      }
      simCard {
        ...legacySimCardFragment
      }
    }
    customerInfo {
      email
      phoneNumber
      deliveryAddress {
        ...legacyDeliveryAddressFragment
      }
    }
    vouchers {
      code
      headline
      description
    }
    dcAccessories {
      ...legacyAccessoriesFragment
    }
    tradeInProducts {
      tradeInDiscount
      basketRowGuid
      devices {
        answers
        id
        imei
        pictureUrl
        price
        priceQuoteId
        productNameShort
      }
      tradeInCampaignDiscount {
        campaignId
        discount
      }
    }
    accountLevelDiscount {
      title
      description
      priceDescription
      icon
      discount
    }
    hasSpecialVoucher
  }
  ${legacyAddonsFragment}
  ${legacyAccessoriesFragment}
  ${legacyPriceFragment}
  ${legacySimCardFragment}
  ${legacyDeviceFragment}
  ${legacySubscriptionFragment}
  ${legacyFreightFragment}
  ${legacyDeliveryAddressFragment}
`;

export default legacyOrderFragment;
