import PropTypes from 'prop-types';
import { Box, Accordion, Grid, Caption, Body, Title, Subhead, SECONDARY_BLACK } from '@3dk/3style';
import { forwardRef } from 'react';
import MarkdownComponent from './MarkdownComponent';

const classes = {
  expansionPanelSidePadding: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  expansionPanelContent: {
    margin: '22px 0',
    '&$summaryExpanded': {
      margin: '22px 0',
    },
  },
  summaryExpanded: {},
  categoryHeadline: {
    marginBottom: '7px',
  },
  table: {
    borderSpacing: '0',
    width: '100%',
    '& td': {
      padding: '0px 10px 0px 0px',
      verticalAlign: 'top',
    },
  },
  tableKey: {
    width: '50%',
    paddingRight: '10px',
  },
  smallTableKey: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      width: '25%',
    },
  }),
  mediumTableKey: (theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
  }),
  item: (theme) => ({
    '& p::first-letter': {
      textTransform: 'uppercase',
    },
    '&:not(:last-child)': {
      paddingBottom: '20px',
    },
    '&:last-child': {
      marginBottom: '15px',
      [theme.breakpoints.up('lg')]: {
        marginBottom: '40px',
      },
    },
  }),
};

const SpecificationsLayout = forwardRef(
  (
    { className, headline, specificationSections, isExpanded, setExpanded, longSpecificationId, headerColumnWidth },
    ref,
  ) => {
    const handleChange = () => {
      setExpanded(!isExpanded);
    };

    return (
      <Grid className={className} container spacing={0}>
        <Grid xs={12}>
          {headline && specificationSections && (
            <Accordion
              classes={{
                expansionPanelSummary: classes.expansionPanelSidePadding,
                expansionPanelDetails: classes.expansionPanelSidePadding,
                content: classes.expansionPanelContent,
                summaryExpanded: classes.summaryExpanded,
              }}
              header={
                <>
                  <Box display={{ xs: 'block', lg: 'none' }}>
                    <MarkdownComponent forceComponent={Subhead} forceComponentType="h2">
                      {headline}
                    </MarkdownComponent>
                  </Box>
                  <Box display={{ xs: 'none', lg: 'block' }}>
                    <MarkdownComponent forceComponent={Title} forceComponentType="h2">
                      {headline}
                    </MarkdownComponent>
                  </Box>
                </>
              }
              expanded={isExpanded}
              onChange={handleChange}
              id={longSpecificationId}
              ref={ref}
            >
              <Grid container>
                {specificationSections.map((specificationSection) => {
                  const { header, specifications } = specificationSection;
                  return (
                    <Grid key={header} css={classes.item} xs={12}>
                      <Box display={{ xs: 'block', lg: 'none' }}>
                        <Caption css={classes.categoryHeadline} type="black">
                          {header}
                        </Caption>
                      </Box>
                      <Box display={{ xs: 'none', lg: 'block' }}>
                        <Body css={classes.categoryHeadline} type="bold">
                          {header}
                        </Body>
                      </Box>

                      <table css={classes.table}>
                        <tbody>
                          {specifications.map((specification) => {
                            const { title, value } = specification;
                            return (
                              <tr key={title}>
                                <td
                                  css={[
                                    classes.tableKey,
                                    headerColumnWidth === 'small' && classes.smallTableKey,
                                    headerColumnWidth === 'medium' && classes.mediumTableKey,
                                  ]}
                                >
                                  <Box display={{ xs: 'block', lg: 'none' }}>
                                    <Caption color={SECONDARY_BLACK}>{title}</Caption>
                                  </Box>
                                  <Box display={{ xs: 'none', lg: 'block' }}>
                                    <Body color={SECONDARY_BLACK}>{title}</Body>
                                  </Box>
                                </td>
                                <td>
                                  <Box display={{ xs: 'block', lg: 'none' }}>
                                    <Caption color={SECONDARY_BLACK}>{value}</Caption>
                                  </Box>
                                  <Box display={{ xs: 'none', lg: 'block' }}>
                                    <Body color={SECONDARY_BLACK}>{value}</Body>
                                  </Box>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Grid>
                  );
                })}
              </Grid>
            </Accordion>
          )}
        </Grid>
      </Grid>
    );
  },
);

SpecificationsLayout.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  specificationSections: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      specifications: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  longSpecificationId: PropTypes.string,
  headerColumnWidth: PropTypes.oneOf(['small', 'medium']),
};

SpecificationsLayout.defaultProps = {
  className: null,
  longSpecificationId: '',
  headerColumnWidth: 'small',
};

export default SpecificationsLayout;
