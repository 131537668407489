import { useEffect, useRef } from 'react';

/* custom hook that uses the useRef hook to store previous value,
      and only updates when a new values is passed to it */
const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    if (value !== null && value !== undefined) ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
