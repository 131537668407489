import { gql } from '@apollo/client';
import accessoryV4Fragment from '../fragments/accessoryV4';

const getProductQuery = gql`
  query accessoryBySlugV4($id: String!) {
    accessoryBySlugV4(slug: $id) {
      ...AccessoryV4
    }
  }
  ${accessoryV4Fragment}
`;

export default getProductQuery;
