export default function formatPrice(price) {
  const number = Number(price);
  if (Number.isNaN(number)) {
    return price;
  }

  const isFloat = !Number.isInteger(number);
  const options = {
    minimumFractionDigits: isFloat ? 2 : 0,
    maximumFractionDigits: 2,
  };

  return number.toLocaleString('da-DK', options);
}
