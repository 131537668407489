import { useQuery } from '@apollo/client';
import getDeviceListPageQuery from 'apollo/queries/getDeviceListPage';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import PAGE_TYPE from 'constants/pageTypes';
import Page from './pageContainer/Page';
import ContentfulProvider from './pageContainer/ContentfulContext';

const DeviceListPage = (props) => {
  const { slug } = props;
  const queryOptions = {
    variables: { slug },
  };

  const { loading, error, data } = useQuery(getDeviceListPageQuery, queryOptions);
  if (loading) return <Loading colored />;
  if (error) return <ApolloErrorHandler error={error} />;
  const { deviceListPage } = data;

  return (
    <ContentfulProvider data={deviceListPage} contentfulPageType={PAGE_TYPE.DEVICE_LIST_PAGE}>
      <Page {...data.deviceListPage} />
    </ContentfulProvider>
  );
};

DeviceListPage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default DeviceListPage;
