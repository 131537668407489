import { gql } from '@apollo/client';
import blockFragment from './block';

const rowContainerFragment = gql`
  fragment RowContainerFragment on RowContainer {
    useFullWidth
    backgroundImage {
      url
    }
    backgroundImageLargeDevices {
      url
    }
    backgroundColor
    placement
    columnGap
    direction
    rowGap
    blocks {
      type
      id
      ...BlockFragment
    }
  }
  ${blockFragment}
`;

export default rowContainerFragment;
