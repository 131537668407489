import { gql } from '@apollo/client';

const deliveryDetailsFragment = gql`
  fragment deliveryDetailsFragment on CheckoutDeliveryDetails {
    firstName
    lastName
    emailAddress
    careOf
    streetAddress
    postalCode
    city
    country
    deliveryTimeText
    deliveryMethod
  }
`;

export default deliveryDetailsFragment;
