import { gql } from '@apollo/client';
import blockFragment from './block';
import gridContainerFragment from './gridContainer';

const tabFragment = gql`
  fragment TabFragment on Tab {
    id
    type
    headline
    headlineLink
    blocks {
      ...BlockFragment
      ...GridContainerFragment
    }
  }
  ${blockFragment}
  ${gridContainerFragment}
`;

export default tabFragment;
