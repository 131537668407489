import { gql } from '@apollo/client';
import blockFragment from '../fragments/block';
import seoFieldsFragment from '../fragments/seoFields';
import gridContainerFragment from '../fragments/gridContainer';
import carouselFragment from '../fragments/carousel';
import rowContainerFragment from '../fragments/rowContainer';
import navigationBarFragment from '../fragments/navigationBar';

const getContentPageQuery = gql`
  query ($slug: String!) {
    contentPage(slug: $slug) {
      slug
      blocks {
        id
        type
        __typename
        ...GridContainerFragment
        ...RowContainerFragment
        ...CarouselFragment
        ...BlockFragment
        ...NavigationBarFragment
      }
      ...SeoFieldsFragment
    }
  }
  ${seoFieldsFragment}
  ${blockFragment}
  ${carouselFragment}
  ${gridContainerFragment}
  ${rowContainerFragment}
  ${navigationBarFragment}
`;

export default getContentPageQuery;
