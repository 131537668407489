import { contentfulTextColors } from '../../app/routes/pageContainer/page/Entry/Colors/Colors';

const contentfulActionMapper = (text, action, index) => {
  if (action === 'n') {
    return <br key={index} />;
  }
  if (contentfulTextColors[action]) {
    return (
      <span key={index} style={{ color: contentfulTextColors[action] }}>
        {text}
      </span>
    );
  }
  return text;
};

const convertContentfulMarkupToElements = (richText) => {
  const elements = [];

  const textArray = Array.isArray(richText) ? richText : [richText];

  textArray.forEach((t) => {
    if (typeof t === 'string') {
      // This Regex splits the string up into patters of text before a {}, text inside a {}, text inside a [] and text after,
      // but only if the full {}[] pattern is present and uninterrupted.
      // For instance the text "a{oneBlock rules}[gold]b" will be split into an array of ["a", "oneBlock rules", "gold", "b"]
      // and "a{oneBlock}rules[gold]b" will be split into an array of ["a{oneBlock}rules[gold]b"]
      const pattern = /\{([^}]*)\}\[([^\]]*)\]/g;
      const parts = t.split(pattern);
      for (let i = 0; i < parts.length; i += 3) {
        const unchangedText = parts[i];
        elements.push(unchangedText);
        if (i + 1 < parts.length) {
          const actionText = parts[i + 1];
          const action = parts[i + 2];
          elements.push(contentfulActionMapper(actionText, action.toLowerCase(), i));
        }
      }
    } else {
      elements.push(t);
    }
  });

  return elements;
};

export default convertContentfulMarkupToElements;
