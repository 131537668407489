import { useEffect, useRef } from 'react';

/**
 * in some cases components have user data
 * that should not be rendered on the server as we will save it in full response cache
 */
const withSecondPass = (Component) =>
  function (props) {
    const isMounted = useRef();
    useEffect(() => {
      isMounted.current = true;
    }, []);

    return isMounted.current ? <Component {...props} /> : null;
  };

export default withSecondPass;
