import PropTypes from 'prop-types';
import { Body } from '@3dk/3style';
import LinkResolver from '../../../../../../components/LinkResolver';
import { addressPropTypes } from './fwaPropTypes';
import linkPropType from '../../../../../../utils/propTypes/link';

const classes = {
  addressContainer: (theme) => ({
    backgroundColor: theme.palette.PRIMARY_LIGHT_GREY,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px',
    marginTop: '32px',
    borderRadius: '8px',
  }),
};

const FwaAddressHeader = ({ address, returnLink }) => (
  <div css={classes.addressContainer}>
    <div>
      <Body>{[address.street, address.streetNumber, address.floor, address.door].filter(Boolean).join(' ')}</Body>
      <Body>{[address.postalCode, address.city].filter(Boolean).join(' ')}</Body>
    </div>
    {returnLink && <LinkResolver {...returnLink}>{returnLink.text}</LinkResolver>}
  </div>
);

FwaAddressHeader.propTypes = {
  address: PropTypes.shape(addressPropTypes),
  returnLink: PropTypes.shape(linkPropType),
};

FwaAddressHeader.defaultProps = {
  address: undefined,
  returnLink: undefined,
};

export default FwaAddressHeader;
