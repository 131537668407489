import { useEffect } from 'react';

const LOGOUT_EVENT = 'user:logout';

export const triggerLogoutEvent = (data) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dispatchEvent(new CustomEvent(LOGOUT_EVENT, { detail: data }));
};

export const useLogoutEvent = (handler, deps) => {
  useEffect(() => {
    window.addEventListener(LOGOUT_EVENT, handler);
    return () => {
      window.removeEventListener(LOGOUT_EVENT, handler);
    };
  }, deps);
};
