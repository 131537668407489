import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { useQuery } from '@apollo/client';
import { warn as logWarning } from '@hi3g-access/client-logger';
import getListPageTypesAndSlugsQuery from 'apollo/queries/getListPages';
import Loading from 'components/Loading';
import getContentPageSlugsQuery from 'apollo/queries/getContentPages';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import ErrorBoundary from 'components/ErrorBoundary';
import CheckoutErrorView from 'components/CheckoutErrorView';
import ReceiptContainer from './routes/ReceiptContainer';
import VerifyAuth from './routes/VerifyAuth';
import OrderVerificationContainer from './routes/OrderVerificationContainer';
import ProductDetailsContainer from './routes/ProductDetailsContainer';
import {
  ABANDON_CHECKOUT,
  ACCESSORY_BASE,
  CART_RECOVERY,
  CATEGORY_PAGE,
  CHECKOUT,
  CONFIRMATION_SELECTION,
  CUSTOMER_CONFIRM,
  DEPRECATED_CATEGORY_PAGE,
  DEPRECATED_PRODUCT_PAGE,
  DEVICE_PRODUCT_PAGE,
  NEM_ID_FALLBACK,
  NEM_ID_FALLBACK_RECEIPT,
  NEM_ID_FALLBACK_VERIFICATION,
  NEM_ID_VERIFICATION,
  ORDER_VERIFICATION,
  PRE_DC_CHECKOUT,
  PRE_DC_CONTACT_CUSTOMER_SERVICE,
  PRE_DC_CREDIT_CHECK_REJECTED_PAGE,
  PRE_DC_ORDER_VERIFICATION,
  PRE_DC_RECEIPT,
  PRODUCT_PAGE,
  SUBSCRIPTION_PRODUCT_PAGE,
  THANK_YOU,
  UNIFIED_CART_RECOVERY,
} from '../constants/urls';
import CONTENFUL_PAGETYPE from '../constants/contentfulPageTypes';
import NemIDFallbackVerification from './routes/NemIDFallbackVerification';
import PageContainer from './routes/PageContainer';
import CartRecovery from './routes/CartRecovery';
import GlobalHeader from './routes/GlobalHeader';
import DeviceListPage from './routes/DeviceListPage';
import ContentPage from './routes/ContentPage';
import getExternalPageSlugsQuery from '../apollo/queries/getExternalPageSlugs';
import ExternalPage from './routes/ExternalPage';
import { getPagesQuery } from '../apollo/queries/getPages';
import PreDcOrderVerification from './routes/PreDcOrderVerification';
import TradeInContainer from './routes/TradeInContainer';
import useQueryAction from '../utils/hooks/queryActions';
import PreDcDevicePageContainer from './routes/PreDcDevicePageContainer';
import CreditCheckRejection from './routes/CreditCheckRejection';
import ContactCustomerService from './routes/ContactCustomerService';
import ConfirmationContainer from './routes/ConfirmationContainer';
import SubscriptionPage from './routes/SubscriptionPage';
import UnifiedCartRecovery from './routes/UnifiedCartRecovery';
import trimSlugAndRemoveStartingSlash from '../utils/url/trimSlugAndRemoveStartingSlash';
import AbandonCheckout from './routes/AbandonCheckout';

const CheckoutContainer = loadable(() => import('./routes/CheckoutContainer'));
const PreDcCheckout = loadable(() => import('./routes/PreDcCheckout'));
const NemIDFallback = loadable(() => import('./routes/NemIDFallback'));
const NemIDFallbackReceipt = loadable(() => import('./routes/NemIDFallbackReceipt'));
const ErrorPage404 = loadable(() => import('../components/errorHandling/errorPages/ErrorPage404'));
const PreDcReceiptContainer = loadable(() => import('./routes/PreDcReceiptContainer'));

/* Updates the menu configuration object by converting accessory node url to absolute, while preserving client side routing of children.
 *  In this way, when a user is in our site, and wants to navigate to /tilbehoer using the menu, we want them to access legacy system, which is done by not using react router (e.g. absolute url) */

const Routes = ({ isCartOpen, setIsCartOpen }) => {
  const commonCartProps = {
    isCartOpen,
    setIsCartOpen,
  };
  useQueryAction();

  const {
    loading: isGetListPageTypesAndSlugsQueryLoading,
    error: getListPageTypesAndSlugsQueryError,
    data: getListPageTypesAndSlugsQueryData,
  } = useQuery(getListPageTypesAndSlugsQuery);

  const {
    loading: isGetContentPageSlugsQueryLoading,
    error: getContentPageSlugsQueryError,
    data: getContentPageSlugsQueryData,
  } = useQuery(getContentPageSlugsQuery);

  const {
    loading: isGetExternalPageSlugsQueryLoading,
    error: getExternalPageSlugsQueryError,
    data: getExternalPageSlugsQueryData,
  } = useQuery(getExternalPageSlugsQuery);

  const {
    loading: isGetPageSlugsQueryLoading,
    error: getPageSlugsQueryError,
    data: getPageSlugsQueryData,
  } = useQuery(getPagesQuery);

  if (
    isGetListPageTypesAndSlugsQueryLoading ||
    isGetContentPageSlugsQueryLoading ||
    isGetExternalPageSlugsQueryLoading ||
    isGetPageSlugsQueryLoading
  ) {
    return <Loading colored />;
  }

  const queryError =
    getListPageTypesAndSlugsQueryError ||
    getContentPageSlugsQueryError ||
    getExternalPageSlugsQueryError ||
    getPageSlugsQueryError;
  if (queryError) {
    return <ApolloErrorHandler error={queryError} />;
  }
  return (
    <>
      <ErrorBoundary>
        <GlobalHeader />
      </ErrorBoundary>
      <ErrorBoundary>
        <TradeInContainer />
      </ErrorBoundary>
      <Switch>
        <Route
          path={CHECKOUT}
          exact
          render={() => (
            <ErrorBoundary ErrorComponent={CheckoutErrorView}>
              <CheckoutContainer />
            </ErrorBoundary>
          )}
        />
        <Route
          path={NEM_ID_FALLBACK}
          exact
          render={() => (
            <ErrorBoundary>
              <NemIDFallback />
            </ErrorBoundary>
          )}
        />
        <Route
          path={NEM_ID_FALLBACK_VERIFICATION}
          exact
          render={() => (
            <ErrorBoundary>
              <NemIDFallbackVerification />
            </ErrorBoundary>
          )}
        />
        <Route
          path={NEM_ID_FALLBACK_RECEIPT}
          exact
          render={() => (
            <ErrorBoundary>
              <NemIDFallbackReceipt />
            </ErrorBoundary>
          )}
        />
        <Route
          path={THANK_YOU}
          exact
          render={() => (
            <ErrorBoundary title="kvitteringssiden" fullHeight showBackButton>
              <ReceiptContainer />
            </ErrorBoundary>
          )}
        />
        <Route
          path={NEM_ID_VERIFICATION}
          exact
          render={() => (
            <ErrorBoundary>
              <VerifyAuth />
            </ErrorBoundary>
          )}
        />
        <Route
          path={ORDER_VERIFICATION}
          exact
          render={() => (
            <ErrorBoundary title="ordrebekræftelse" fullHeight showBackButton>
              <OrderVerificationContainer />
            </ErrorBoundary>
          )}
        />
        <Route
          path={CART_RECOVERY}
          exact
          render={() => (
            <ErrorBoundary>
              <CartRecovery />
            </ErrorBoundary>
          )}
        />
        <Route
          path={UNIFIED_CART_RECOVERY}
          exact
          render={() => (
            <ErrorBoundary>
              <UnifiedCartRecovery />
            </ErrorBoundary>
          )}
        />
        <Route
          path={[CONFIRMATION_SELECTION, CUSTOMER_CONFIRM]}
          exact
          render={() => (
            <ErrorBoundary>
              <ConfirmationContainer />
            </ErrorBoundary>
          )}
        />
        <Route
          path={PRE_DC_CHECKOUT}
          exact
          render={() => (
            <ErrorBoundary>
              <PreDcCheckout />
            </ErrorBoundary>
          )}
        />
        <Route
          path={PRE_DC_CREDIT_CHECK_REJECTED_PAGE}
          exact
          render={() => (
            <ErrorBoundary>
              <CreditCheckRejection />
            </ErrorBoundary>
          )}
        />
        <Route
          path={PRE_DC_CONTACT_CUSTOMER_SERVICE}
          exact
          render={() => (
            <ErrorBoundary>
              <ContactCustomerService />
            </ErrorBoundary>
          )}
        />
        <Route
          path={PRE_DC_ORDER_VERIFICATION}
          exact
          render={() => (
            <ErrorBoundary>
              <PreDcOrderVerification />
            </ErrorBoundary>
          )}
        />
        <Route
          path={PRE_DC_RECEIPT}
          exact
          render={() => (
            <ErrorBoundary>
              <PreDcReceiptContainer />
            </ErrorBoundary>
          )}
        />
        <Route
          path={ABANDON_CHECKOUT}
          exact
          render={() => (
            <ErrorBoundary>
              <AbandonCheckout />
            </ErrorBoundary>
          )}
        />
        {getListPageTypesAndSlugsQueryData.listPages.map((listPage) => {
          switch (listPage.type) {
            case 'devicelistpage': {
              const cleanedSlug = trimSlugAndRemoveStartingSlash(listPage.slug);
              return [
                <Route
                  path={`/${cleanedSlug}`}
                  exact
                  key={cleanedSlug}
                  render={() => (
                    <ErrorBoundary>
                      <DeviceListPage slug={cleanedSlug} />
                    </ErrorBoundary>
                  )}
                />,
              ];
            }
            default:
              logWarning(`Encountered unknown list page type - ${listPage.type}`);
              return null;
          }
        })}
        {getContentPageSlugsQueryData.contentPages.map((contentPage) => {
          const cleanedSlug = trimSlugAndRemoveStartingSlash(contentPage.slug);
          return (
            <Route
              path={`/${cleanedSlug}`}
              exact
              key={cleanedSlug}
              render={() => (
                <ErrorBoundary>
                  <ContentPage slug={cleanedSlug} />
                </ErrorBoundary>
              )}
            />
          );
        })}
        {getPageSlugsQueryData.pages.map((page) => {
          const cleanedSlug = trimSlugAndRemoveStartingSlash(page.slug);
          return (
            <Route
              key={page.id}
              path={`${ACCESSORY_BASE}${cleanedSlug}`}
              exact
              render={() => <PageContainer slug={cleanedSlug} pageType={CONTENFUL_PAGETYPE.CONTENT_PAGE} />}
            />
          );
        })}
        {getExternalPageSlugsQueryData.externalPages.map((externalPage) => {
          const cleanedSlug = trimSlugAndRemoveStartingSlash(externalPage.slug);
          return (
            <Route path={`/${cleanedSlug}`} key={cleanedSlug} render={() => <ExternalPage slug={cleanedSlug} />} />
          );
        })}
        {/* We prioritize routing of contentPages, pages and externalPages over product and category pages routes by placing their routes before them.
        This allows us to have contentPages with slug structures equal to /shop/:type/:brand/:device/ and /shop/abonnementer/:slug/ */}
        <Route
          path={SUBSCRIPTION_PRODUCT_PAGE}
          render={() => (
            <ErrorBoundary>
              <SubscriptionPage />
            </ErrorBoundary>
          )}
        />
        {/* TODO: This is only temporary and will be removed by (NOV-2577) */}
        <Redirect from={DEPRECATED_PRODUCT_PAGE} to={PRODUCT_PAGE} exact />
        <Route
          exact
          path={PRODUCT_PAGE}
          render={() => (
            <ErrorBoundary>
              <ProductDetailsContainer {...commonCartProps} />
            </ErrorBoundary>
          )}
        />
        <Route
          path={DEVICE_PRODUCT_PAGE}
          render={() => (
            <ErrorBoundary>
              <PreDcDevicePageContainer />
            </ErrorBoundary>
          )}
        />
        {/* TODO: This is only temporary and will be removed by (NOV-2577) */}
        <Redirect from={DEPRECATED_CATEGORY_PAGE} to={CATEGORY_PAGE} exact />
        <Route
          exact
          path={CATEGORY_PAGE}
          render={() => (
            <ErrorBoundary>
              <PageContainer pageType={CONTENFUL_PAGETYPE.CATEGORY_PAGE} />
            </ErrorBoundary>
          )}
        />
        <Route render={() => <ErrorPage404 />} />
      </Switch>
    </>
  );
};

Routes.propTypes = {
  countOfProductsInCart: PropTypes.number,
  isCartOpen: PropTypes.bool.isRequired,
  setIsCartOpen: PropTypes.func.isRequired,
};

Routes.defaultProps = {
  countOfProductsInCart: 0,
};

export default Routes;
