import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import PAGE_TYPE from 'constants/pageTypes';
import Loading from '../../components/Loading';
import { getPageQuery, getAgentPageQuery } from '../../apollo/queries/getPage';
import Page from './pageContainer/Page';
import ContentfulProvider from './pageContainer/ContentfulContext';

const PageContainer = ({ slug, pageType }) => {
  const { category } = useParams();
  const location = useLocation();

  const id = slug || category;

  const isAgent = location.pathname.match(/^\/agent/);

  const { loading, error, data } = useQuery(isAgent ? getAgentPageQuery : getPageQuery, {
    variables: { slug: id, pageType },
  });

  if (loading) return <Loading colored />;
  if (error) return <ApolloErrorHandler error={error} />;
  const { page } = data;

  return (
    <ContentfulProvider data={page} contentfulPageType={pageType?.toLowerCase() || PAGE_TYPE.PAGE}>
      <Page isAgent={isAgent} {...data.page} />
    </ContentfulProvider>
  );
};

PageContainer.propTypes = {
  pageType: PropTypes.string,
  slug: PropTypes.string,
};

PageContainer.defaultProps = {
  pageType: null,
  slug: '',
};

export default PageContainer;
