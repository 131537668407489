import { Container } from '@3dk/3style';
import { useEffect, useRef, useState } from 'react';

/**
 * Cookie Information works by having a global script on all pages (we have it in render.js)
 * We can then display multiple configurations / texts by inserting magic elements, or scripts
 * We use the following:
 *   1. Privacy Controls - requires an HTML element with id 'cicc-template'
 *   2. Cookie Policy Text - requires a script to be inserted (se below)
 * Since everything is loaded using scripts, we have hydration issues between server and client
 * To avoid these issues, both server and client doesn't render anything (on first render) and then client renders the content on second render
 * See: https://react.dev/reference/react-dom/client/hydrateRoot#handling-different-client-and-server-content for more information
 */
const CookieInformationPage = () => {
  const [isClient, setIsClient] = useState(false);
  const cookiePolicyScriptRef = useRef(undefined);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (isClient) {
      // Normally when the dom have finished loading, CookieInformation's scripts runs and inserts 'content' in the HTML element with id 'cicc-template'.
      // Since this component doesn't render anything on first render, and first starts rendering on second render, then their script have already been executed, and no 'content' is inserted.
      // Inside their script I found that the event 'DOMContentLoaded' can be dispatched, which will trigger their code to run again.
      const event = new Event('DOMContentLoaded');
      window.dispatchEvent(event);

      const script = document.createElement('script');
      script.src = 'https://policy.cookieinformation.com/27d67e3a-f986-4787-8f49-c2ad46fde479/cd.js';
      script.type = 'text/javascript';
      script.id = 'CookiePolicy';
      cookiePolicyScriptRef.current.appendChild(script);
    }
  }, [isClient]);

  return isClient ? (
    <Container>
      <div id="cicc-template" />
      <div ref={cookiePolicyScriptRef} />
    </Container>
  ) : null;
};
export default CookieInformationPage;
