import { gql } from '@apollo/client';

const createPreDcOrderMutation = gql`
  mutation createPreDcOrder(
    $cartId: String
    $legacyCartId: String!
    $paymentId: String
    $forceInvoice: Boolean!
    $signUpOnly: Boolean!
  ) {
    createPreDcOrder(
      cartId: $cartId
      legacyCartId: $legacyCartId
      paymentId: $paymentId
      forceInvoice: $forceInvoice
      signUpOnly: $signUpOnly
    ) {
      orderId
      orderHasMissingPortingInfo
    }
  }
`;

export default createPreDcOrderMutation;
