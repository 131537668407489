import { gql } from '@apollo/client';

const getDefaultCustomerConsentsQuery = gql`
  query getDefaultCustomerConsents($category: String, $customerType: String) {
    getDefaultCustomerConsents(category: $category, customerType: $customerType) {
      consents {
        version
        consentText
        consents {
          consentId
          version
        }
      }
    }
  }
`;

export default getDefaultCustomerConsentsQuery;
