import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { Carousel, CountryList } from '@3dk/3style';
import {
  SUBSCRIPTION_CARD,
  IFRAME,
  PRODUCT_LIST,
  DEVICE_LIST,
  PAGE_HEADER,
  ICON_BLOCK,
  CONTACT_INFORMATION_BLOCK,
  FAQ,
  CAROUSEL,
  GRID_CONTAINER,
  LEGAL_TEXT,
  NAVIGATION_BAR,
  SIMPLE_IMAGE,
  SIMPLE_TEXT,
  VIDEO_BLOCK,
  PRODUCT_SLIDER,
  ONE_BLOCK,
  NOTIFICATION_BAR,
  STORE_LIST,
  ROW_CONTAINER,
  ROAMING_COUNTRIES_DROPDOWN,
  SEARCH_BAR,
  TAB,
  FWA,
  FAMILY_SLIDER,
  COUNTRY_LIST,
} from './constants';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import WidthWrapper from '../../../../components/WidthWrapper';
import IFrame from '../../../../components/IFrame';
import findFirstH1BlockIndex from './utility/findFirstH1BlockIndex';
import RoamingCountriesDropdown from './Entry/RoamingCountriesDropdown';
import FwaEntry from './Entry/FwaEntry';

const GridContainer = loadable(() => import('./Entry/GridContainer'));
const PageHeader = loadable(() => import('./Entry/PageHeader'));
const ContactInformationBlock = loadable(() => import('../../../../components/ContactInformationBlock'));
const AccessoryListContainer = loadable(() => import('./Entry/AccessoryListContainer'));
const Faq = loadable(() => import('./Entry/Faq'));
const SimpleImage = loadable(() => import('./Entry/SimpleImage'));
const SimpleText = loadable(() => import('./Entry/SimpleText'));
const ProductSliderEntry = loadable(() => import('./Entry/ProductSlider'));
const DeviceListContainer = loadable(() => import('./Entry/DeviceListContainer'));
const OneBlockEntry = loadable(() => import('./Entry/OneBlockEntry'));
const NotificationBar = loadable(() => import('./Entry/NotificationBar'));
const StoreListContainer = loadable(() => import('./Entry/StoreListContainer'));
const FamilySlider = loadable(() => import('./Entry/FamilySliderEntry'));
const SubscriptionCard = loadable(() => import('./Entry/SubscriptionCard'));
const IconBlock = loadable(() => import('./Entry/IconBlock'));
const VideoBlock = loadable(() => import('./Entry/VideoBlock'));
const RowContainer = loadable(() => import('./Entry/RowContainer'));
const SearchBar = loadable(() => import('./Entry/SearchBar'));
const NavigationBar = loadable(() => import('./Entry/NavigationBar'));
const LegalText = loadable(() => import('./Entry/LegalText'));
const Tab = loadable(() => import('./Entry/Tab'));

const Entry = ({ type, id, useFullWidth, blocks, isAgent, disableGutters, ...props }) => {
  switch (type) {
    case SUBSCRIPTION_CARD:
      return <SubscriptionCard {...props} />;
    case IFRAME:
      return <IFrame {...props} />;
    case PRODUCT_LIST:
      return <AccessoryListContainer isAgent={isAgent} listId={id} {...props} />;
    case DEVICE_LIST:
      return <DeviceListContainer listId={id} {...props} />;
    case PAGE_HEADER:
      return <PageHeader {...props} />;
    case CONTACT_INFORMATION_BLOCK:
      return <ContactInformationBlock {...props} />;
    case SIMPLE_IMAGE:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <SimpleImage {...props} />
        </WidthWrapper>
      );
    case SIMPLE_TEXT:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <SimpleText {...props} />
        </WidthWrapper>
      );
    case LEGAL_TEXT:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <LegalText {...props} />
        </WidthWrapper>
      );
    case VIDEO_BLOCK:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <VideoBlock {...props} />
        </WidthWrapper>
      );
    case FAQ:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <Faq content={props} />
        </WidthWrapper>
      );
    case CAROUSEL: {
      const indexOfFirstH1Element = findFirstH1BlockIndex(blocks);
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <Carousel {...props}>
            {blocks.map((block, index) => (
              <Entry
                useFullWidth
                {...block}
                key={block.id}
                setH1Tag={props.setH1Tag && index === indexOfFirstH1Element}
                disableGutters
              />
            ))}
          </Carousel>
        </WidthWrapper>
      );
    }
    case GRID_CONTAINER: {
      const indexOfFirstH1Element = findFirstH1BlockIndex(blocks);
      return (
        <GridContainer useFullWidth={useFullWidth} {...props}>
          {blocks.map((block, index) => (
            <ErrorBoundary key={block.id}>
              <Entry
                useFullWidth
                {...block}
                setH1Tag={props.setH1Tag && index === indexOfFirstH1Element}
                disableGutters
              />
            </ErrorBoundary>
          ))}
        </GridContainer>
      );
    }
    case ROW_CONTAINER: {
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters>
          <RowContainer disableGutters {...props}>
            {blocks.map((block) => (
              <ErrorBoundary>
                <Entry useFullWidth {...block} key={block.id} disableGutters />
              </ErrorBoundary>
            ))}
          </RowContainer>
        </WidthWrapper>
      );
    }
    case ROAMING_COUNTRIES_DROPDOWN: {
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <RoamingCountriesDropdown {...props} />
        </WidthWrapper>
      );
    }
    case ICON_BLOCK:
      return <IconBlock {...props} />;
    case PRODUCT_SLIDER:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <ProductSliderEntry {...props} />
        </WidthWrapper>
      );
    case ONE_BLOCK:
      return <OneBlockEntry id={id} {...props} />;
    case NAVIGATION_BAR:
      return (
        <NavigationBar useFullWidth={useFullWidth} {...props}>
          {blocks.map((block) => (
            <Entry useFullWidth {...block} key={block.id} disableGutters />
          ))}
        </NavigationBar>
      );
    case TAB:
      return (
        <Tab {...props}>
          {blocks.map((block) => (
            <Entry useFullWidth {...block} key={block.id} disableGutters />
          ))}
        </Tab>
      );
    case NOTIFICATION_BAR:
      return <NotificationBar {...props} />;
    case STORE_LIST:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <StoreListContainer {...props} />
        </WidthWrapper>
      );
    case FAMILY_SLIDER:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <FamilySlider {...props} />
        </WidthWrapper>
      );
    case COUNTRY_LIST:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <CountryList {...props} />
        </WidthWrapper>
      );
    case SEARCH_BAR:
      return <SearchBar {...props} />;
    case FWA:
      return (
        <WidthWrapper useFullWidth={useFullWidth} disableGutters={disableGutters}>
          <FwaEntry {...props} />
        </WidthWrapper>
      );
    default:
      return null;
  }
};

Entry.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  useFullWidth: PropTypes.bool,
  blocks: PropTypes.arrayOf(PropTypes.shape(Entry.propTypes)),
  disableGutters: PropTypes.bool,
  isAgent: PropTypes.bool,
  setH1Tag: PropTypes.bool,
};

Entry.defaultProps = {
  useFullWidth: false,
  blocks: [],
  isAgent: false,
  disableGutters: false,
  setH1Tag: false,
};

export default Entry;
