import PropTypes from 'prop-types';

export const INSTALLMENT_OPTIONS_TYPE = PropTypes.shape({
  installmentOptionId: PropTypes.string,
  installmentPeriods: PropTypes.number,
  monthlyAmount: PropTypes.shape({
    originalAmountInclVat: PropTypes.number,
    installmentAmountInclVat: PropTypes.number,
  }),
});

export const PAYMENT_PLAN_OPTIONS_TYPE = PropTypes.shape({
  upfrontPlan: PropTypes.shape({
    amount: PropTypes.number,
    type: PropTypes.string,
    available: PropTypes.bool,
  }),
  installmentPlans: PropTypes.arrayOf(
    PropTypes.shape({
      periodAmount: PropTypes.number,
      duration: PropTypes.string,
      originalPeriodAmount: PropTypes.number,
      type: PropTypes.string,
    }),
  ),
  recurringPlan: PropTypes.shape({
    periodAmount: PropTypes.number,
    bindingPeriod: PropTypes.string,
    noticePeriod: PropTypes.string,
    type: PropTypes.string,
  }),
});

const PRICE_TYPE = PropTypes.shape({
  originalPrice: PropTypes.number,
  sellingPrice: PropTypes.number.isRequired,
  reductionAmount: PropTypes.number,
});

export const MEDIA_TYPE = PropTypes.shape({
  type: PropTypes.string,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
});

export const ACCESSORY_VARIANT_TYPE = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  offering: PropTypes.shape({
    id: PropTypes.string.isRequired,
    price: PRICE_TYPE.isRequired,
    paymentPlanOptions: PAYMENT_PLAN_OPTIONS_TYPE,
    salesEnd: PropTypes.string,
    highlightType: PropTypes.string,
    offeringHighlight: PropTypes.shape({
      highlightType: PropTypes.string,
      highlightReductionType: PropTypes.string,
      highlightPercentage: PropTypes.number,
      highlightAmount: PropTypes.number,
    }),
  }),
  vendorColourName: PropTypes.string,
  content: PropTypes.shape({
    colour: PropTypes.shape({
      primaryHexCode: PropTypes.string,
      secondaryHexCode: PropTypes.string,
    }),
    images: PropTypes.arrayOf(MEDIA_TYPE),
  }),
  expectedDelivery: PropTypes.shape({
    dateInterval: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    deliveryDate: PropTypes.string,
  }),
  filterMatch: PropTypes.bool,
  __typename: PropTypes.string,
});

export const ACCESSORY_TYPE = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string),
  slug: PropTypes.string,
  content: PropTypes.shape({
    shortDescription: PropTypes.string,
    shortDescriptionHeader: PropTypes.string,
    longDescription: PropTypes.string,
    highlight: PropTypes.string,
    uniqueSellingPoints: PropTypes.string,
    image: MEDIA_TYPE,
    specificationHighlights: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
  }),
  variants: PropTypes.arrayOf(ACCESSORY_VARIANT_TYPE),
  usps: PropTypes.arrayOf(PropTypes.string),
  __typename: PropTypes.string,
});

export const FILTER_TYPE = PropTypes.shape({
  filterKey: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.number,
  headline: PropTypes.string,
});

export const APPLIED_FILTERS_TYPE = PropTypes.shape({
  categories: FILTER_TYPE,
  secondaryCategories: FILTER_TYPE,
  brands: FILTER_TYPE,
  colours: FILTER_TYPE,
});

export const ACCESSORIES_TYPE = PropTypes.arrayOf(ACCESSORY_TYPE);

export const MARKETING_CARD_TYPE = PropTypes.shape({
  type: PropTypes.string,
  headlineText: PropTypes.string,
  headlineColor: PropTypes.string,
  headlineAlignment: PropTypes.string,
  bodyText: PropTypes.string,
  bodyAlignment: PropTypes.string,
  buttonText: PropTypes.string,
  linkPicker: PropTypes.shape({
    url: PropTypes.string,
    openAs: PropTypes.string,
  }),
  buttonColor: PropTypes.string,
  buttonAlignment: PropTypes.string,
  legalText: PropTypes.string,
  legalAlignment: PropTypes.string,
  legalColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.shape({
    url: PropTypes.string,
  }),
  splashText: PropTypes.string,
  splashTextColor: PropTypes.string,
  splashBackgroundColor: PropTypes.string,
  splashXPosition: PropTypes.number,
  splashYPosition: PropTypes.number,
});
