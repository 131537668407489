import { USP, BoxIcon, Container, LoadingBubbles } from '@3dk/3style';

const classes = {
  circleContainer: {
    width: '100%',
    height: '300px',
    margin: '150px auto',
    position: 'relative',
  },
  usp: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    paddingLeft: '20px',
  },
};

const LoadingAnimation = () => (
  <Container>
    <div css={classes.circleContainer}>
      <USP
        icon={<BoxIcon />}
        label="Afslutter din bestilling"
        size="medium"
        classes={{
          root: classes.usp,
          label: classes.label,
        }}
      />
      <LoadingBubbles />
    </div>
  </Container>
);

export default LoadingAnimation;
