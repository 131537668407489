import { useState } from 'react';
import PropTypes from 'prop-types';
import ProductLongSpecification from '../productDetailsContainer/productDetails/ProductLongSpecification';

const SubscriptionSpecifications = ({ specificationsJson }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const technicalSpecifications = specificationsJson
    ? { '': JSON.parse(specificationsJson) } // The empty string represents the subHeader, which is hidden on subscriptionPage
    : undefined;
  return (
    <ProductLongSpecification
      headline="## Specifikationer"
      headerColumnWidth="medium"
      technicalSpecifications={technicalSpecifications}
      isExpanded={isExpanded}
      setExpanded={setIsExpanded}
    />
  );
};

SubscriptionSpecifications.propTypes = {
  specificationsJson: PropTypes.string,
};

SubscriptionSpecifications.defaultProps = {
  specificationsJson: null,
};

export default SubscriptionSpecifications;
