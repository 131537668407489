const INCLUDED_PRODUCT_TYPES = {
  ROUTER: 'ROUTER',
};

const INCLUDED_PRODUCT_INVENTORY_TYPES = {
  LOAN: 'LOAN',
};

const getIncludedProductText = (inventoryType, subtype) => {
  switch (subtype) {
    case INCLUDED_PRODUCT_TYPES.ROUTER: {
      if (inventoryType === INCLUDED_PRODUCT_INVENTORY_TYPES.LOAN) {
        return 'Inkl. lånerouter';
      }
      return '';
    }
    default:
      return '';
  }
};

export default getIncludedProductText;
