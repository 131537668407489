const CHECKOUT_ACCORDION_IDS = {
  CONTACT_INFORMATION: 0,
  NUMBER_SELECTION: 1,
  DATA_DEVICE_SIM_SELECTION: 2,
  CREDIT_CHECK: 3,
  DELIVERY: 4,
  ACCEPT: 5,
};

export default CHECKOUT_ACCORDION_IDS;
