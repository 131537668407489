import { useEffect } from 'react';
import { Box } from '@3dk/3style';
import { useHistory, useLocation } from 'react-router-dom';
import { makeVar } from '@apollo/client';
import { error as logError } from '@hi3g-access/client-logger';
import Loading from '../../components/Loading';
import {
  AUTH_RESPONSE_PARAMETER,
  AUTH_SUCCESS,
  AUTH_SUCCESS_CONFLICTING_ID,
  AUTH_SUCCESS_UNKNOWN_NAME,
  LOGIN_ERROR,
} from './checkoutContainer/checkout/constants';

const defaultAuthenticationState = {
  verified: false,
  anonymous: false,
  skipped: false,
  authFailed: false,
  authSuccess: false,
  authMismatch: false,
};
export const authenticationStateVar = makeVar({
  ...defaultAuthenticationState,
});
export function resetAuthenticationState() {
  authenticationStateVar({ ...defaultAuthenticationState });
}

const VerifyAuth = () => {
  const location = useLocation();
  const history = useHistory();
  const isClient = typeof window !== 'undefined';
  const targetOrigin = isClient && window?.location?.origin;
  const redirectUrl = (isClient && localStorage.authDestinationUrl) || '/';

  useEffect(() => {
    const parameters = new URLSearchParams(location.search);
    const authResponse = parameters.get(AUTH_RESPONSE_PARAMETER);
    const hasErrorLogin = LOGIN_ERROR.includes(authResponse);
    if (hasErrorLogin) {
      logError('NemId login failed', authResponse);
      authenticationStateVar(Object.assign(authenticationStateVar(), { authFailed: true }));
      history.replace(redirectUrl);
    }

    if (isClient && authResponse) {
      if (hasErrorLogin) {
        logError('Error authenticating user', { error: authResponse });
        authenticationStateVar(Object.assign(authenticationStateVar(), { authFailed: true }));
      }

      const anonymous = authResponse === AUTH_SUCCESS_UNKNOWN_NAME;
      const authIdConflict = authResponse === AUTH_SUCCESS_CONFLICTING_ID;
      const authSuccess = authResponse === AUTH_SUCCESS || anonymous || authIdConflict;
      authenticationStateVar(
        Object.assign(authenticationStateVar(), {
          anonymous,
          authSuccess,
          authMismatch: authIdConflict,
        }),
      );
    }
    history.replace(redirectUrl);
  }, [isClient, location, targetOrigin, history, redirectUrl]);

  return (
    <Box display="inline-block">
      <Loading />
    </Box>
  );
};

export default VerifyAuth;
