import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import ApolloErrorHandler from '../../components/errorHandling/ApolloErrorHandler';
import Loading from '../../components/Loading';
import URL_SEARCH_PARAMETERS from '../../constants/urlSearchParameters';
import getDevicePageQuery from '../../apollo/queries/getDevicePageQuery';

const PreDcDevicePage = loadable(() => import('./PreDcDevicePageContainer/PreDcDevicePage'));

const PreDcDevicePageContainer = () => {
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);
  const variantIdFromUrl = urlSearchParams.get(URL_SEARCH_PARAMETERS.VARIANT_QUERY_PARAMETER);
  const variables = { options: { deviceId: null, urlSegment: null } };

  if (!variantIdFromUrl) {
    const urlSegment = location.pathname.split('/').filter(Boolean).pop();
    variables.options.urlSegment = urlSegment;
  } else {
    variables.options.deviceId = variantIdFromUrl;
  }

  const { data, error, loading, previousData } = useQuery(getDevicePageQuery, { variables });

  if (loading && !previousData) return <Loading colored />;
  if (error) return <ApolloErrorHandler error={error} />;

  // This is to never show a spinner, and keep old data shown while new data is being fetched.
  const devicePage = data?.devicePage ?? previousData.devicePage;

  const {
    defaultVariantOptions: { deviceId, paymentId, paymentType, subscriptionId },
  } = devicePage;

  const defaultVariantOptions = {
    deviceId,
    paymentId,
    paymentType,
    subscriptionId,
  };

  return <PreDcDevicePage devicePage={devicePage} defaultVariantOptions={defaultVariantOptions} />;
};

export default PreDcDevicePageContainer;
