import { gql } from '@apollo/client';

const getListPageTypesAndSlugsQuery = gql`
  query {
    listPages {
      type
      slug
    }
  }
`;

export default getListPageTypesAndSlugsQuery;
