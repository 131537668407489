import { Body, Banner, SignalBarIcon } from '@3dk/3style';
import { t } from 'i18next';
import Imgix, { Picture, Source } from 'react-imgix';
import PropTypes from 'prop-types';
import { fwaImageProps, fwaStatsProps } from './fwaPropTypes';
import convertRichText from '../../../../../../utils/convertRichText';

const classes = {
  container: {
    padding: '1rem 0',
    textAlign: 'center',
  },
  image: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  },
  signalIcon: {
    position: 'relative',
    top: '0.25rem',
    left: '0.5rem',
    fontSize: '2.5rem',
  },
};

const FwaSpeedometerBlock = ({ images, stats, speedometerText }) => {
  const startSpeed = stats.downloadSpeed?.from ?? 0;
  const sortedImages = [...(images || [])].sort((a, b) => (a.fromSpeed ?? 0) - (b.fromSpeed ?? 0));
  const image = sortedImages.find((img) => img.fromSpeed >= startSpeed);

  return (
    <div css={classes.container}>
      {image && (
        <Picture>
          <Source htmlAttributes={{ type: 'image/webp ' }} imgixParams={{ fm: 'webp' }} src={image.url} />
          <Imgix css={classes.image} src={image.url} width={300} />
        </Picture>
      )}

      <div css={classes.signalContainer}>
        <span css={classes.signalIcon}>
          <SignalBarIcon />
        </span>
        <Banner>{stats?.type}</Banner>
        <div>
          <Body>{t('FWA.Speedometer.Download_Label', stats?.downloadSpeed)}</Body>
          <Body>{t('FWA.Speedometer.Upload_Label', stats?.uploadSpeed)}</Body>
        </div>
      </div>
      {speedometerText && convertRichText(speedometerText)}
    </div>
  );
};

FwaSpeedometerBlock.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(fwaImageProps)),
  speedometerText: PropTypes.string,
  stats: PropTypes.shape(fwaStatsProps),
};

FwaSpeedometerBlock.defaultProps = {
  images: [],
  stats: {},
  speedometerText: undefined,
};

export default FwaSpeedometerBlock;
