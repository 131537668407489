import { gql } from '@apollo/client';
import gridContainerFragment from '../fragments/gridContainer';
import simpleTextFragment from '../fragments/simpleText';
import simpleImageFragment from '../fragments/simpleImage';
import seoFieldsFragment from '../fragments/seoFields';
import countryListFragment from '../fragments/countryList';

const subscriptionPageQuery = gql`
  query SubscriptionPage($slug: String!) {
    subscriptionPage(slug: $slug) {
      id
      type
      backgroundImage {
        description
        url
      }
      backgroundImageLargeDevices {
        description
        url
      }
      bindingPeriodMonths
      backgroundColor
      blocks {
        id
        type
        ...SimpleImageFragment
        ...GridContainerFragment
        ...SimpleTextFragment
      }
      specificationsIndexNumber
      countryList {
        id
        type
        ...CountryListFragment
      }
      cardHeader
      cardTitle
      dataLimit
      discount {
        numberOfMonths
        price {
          installment
        }
        text
      }
      dkOnly
      minimumPrice
      price {
        activation
        installment
      }
      specificationsJson
      subscriptionId
      title
      uspNames
      subscriptionCardTopBarImage
      ribbonTopText
      ribbonBottomText
      variants {
        displayName
        price {
          installment
        }
        slug
        subscriptionId
      }
      disableAldPreviewForProduct
      ...SeoFieldsFragment
    }
  }
  ${seoFieldsFragment}
  ${countryListFragment}
  ${simpleImageFragment}
  ${gridContainerFragment}
  ${simpleTextFragment}
`;

export default subscriptionPageQuery;
