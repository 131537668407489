import { Body, Caption, SECONDARY_BLACK, Small } from '@3dk/3style';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AddressCoverageCheck from './addressCoverage/AddressCoverageCheck';

const classes = {
  addressContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  addressCoverageContainer: {
    height: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  changeAddressButton: (theme) => ({
    margin: '0px 20px',
    color: theme.palette.SECONDARY_BLACK,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  displayedSpeedsContainer: {
    display: 'flex',
  },
  technologyBadge: (theme) => ({
    backgroundColor: theme.palette.PRIMARY_GREEN,
    color: theme.palette.PRIMARY_WHITE,
    padding: '1px 4px',
    borderRadius: '5px',
    marginRight: '5px',
  }),
};

const formatSpeedRange = (speed) => {
  const { from, to, unit } = speed || {};

  return `${from}-${to} ${unit}`;
};

const AddressCoverageResult = ({ setHasCheckedAddress }) => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedAddressCoverage, setSelectedAddressCoverage] = useState(null);

  const onAddressChange = () => {
    setSelectedAddress('');
    setHasCheckedAddress(false);
  };

  const { uploadSpeed, downloadSpeed, recommendedTechnology } = selectedAddressCoverage || {};

  const displayedSpeedRanges = `Download: ${formatSpeedRange(downloadSpeed)}. Upload: ${formatSpeedRange(
    uploadSpeed,
  )}.`;

  if (!selectedAddress || !selectedAddressCoverage) {
    return (
      <div css={classes.addressCoverageContainer}>
        <AddressCoverageCheck
          includeCurrentLocation={false}
          setSelectedAddress={setSelectedAddress}
          setSelectedAddressCoverage={setSelectedAddressCoverage}
        />
      </div>
    );
  }

  setHasCheckedAddress(true);
  return (
    <div css={classes.addressCoverageContainer}>
      <div css={classes.addressContainer}>
        <Body>{selectedAddress}</Body>
        <Caption css={classes.changeAddressButton} onClick={onAddressChange}>
          skift
        </Caption>
      </div>
      <div css={classes.displayedSpeedsContainer}>
        <Small type="bold" css={classes.technologyBadge}>
          {recommendedTechnology}
        </Small>
        <Small color={SECONDARY_BLACK}>{displayedSpeedRanges}</Small>
      </div>
    </div>
  );
};

AddressCoverageResult.propTypes = {
  setHasCheckedAddress: PropTypes.func,
};

AddressCoverageResult.defaultProps = {
  setHasCheckedAddress: () => null,
};

export default AddressCoverageResult;
