import { Container } from '@3dk/3style';
import PropTypes from 'prop-types';
import HeroBackground from './HeroBackground';

const classes = {
  background: (theme) => ({
    gridColumn: '1',
    gridRow: '1',
    height: '450px',
    [theme.breakpoints.up('lg')]: {
      height: '600px',
    },
  }),
  container: (theme) => ({
    gridColumn: '1',
    gridRow: '1',
    paddingTop: '50px',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  hero: {
    display: 'grid',
    gridTemplateColumns: '100%',
  },
};

const Hero = (props) => {
  const { background, backgroundColor, children, className } = props;

  return (
    <div css={classes.hero} className={className}>
      <HeroBackground css={classes.background} background={background} backgroundColor={backgroundColor} />
      <Container css={classes.container}>{children}</Container>
    </div>
  );
};

Hero.propTypes = {
  background: HeroBackground.propTypes.background,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Hero.defaultProps = {
  background: null,
  backgroundColor: null,
  children: null,
  className: null,
};

export default Hero;
