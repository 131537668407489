import { gql } from '@apollo/client';

const getAddressById = gql`
  query GetAddressById($addressId: String!) {
    getAddressById(id: $addressId) {
      displayName
      street
      streetNumber
      floor
      door
      city
      postalCode
    }
  }
`;
export default getAddressById;
