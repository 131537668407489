import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import Loading from '../../components/Loading';
import { ACCESSORY_BASE, CHECKOUT } from '../../constants/urls';
import eventEmitter from '../../utils/eventEmitter';
import getCartQuery from '../../apollo/queries/getCart';
import recoverCartMutation from '../../apollo/mutations/recoverCart';

const CartRecovery = () => {
  const location = useLocation();
  const params = useParams();
  const { cartId } = params;

  const [recoverCart, { loading, error }] = useMutation(recoverCartMutation, {
    onCompleted() {
      // We must reload /checkout from the server, because using react-router will prevent count of products from being updated, and thus will redirect customer to frontpage
      window.history.replaceState(null, null, ACCESSORY_BASE);
      window.location.assign(`${CHECKOUT}${location.search}`);
    },
    // Function that adds product to cache and update badge displaying count of products in cart
    update: (store, { data: updatedCart }) => {
      const data = { cart: { ...updatedCart.recoverCart } };

      const count = data.cart.items.length;
      eventEmitter.updateCountOfProductsInCart(count);

      store.writeQuery({
        query: getCartQuery,
        data,
      });
    },
  });

  useEffect(() => {
    recoverCart({
      variables: {
        cartId,
      },
    });
  }, [cartId, recoverCart]);

  if (loading) return <Loading colored />;
  if (error) return <ApolloErrorHandler error={error} />;

  return <Loading colored />;
};

export default CartRecovery;
