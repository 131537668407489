import { useLocation } from 'react-router-dom';
import { initializeClient, AuthContextProvider } from '@3dk/3common';
import GlobalHeaderComponent from './globalHeader/GlobalHeaderComponent';
import MinimalGlobalHeader from './globalHeader/MinimalGlobalHeader';
import FEATURE_FLAG from '../../utils/feature-flags';
import { CHECKOUT, PRE_DC_CHECKOUT } from '../../constants/urls';
import runtimeConfig from '../../utils/config';
import { useFeatureFlagContext } from '../../optimizely';

const { WEB_SHOP_ORIGIN_URL, CORS_DOMAINS } = runtimeConfig;

// In some environments, accessory shop and webshop are hosted on different domains (e.g. test and test02, test03 etc)
// In these cases, we want to include credentials, to attach SSG_SESSION cookie to request
const withCredentials = CORS_DOMAINS ? CORS_DOMAINS.split(',').includes(WEB_SHOP_ORIGIN_URL) : false;

initializeClient({
  baseURL: WEB_SHOP_ORIGIN_URL,
  withCredentials,
});

const GlobalHeader = () => {
  const location = useLocation();
  const isCheckout = location.pathname === PRE_DC_CHECKOUT || location.pathname === CHECKOUT;

  const { getFeatureFlag } = useFeatureFlagContext();
  const disableGlobalHeader = getFeatureFlag(FEATURE_FLAG.DISABLE_GLOBAL_HEADER, false);

  // If global header is disabled we still show a minimal global header in checkout
  if (disableGlobalHeader) {
    return isCheckout ? <MinimalGlobalHeader /> : null;
  }

  const disableAccountLevelDiscount = getFeatureFlag(FEATURE_FLAG.ALD_DISABLED, false);
  return (
    <AuthContextProvider>
      <GlobalHeaderComponent disableAccountLevelDiscount={disableAccountLevelDiscount} />
    </AuthContextProvider>
  );
};

export default GlobalHeader;
