import PropTypes from 'prop-types';
import { error as logError } from '@hi3g-access/client-logger';
import Status from './Status';
import PageNotFound from './pages/PageNotFound';

const Error = ({ code, error }) => {
  if (error) {
    if (code === 404) {
      logError({ code, error });
      return <PageNotFound />;
    }
  }
  const content = (
    <>
      <h1>Ups, der skete en fejl i vores ende</h1>
      <p>Vi arbejder på at udbedre fejlen snarest muligt..</p>
      {code && <p>Fejl kode: {code}</p>}
      {/* TODO (tkh) Add support contact information */}
      {/* Maybe use ContactInformationBlock? */}
    </>
  );

  if (code) {
    return <Status code={code}>{content}</Status>;
  }

  return content;
};

Error.propTypes = {
  code: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

Error.defaultProps = {
  code: undefined,
  error: undefined,
};

export default Error;
