import { IconMap, USP, Subhead, uspIconSizesToNumber } from '@3dk/3style';
import { extractLinkProps } from 'utils/richTextRenderOptions/renderLink';
import Image from 'components/Image';
import Flex from 'components/Flex/Flex';
import { getColorFromName } from './Colors/Colors';
import { ICON_BLOCK_SHAPE } from '../../../../../utils/propTypes/iconBlockType';
import LinkResolver from '../../../../../components/LinkResolver';

const getImgixImage = (image, size) => {
  const url = image?.url || image?.fields?.file.url;
  if (url) {
    const sizes = image?.width ? { ...image } : image?.fields?.file?.details?.image;
    const imageSizes = [
      sizes?.width > sizes?.height ? { mediaQueryMinWidth: 0, width: size } : { mediaQueryMinWidth: 0, height: size },
    ];
    return (
      <Flex justifyContent="center" alignItems="center" css={{ width: size, height: size }}>
        <Image alt={image?.description} src={url} sizes={imageSizes} />
      </Flex>
    );
  }
  return null;
};

const IconBlock = (props) => {
  const { icon, image, text, color, iconSize, link, textColor } = props;
  const { url, internalLinkPageType } = (link && extractLinkProps(link.fields || link)) || {};
  const iconColor = getColorFromName(color);

  const labelColor = getColorFromName(textColor);
  const size = iconSize?.toLowerCase();
  return (
    <LinkResolver
      linkType={link?.linkType || link?.fields?.linkType}
      url={url}
      internalLink={internalLinkPageType}
      anchorLink={link?.anchorLink || link?.fields?.anchorLink}
    >
      <USP
        vertical
        icon={icon ? IconMap[icon] : getImgixImage(image, uspIconSizesToNumber[size])}
        uspColor={iconColor}
        labelColor={labelColor}
        size={size}
        label={text}
        Typography={Subhead}
      />
    </LinkResolver>
  );
};

IconBlock.propTypes = ICON_BLOCK_SHAPE;

IconBlock.defaultProps = {
  text: null,
  icon: null,
  image: null,
  color: 'Black',
  iconSize: 'medium',
  link: null,
  textColor: 'Black',
};

export default IconBlock;
