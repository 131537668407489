import { gql } from '@apollo/client';

const deviceListFragment = gql`
  fragment DeviceListFragment on DeviceList {
    title
    marketingCards {
      id
      type
      headlineText
      headlineColor
      headlineAlignment
      bodyText
      bodyAlignment
      buttonText
      linkPicker {
        url
        openAs
      }
      buttonColor
      buttonAlignment
      legalText
      legalAlignment
      legalColor
      backgroundColor
      backgroundImage {
        url
      }
      splashText
      splashTextColor
      splashBackgroundColor
      splashXPosition
      splashYPosition
    }
    marketingCardPositions
    filterHeadline
    brandFilterHeadline
    brandFilter
    deviceType
  }
`;

export default deviceListFragment;
