export const LEGACY_PRODUCT_PREFIX = {
  DEVICE: '4_',
  SUBSCRIPTION: '2_',
  VAS: '6_',
  ACCESSORY: '7_',
};
const getOneClickProducts = (oneClickDevice, oneClickSubscriptions, oneClickAccessories, oneClickVas) => {
  const oneClickDeviceId = oneClickDevice ? `${LEGACY_PRODUCT_PREFIX.DEVICE}${oneClickDevice}` : '';
  const oneClickSubscriptionsIds = oneClickSubscriptions
    ? oneClickSubscriptions.map((subscription) => `${LEGACY_PRODUCT_PREFIX.SUBSCRIPTION}${subscription}`)
    : [];
  const oneClickAccessoriesIds = oneClickAccessories
    ? oneClickAccessories.map((accessory) => `${LEGACY_PRODUCT_PREFIX.ACCESSORY}${accessory}`)
    : [];
  const oneClickVasIds = oneClickVas ? oneClickVas.map((vas) => `${LEGACY_PRODUCT_PREFIX.VAS}${vas}`) : [];
  const oneClickProducts = [];
  const mainProduct = [];
  // We currently don't support adding only legacy accessories or addons, since a subscription is needed for checkout
  if (oneClickDeviceId) {
    mainProduct.push(oneClickDeviceId, oneClickSubscriptionsIds[0], ...oneClickAccessoriesIds, ...oneClickVasIds);
    oneClickProducts.push({ product: mainProduct.filter(Boolean), isMainProduct: true });
    oneClickSubscriptionsIds.slice(1).forEach((sub) => {
      oneClickProducts.push({ product: [sub], isMainProduct: false });
    });
  } else if (oneClickSubscriptionsIds.length > 0) {
    mainProduct.push(oneClickSubscriptionsIds[0], ...oneClickAccessoriesIds, ...oneClickVasIds);
    oneClickProducts.push({ product: mainProduct.filter(Boolean), isMainProduct: true });
    oneClickSubscriptionsIds.slice(1).forEach((sub) => {
      oneClickProducts.push({ product: [sub], isMainProduct: false });
    });
  }
  return oneClickProducts;
};

export default getOneClickProducts;
