import { gql } from '@apollo/client';
import legacyDevicePagePriceFragment from '../fragments/legacyDevicePagePrice';

const getDevicePageQuery = gql`
  query getDevicePage($options: Legacy_DevicePageOptionsInput!) {
    devicePage(options: $options) {
      brand
      content {
        longDescriptionHtml
        introductionTextHtml
        specificationHighlights {
          iconUrl
          title
        }
        technicalSpecificationsJson
      }
      id
      deviceId
      eanCode
      campaignId
      imageSplashText
      imageSplashColor
      sticker
      hideFromSearchEngines
      indexOfMostPopularSubscription
      insurance {
        id
        description
        heading
        readMoreBodyHtml
        monthlyPrice
        name
      }
      name
      price {
        ...DevicePage_Price
      }
      psid
      options {
        installment
        subscription
        subscriptionBindingPeriod
        upfront
      }
      seo {
        description
        title
      }
      slug
      type
      variantOptions {
        id
        content {
          color {
            primaryHexCode
          }
        }
        vendorColorName
      }
      detailedVariant {
        content {
          color {
            primaryHexCode
          }
          images {
            url
          }
        }
        deliveryTime
        id
        offering {
          paymentPlanOptions {
            installmentPlans {
              duration
              id
              periodAmount
            }
            upfrontPlan {
              amount
            }
          }
        }
        sizes {
          enabled
          id
          label
          sizeSplash
          upfrontPrice
        }
        subscriptions {
          id
          type
          label
          recurringPrice
          discountText
          splashText
          groupLabel
          usps {
            name
            description
            icon
          }
          uspImage
        }
        vendorColorName
      }
      defaultVariantOptions {
        deviceId
        paymentId
        paymentType
        subscriptionId
      }
      defaultVariantId
    }
  }
  ${legacyDevicePagePriceFragment}
`;

export default getDevicePageQuery;
