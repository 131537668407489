import { gql } from '@apollo/client';

const searchAddressByCoordinatesQuery = gql`
  query SearchAddressByCoordinates($longitude: Float, $latitude: Float, $radius: Float) {
    searchAddressByCoordinates(longitude: $longitude, latitude: $latitude, radius: $radius) {
      id
      latitude
      longitude
      displayName
    }
  }
`;

export default searchAddressByCoordinatesQuery;
