import { gql } from '@apollo/client';

const simpleTextFragment = gql`
  fragment SimpleTextFragment on SimpleText {
    id
    text
    fontColor
    fontColorPicker
    backgroundColor
    backgroundColorPicker
    textAlignment
    columns
    unsortedListIcon
    anchorTag
  }
`;

export default simpleTextFragment;
