/**
 * This function takes a string as input and parses it, returning the corresponding primitive value or the original string.
 * It handles cases where the input string represents boolean values ('true' or 'false'), null, undefined, or any other string,
 * returning the appropriate primitive type or the original string if no match is found.
 *
 * @example parseString('undefined') => undefined
 * @example parseString('false') => false
 * @example parseString('some string') => "some string"
 * @param {string} string
 * @returns {any}
 */
function parseString(string) {
  const lowerStr = string?.toLowerCase();
  if (lowerStr === 'null') return null;
  if (lowerStr === 'undefined') return undefined;
  if (lowerStr === 'false') return false;
  if (lowerStr === 'true') return true;
  return string;
}

export default parseString;
