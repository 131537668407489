import { gql } from '@apollo/client';

const getContentPageSlugsQuery = gql`
  query getContentPageSlugs {
    contentPages {
      slug
      hideFromSearchEngines
    }
  }
`;

export default getContentPageSlugsQuery;
