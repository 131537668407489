import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, IconMap } from '@3dk/3style';
import HelpFooterTab from './HelpFooterTab';

const makeClasses = (props) => ({
  container: (theme) => ({
    backgroundColor: theme.palette.PRIMARY_WHITE,
    borderRadius: '25px',
    padding: '15px',
  }),
  tabs: (theme) => ({
    borderBottomStyle: 'solid',
    borderColor: theme.palette.PRIMARY_GREY,
    borderWidth: '1px',
    '& .MuiTabs-indicator': {
      backgroundColor: props.isBusiness ? theme.palette.PRIMARY_BLUE : theme.palette.PRIMARY_ORANGE,
      height: 4,
    },
  }),
});

const HelpFooter = (props) => {
  const { footerTabs, isBusiness } = props;
  const [activeTab, setActiveTab] = useState(0);
  const { contactTabBlocks, callTabBlocks, findShopTabBlocks } = footerTabs;
  const classes = makeClasses({ isBusiness });

  const handleChange = (event, index) => {
    setActiveTab(index);
  };

  const tabContainer = [contactTabBlocks, callTabBlocks, findShopTabBlocks];

  return (
    <div css={classes.container}>
      <Tabs value={activeTab} onChange={handleChange} css={classes.tabs} left fixed>
        <Tab icon={IconMap.mail} label="Skriv" />
        <Tab icon={IconMap.phone} label="Ring" />
        <Tab icon={IconMap.pin} label="Find Butik" />
      </Tabs>

      {tabContainer.map((tabContent, index) => (
        <HelpFooterTab
          key={tabContent[0]?.id}
          activeTab={activeTab}
          tabIndex={index}
          blocks={tabContent}
          isBusiness={isBusiness}
        />
      ))}
    </div>
  );
};

HelpFooter.propTypes = {
  footerTabs: PropTypes.shape({
    title: PropTypes.string,
    contactTabBlocks: PropTypes.node,
    callTabBlocks: PropTypes.node,
    findShopTabBlocks: PropTypes.node,
  }).isRequired,
  isBusiness: PropTypes.bool.isRequired,
};

export default HelpFooter;
