import { gql } from '@apollo/client';
import confirmationFragment from '../fragments/confirmation';

const getConfirmationsQuery = gql`
  query getConfirmations {
    confirmations {
      ...Confirmation
    }
  }
  ${confirmationFragment}
`;

export default getConfirmationsQuery;
