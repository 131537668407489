import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { ClassNames } from '@emotion/react';
import { Headline, Title, Subhead, Body, Link, PRIMARY_BLACK } from '@3dk/3style';

const MarkdownComponent = (props) => {
  const { children, color, forceComponent, forceComponentType, ...rest } = props;
  return (
    children && (
      <ClassNames>
        {({ css }) => (
          <Markdown
            options={{
              overrides: {
                span: {
                  component: forceComponent || 'span',
                  props: {
                    component: forceComponentType,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.span),
                  },
                },
                div: {
                  component: forceComponent || 'div',
                  props: {
                    component: forceComponentType,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.div),
                  },
                },
                h2: {
                  component: forceComponent || Headline,
                  props: {
                    component: forceComponentType || 'h2',
                    color,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.h2),
                  },
                },
                h3: {
                  component: forceComponent || Title,
                  props: {
                    component: forceComponentType || 'h3',
                    type: 'black',
                    color,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.h3),
                  },
                },
                h4: {
                  component: forceComponent || Subhead,
                  props: {
                    component: forceComponentType || 'h4',
                    type: 'medium',
                    color,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.h4),
                  },
                },
                h5: {
                  component: forceComponent || Subhead,
                  props: {
                    component: forceComponentType || 'h5',
                    type: 'roman',
                    color,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.h5),
                  },
                },
                p: {
                  component: forceComponent || Body,
                  props: {
                    component: forceComponentType,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.p),
                    color,
                  },
                },
                li: {
                  component: forceComponent || Body,
                  props: {
                    component: forceComponentType || 'li',
                    color,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.li),
                  },
                },
                a: {
                  component: forceComponent || Link,
                  props: {
                    component: forceComponentType,
                    color,
                    className: rest.classes && css(rest.classes.forceComponent || rest.classes.a),
                  },
                },
              },
            }}
          >
            {children}
          </Markdown>
        )}
      </ClassNames>
    )
  );
};

MarkdownComponent.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  forceComponent: PropTypes.elementType,
  forceComponentType: PropTypes.string,
};

MarkdownComponent.defaultProps = {
  color: PRIMARY_BLACK,
  forceComponent: null,
  forceComponentType: '',
};

export default MarkdownComponent;
