import { gql } from '@apollo/client';

const checkCoverageQuery = gql`
  query CheckCoverage($location: FWA_Location) {
    checkCoverage(location: $location) {
      recommendedTechnology
      primaryProduct
      maxSpeed
      landingPage
      uploadSpeed {
        from
        to
        unit
      }
      downloadSpeed {
        from
        to
        unit
      }
    }
  }
`;

export default checkCoverageQuery;
