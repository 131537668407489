import { useFeatureFlagContext } from '../../optimizely';

/**
 *
 * @param flag - Flag name from Optimizely
 * @param Component - main Component
 * @param enabledIfError - should feature be enabled in case the feature flag cannot be fetched
 *
 * fetches flag from optimizely and displays component if component is enabled
 * otherwise null
 */
const withOptimizelyOff = (flag, Component, enabledIfError = true) =>
  function (props) {
    const { getFeatureFlag } = useFeatureFlagContext();
    const enabled = getFeatureFlag(flag, enabledIfError);
    if (!enabled) {
      return <Component {...props} />;
    }

    return null;
  };

export default withOptimizelyOff;
