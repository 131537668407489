import { Breadcrumbs as StyleBreadcrumbs } from '@3dk/3style';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Body } from '@3dk/3style/dist/lib/Atoms/Typography';

const classes = {
  text: (theme) => ({
    textDecoration: 'none',
    ':hover': {
      color: theme.palette.PRIMARY_BLACK,
      textDecoration: 'underline',
    },
  }),
};

export const homePageBreadCrumb = { name: 'Hjem', url: '/' };

export const BreadCrumbs = ({ breadCrumbs }) => (
  <StyleBreadcrumbs>
    {breadCrumbs.map((breadCrumb) =>
      breadCrumb.url ? (
        <Link css={classes.text} key={breadCrumb.name} to={breadCrumb.url}>
          {breadCrumb.name}
        </Link>
      ) : (
        <Body key={breadCrumb.name}>{breadCrumb.name}</Body>
      ),
    )}
  </StyleBreadcrumbs>
);

export const BreadCrumbsType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  }),
);

BreadCrumbs.propTypes = {
  breadCrumbs: BreadCrumbsType.isRequired,
};
