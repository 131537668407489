import PropTypes from 'prop-types';
import FwaAddressHeader from './FwaAddressHeader';
import FwaBundleCard from './FwaBundleCard';
import FwaSpeedometerBlock from './FwaSpeedometerBlock';
import { accountLevelDiscountProps, addressPropTypes, fwaImageProps, fwaSpeedProps, imageProps } from './fwaPropTypes';
import linkPropType from '../../../../../../utils/propTypes/link';
import legalTextPropType from '../../../../../../utils/propTypes/legalTextPropType';

const classes = {
  bundle: (theme) => ({
    display: 'grid',
    marginTop: '25px',
    columnGap: '25px',
    rowGap: '25px',
    [theme.breakpoints.up('md')]: {
      marginTop: '50px',
      columnGap: '50px',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
      alignItems: 'center',
    },
  }),
};

const FwaView = ({ fwaProps, address, coverage, children, accountLevelDiscount }) => {
  const { downloadSpeed, uploadSpeed, recommendedTechnology } = coverage || {};

  return (
    <section>
      {address && <FwaAddressHeader address={address} returnLink={fwaProps.searchLink} />}
      {children}
      {coverage && (
        <div css={classes.bundle}>
          <FwaBundleCard
            image={fwaProps.productImage}
            link={fwaProps.productLink}
            legalText={fwaProps.legalText}
            accountLevelDiscount={accountLevelDiscount}
          />
          <FwaSpeedometerBlock
            images={fwaProps?.speedImages}
            speedometerText={fwaProps.speedDescription}
            stats={{ downloadSpeed, uploadSpeed, type: recommendedTechnology }}
          />
        </div>
      )}
    </section>
  );
};

FwaView.propTypes = {
  fwaProps: PropTypes.shape({
    speedDescription: PropTypes.string,
    speedImages: PropTypes.arrayOf(PropTypes.shape(fwaImageProps)),
    searchLink: PropTypes.shape(linkPropType),
    productImage: PropTypes.shape(imageProps),
    productLink: PropTypes.shape(linkPropType),
    legalText: PropTypes.shape(legalTextPropType),
  }),
  address: PropTypes.shape(addressPropTypes),
  coverage: PropTypes.shape({
    downloadSpeed: PropTypes.shape(fwaSpeedProps),
    uploadSpeed: PropTypes.shape(fwaSpeedProps),
    recommendedTechnology: PropTypes.string,
  }),
  accountLevelDiscount: PropTypes.shape(accountLevelDiscountProps),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.array, PropTypes.node]),
};

FwaView.defaultProps = {
  fwaProps: undefined,
  address: undefined,
  coverage: undefined,
  accountLevelDiscount: undefined,
  children: undefined,
};
export default FwaView;
