import { gql } from '@apollo/client';

const getDueDiligenceCheck = gql`
  query DueDiligenceReport($reportId: String) {
    dueDiligenceReport(reportId: $reportId) {
      fraudCheckResults {
        provider
        certId
        result
        resultId
        timestamp
      }
      fmipCheckResults {
        provider
        certId
        result
        resultId
        timestamp
      }
    }
  }
`;

export default getDueDiligenceCheck;
