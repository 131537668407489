import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import runtimeConfig from '../../../utils/config';
import PageHelmet from '../PageHelmet';
import PageEntries from './page/PageEntries';

const { BASE_URI } = runtimeConfig;

const Page = (props) => {
  const { blocks, isAgent, canonicalUrl, ...rest } = props;
  const { pathname } = useLocation();

  return (
    <>
      <PageHelmet canonicalUrl={canonicalUrl || `${BASE_URI}${pathname}`} {...rest} />
      <PageEntries blocks={blocks} isAgent={isAgent} />
    </>
  );
};

Page.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
  openGraphTitle: PropTypes.string,
  openGraphType: PropTypes.string,
  openGraphDescription: PropTypes.string,
  openGraphImage: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  isAgent: PropTypes.bool,
  canonicalUrl: PropTypes.string,
};

Page.defaultProps = {
  blocks: [],
  metaTitle: null,
  metaDescription: null,
  metaKeywords: [],
  openGraphTitle: null,
  openGraphType: null,
  openGraphDescription: null,
  openGraphImage: {},
  isAgent: false,
  canonicalUrl: null,
};

export default Page;
