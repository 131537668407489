import { gql } from '@apollo/client';

const getExternalPageSlugsQuery = gql`
  query ExternalPageSlugs {
    externalPages {
      externalPageType
      slug
      hideFromSearchEngines
    }
  }
`;

export default getExternalPageSlugsQuery;
