import { Caption, CheckmarkIcon, ConfigurationOption, Divider } from '@3dk/3style';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useState } from 'react';
import SUBSCRIPTION_TYPES from '../../../constants/subscriptionTypes';
import AddressCoverageResult from '../../AddressCoverageResult';
import Configurator from '../../ProductPage/Configurator';
import PriceSummary from '../../ProductPage/PriceSummary';
import UspGrid from '../../UspGrid';
import PriceOverviewGrid from './PriceOverviewGrid';
import { useSubscriptionPageContext } from './SubscriptionProvider';
import IncludedProductInfo from './configurator/IncludedProductInfo';
import { DISCOUNT_TYPE, PRICE_TYPE, VARIANT_TYPE } from './utils/types';
import AldInformationView from '../../AldInformationView/AldInformationView';
import { accountLevelDiscountPropType } from '../../../utils/propTypes/accountLevelDiscount';
import { SIM_ALONE } from '../../../app/routes/pageContainer/page/constants';

const classes = {
  bottom: {
    marginTop: 'auto',
  },
  configurationOption: {
    marginBottom: '20px',
    padding: '0 10px',
    flexGrow: '0',
  },
  minimumPrice: (theme) => ({
    color: theme.palette.SECONDARY_BLACK,
    textDecoration: 'underline',
  }),
  finePrint: (theme) => ({
    fontStyle: 'italic',
    color: theme.palette.SECONDARY_BLACK,
  }),
  priceOverviewGrid: {
    marginBottom: '20px',
  },
  text: {
    margin: ' 0 auto 20px auto',
    textAlign: 'center',
  },
  uspGrid: {
    marginBottom: '30px',
  },
  uspIcon: (theme) => ({
    color: theme.palette.PRIMARY_GREEN,
  }),
  aldLink: {
    marginTop: '30px',
  },
};

const SubscriptionPageConfigurator = ({
  subscriptionPage,
  currentVariant,
  showAld,
  accountLevelDiscountPreview,
  isBetterAldDiscount,
}) => {
  const [hasCheckedAddress, setHasCheckedAddress] = useState(false);
  const { addToCart, isAddToCartLoading, selectVariant } = useSubscriptionPageContext();
  const { discount, finePrint, minimumPrice, price, subscriptionId, subtitle, title, uspNames, variants } =
    subscriptionPage;
  const { includedProducts, expectedDelivery } = currentVariant ?? {};

  const productRequiresAddressCheck = currentVariant.type === SUBSCRIPTION_TYPES.HOME_INTERNET;
  const hasDiscount = !!discount;
  const showPriceGridOverview = hasDiscount || price.activation > 0;
  const installment = hasDiscount ? discount.price.installment : price.installment;
  const upfront = hasDiscount ? discount.price.upfront : price.upfront;

  const usps = (uspNames ?? []).map((name) => ({
    icon: <CheckmarkIcon css={classes.uspIcon} />,
    label: name,
  }));

  return (
    <Configurator
      delivery={expectedDelivery}
      isAddToCartButtonDisabled={!hasCheckedAddress && productRequiresAddressCheck}
      isAddToCartButtonLoading={isAddToCartLoading}
      onAddToCartButtonClick={() => addToCart(subscriptionId)}
      title={title}
      subtitle={subtitle}
      isBetterAldDiscount={isBetterAldDiscount}
      useStickyButton
    >
      {usps && <UspGrid css={classes.uspGrid} usps={usps} />}
      {variants?.length > 0 && !variants.every((variant) => variant.label === variants[0].label) && (
        <ConfigurationOption
          css={classes.configurationOption}
          drawerItems={[{ group: 0, items: variants }]}
          buttonLabel={t('Product_Page.Configurator.Variant_Button_Label')}
          drawerLabel={t('Product_Page.Configurator.Variant_Drawer_Label')}
          onChange={selectVariant}
          selectedId={{ group: 0, id: subscriptionId }}
        />
      )}
      {productRequiresAddressCheck && (
        <>
          <Divider spacing="small" />
          <AddressCoverageResult setHasCheckedAddress={setHasCheckedAddress} />
        </>
      )}
      {includedProducts?.length > 0 && (
        <>
          <Divider spacing="small" />
          <IncludedProductInfo includedProducts={includedProducts} />
          <Divider spacing="small" />
        </>
      )}
      {showPriceGridOverview && <PriceOverviewGrid css={classes.priceOverviewGrid} discount={discount} price={price} />}
      <div css={classes.bottom}>
        <PriceSummary installment={installment} upfront={upfront} />
        <div css={classes.text}>
          {finePrint && <Caption css={classes.finePrint}>{finePrint}</Caption>}
          {minimumPrice && <Caption css={classes.minimumPrice}>Mindstepris: {minimumPrice} kr.</Caption>}
          {showAld && (
            <AldInformationView
              css={classes.aldLink}
              accountLevelDiscountPreview={accountLevelDiscountPreview}
              useCase={SIM_ALONE}
            />
          )}
        </div>
      </div>
    </Configurator>
  );
};

SubscriptionPageConfigurator.propTypes = {
  showAld: PropTypes.bool,
  subscriptionPage: PropTypes.shape({
    discount: DISCOUNT_TYPE,
    finePrint: PropTypes.string,
    minimumPrice: PropTypes.number,
    price: PRICE_TYPE,
    subscriptionId: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    uspNames: PropTypes.arrayOf(PropTypes.string),
    variants: PropTypes.arrayOf(VARIANT_TYPE.isRequired),
  }).isRequired,
  currentVariant: VARIANT_TYPE.isRequired,
  accountLevelDiscountPreview: accountLevelDiscountPropType,
  isBetterAldDiscount: PropTypes.bool,
};

SubscriptionPageConfigurator.defaultProps = {
  showAld: true,
  accountLevelDiscountPreview: {},
  isBetterAldDiscount: false,
};

export default SubscriptionPageConfigurator;
