import { SvgIconMap } from '@3dk/3style';
import convertRichText from '../../../../../utils/convertRichText';
import { getColorFromName, getFontColorFromName } from './Colors/Colors';
import SIMPLE_TEXT_TYPE from '../../../../../utils/propTypes/simpleTextType';
import renderOptions from '../../../../../utils/richTextRenderOptions/defaultRenderOptions';
import colorRenderOptions from '../../../../../utils/richTextRenderOptions/colorRenderOptions';

const makeClasses = (props) => ({
  root: (theme) => ({
    whiteSpace: 'pre-line',
    lineHeight: '1.2',
    '& .MuiTypography-root': {
      lineHeight: 1.2,
    },
    padding: '0.05px 8px', // PaddingTop and PaddingBottom disables collapsing margin
    backgroundColor: props.backgroundColorPicker ?? getColorFromName(props.backgroundColor),
    textAlign: props.textAlignment?.toLowerCase(),
    color: props.fontColorPicker ?? getFontColorFromName(props.fontColor),
    '> li, p, a, h2, h3, h4, h5, h6': {
      color: props.fontColorPicker ?? getFontColorFromName(props.fontColor),
    },
    '> h2, h3, h4, h5, h6': {
      marginBottom: '25px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '20px',
      },
    },
    '> h2, h3': {
      [theme.breakpoints.up('lg')]: {
        columnSpan: 'all',
        ...(props.columns ? { marginBottom: '15px' } : {}),
      },
    },
    '& > *': {
      textAlign: props.textAlignment?.toLowerCase(),
    },
    [theme.breakpoints.up('lg')]: {
      columnCount: props.columns > 1 ? props.columns : 'unset',
    },
    '> ol': {
      display: 'inline-block',
    },
    '> ul': {
      textAlign: 'left',
      display: 'flow-root',
      ...(props.unsortedListIcon && SvgIconMap[props.unsortedListIcon]
        ? {
            paddingLeft: 0,
            listStyleType: 'none',
            marginBlockStart: '0',
            '& > li': {
              position: 'relative',
              paddingLeft: '40px',
              '&::before': {
                content: '""',
                position: 'absolute',
                left: '0',
                top: '50%',
                transform: 'translateY(-50%) translateX(calc(40px - 1em - 10px))',
                width: '1em',
                height: '1em',
                backgroundSize: 'contain',
                mask: `url("${SvgIconMap[props.unsortedListIcon]}") no-repeat`,
                maskSize: 'contain',
                backgroundColor: props.fontColorPicker ?? getFontColorFromName(props.fontColor),
              },
            },
          }
        : {}),
    },
  }),
});

const SimpleText = (props) => {
  const {
    text,
    fontColor,
    backgroundColor,
    fontColorPicker,
    backgroundColorPicker,
    textAlignment,
    columns,
    unsortedListIcon,
    anchorTag,
  } = props;
  const classes = makeClasses({
    fontColor,
    backgroundColor,
    fontColorPicker,
    backgroundColorPicker,
    textAlignment,
    columns,
    unsortedListIcon,
  });

  const richText = convertRichText(text, {
    renderNode: { ...renderOptions.renderNode, ...colorRenderOptions.renderNode },
  });

  return (
    <div css={classes.root} id={anchorTag}>
      {richText}
    </div>
  );
};

SimpleText.propTypes = SIMPLE_TEXT_TYPE;

SimpleText.defaultProps = {
  backgroundColor: null,
  backgroundColorPicker: null,
  fontColor: null,
  fontColorPicker: null,
  textAlignment: 'Left',
  columns: undefined,
  unsortedListIcon: undefined,
  anchorTag: null,
};

export default SimpleText;
