import PropTypes from 'prop-types';
import { Background } from 'react-imgix';

const makeClasses = (props) => ({
  backgroundColor: {
    backgroundColor: props.backgroundColor,
  },
  largeOnly: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }),
  smallOnly: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  }),
});

const HeroBackground = (props) => {
  const { background, className, backgroundColor } = props;
  const classes = makeClasses({ backgroundColor });

  if (background !== null && (background.small || background.large)) {
    return (
      <>
        {background.small && <Background className={className} css={classes.smallOnly} src={background.small} />}
        {background.large && <Background className={className} css={classes.largeOnly} src={background.large} />}
      </>
    );
  }
  return <div css={classes.backgroundColor} className={className} />;
};

HeroBackground.propTypes = {
  background: PropTypes.shape({
    small: PropTypes.string,
    large: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

HeroBackground.defaultProps = {
  background: null,
  backgroundColor: null,
  className: null,
};

export default HeroBackground;
