import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import loadable from '@loadable/component';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import Loading from '../../components/Loading';
import getReceiptPageQuery from '../../apollo/queries/getReceiptPage';
import getAcceptedConfirmationQuery from '../../apollo/queries/getAcceptedConfirmation';
import receiptQuery from '../../apollo/queries/receipt';

const Receipt = loadable(() => import('./receiptContainer/Receipt'));

const isRwOrderReference = (orderReference) => {
  const orderReferenceRegex = /^[a-z0-9]{20}$/;
  return orderReferenceRegex.test(orderReference);
};

const ReceiptContainer = () => {
  const params = useParams();
  const { orderReference } = params;
  const isRwOrder = isRwOrderReference(orderReference);

  const { loading, error, data } = useQuery(getReceiptPageQuery);

  const {
    loading: orderLoading,
    error: orderError,
    data: orderData,
  } = useQuery(isRwOrder ? getAcceptedConfirmationQuery : receiptQuery, {
    variables: { orderReference },
  });

  if (loading || orderLoading) return <Loading colored />;

  if (error || orderError) return <ApolloErrorHandler error={error ?? orderError} />;

  return (
    <Receipt
      page={data?.receiptPage}
      order={isRwOrder ? orderData?.acceptedConfirmation : orderData?.receipt}
      orderId={orderReference}
      isRwOrder={isRwOrder}
    />
  );
};

export default ReceiptContainer;
