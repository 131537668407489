import PropTypes from 'prop-types';
import React from 'react';
import Imgix, { Picture, Source } from 'react-imgix';

const Image = (props) => {
  const { alt, sizes, src, title } = props;

  // Sort the sizes in decreasing minWidth order.
  const sortedSizes = sizes?.sort((a, b) => b.mediaQueryMinWidth - a.mediaQueryMinWidth) ?? [];
  return (
    <Picture>
      {sortedSizes.map((size) => (
        <React.Fragment key={`${size.mediaQueryMinWidth}-${size.width}-${size.height}`}>
          <Source
            htmlAttributes={{ media: `(min-width: ${size.mediaQueryMinWidth}px)`, type: 'image/webp' }}
            imgixParams={{ fill: 'solid', fit: 'fillmax', fm: 'webp' }}
            src={src}
            height={size.height}
            width={size.width}
          />
        </React.Fragment>
      ))}
      <Imgix
        htmlAttributes={{ alt, title, type: 'image/webp' }}
        imgixParams={{ fill: 'solid', fit: 'fillmax', fm: 'webp' }}
        src={src}
        height={sizes?.[0]?.height ?? 100}
        width={sizes?.[0]?.width ?? 100}
      />
    </Picture>
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  sizes: PropTypes.arrayOf(
    PropTypes.shape({
      mediaQueryMinWidth: PropTypes.number.isRequired,
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
  ).isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

Image.defaultProps = {
  title: null,
};

export default Image;
