import { Carousel } from '@3dk/3style';
import PropTypes from 'prop-types';
import React from 'react';
import Flex from '../Flex/Flex';
import Image from '../Image';

const classes = {
  extraComponent: {
    position: 'absolute',
    right: '35px',
    top: '150px',
  },
};

const imageSizes = [
  { mediaQueryMinWidth: 1024, width: 400, height: 400 },
  { mediaQueryMinWidth: 0, width: 300, height: 300 },
];

const ImageCarousel = (props) => {
  const { className, images } = props;
  return (
    images?.length > 0 && (
      <div className={className}>
        <Carousel autoplay={false} loop showPreviousAndNextButton>
          {images.map((image) => (
            <React.Fragment key={`${image.alt}-${image.src}-${image.title}`}>
              <div css={classes.extraComponent}>{image.ExtraComponent}</div>
              <Flex justifyContent="center">
                <Image alt={image.alt} src={image.src} title={image.title} sizes={imageSizes} />
              </Flex>
            </React.Fragment>
          ))}
        </Carousel>
      </div>
    )
  );
};

ImageCarousel.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ).isRequired,
};

ImageCarousel.defaultProps = {
  className: null,
};

export default ImageCarousel;
