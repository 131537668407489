import { gql } from '@apollo/client';
import linkFragment from './link';
import legalText from './legalText';

const FwaContainerFragment = gql`
  fragment FwaContainerFragment on FwaContainer {
    isBusiness
    speedDescription
    speedImages {
      fromSpeed
      url
    }
    searchLink {
      ...LinkFragment
    }
    validateLocation
    productImage {
      url
      description
      height
      width
    }
    productLink {
      ...LinkFragment
    }
    legalText {
      ...LegalTextFragment
    }
    formstackAddressTitle
    formstackProductTitle
    formstackBandwidthTitle
    formstackTechnologyTitle
    disableAldPredictions
  }
  ${linkFragment}
  ${legalText}
`;

export default FwaContainerFragment;
