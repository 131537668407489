import { useEffect, useState } from 'react';
import getTimeStampDiff from '../dateHelpers/getTimeStampDiff';

const DATE_FORMAT = 'en-GB';
const DANISH_TIME_ZONE = 'Europe/Copenhagen';

const useCountdown = ({ dailyExpiration, oneTimeExpiration }) => {
  const { expirationHour, expirationMinute } = dailyExpiration || {};
  const [countdownData, setCountdownData] = useState(null);

  const getCutoffTimeString = () => {
    const currentDanishTime = new Date().toLocaleString(DATE_FORMAT, {
      timeZone: DANISH_TIME_ZONE,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const cutoffTimeOfDay = `${expirationHour}:${expirationMinute}:00`;
    return [currentDanishTime, cutoffTimeOfDay].join(', ');
  };

  const countdownExpiration = oneTimeExpiration || getCutoffTimeString();

  const updateCountdown = () => {
    const currentDanishTime = new Date().toLocaleString(DATE_FORMAT, {
      timeZone: DANISH_TIME_ZONE,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
    });

    const remainingTime = getTimeStampDiff(Date.parse(currentDanishTime), Date.parse(countdownExpiration));

    const { days, hours, minutes, seconds } = remainingTime;
    const hasRemainingTime = days > 0 || hours > 0 || minutes > 0 || seconds > 0;

    setCountdownData({
      hasRemainingTime,
      remainingDays: days,
      remainingHours: hours,
      remainingMinutes: minutes,
      remainingSeconds: seconds,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return countdownData;
};

export default useCountdown;
