import { Body, Box, Caption } from '@3dk/3style';
import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';

const classes = {
  container: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  underlined: {
    textDecoration: 'underline',
    color: 'inherit',
  },
};

const SupportContactInfo = (props) => {
  const { className } = props;
  const theme = useTheme();
  const color = theme.palette.SECONDARY_BLACK;
  return (
    <>
      <Box css={classes.container} className={className} display={{ xs: 'flex', lg: 'none' }}>
        <Caption color={color}>Stadigvæk brug for hjælp?</Caption>
        <Caption color={color}>
          Besøg{' '}
          <a css={classes.underlined} href="mailto:kundeservice@3.dk">
            kundeservice
          </a>{' '}
          eller ring på{' '}
          <a css={classes.underlined} href="tel:004570313313">
            70 313 313
          </a>
        </Caption>
      </Box>
      <Box css={classes.container} className={className} display={{ xs: 'none', lg: 'flex' }}>
        <Body color={color}>Stadigvæk brug for hjælp?</Body>
        <Body color={color}>
          Besøg{' '}
          <a css={classes.underlined} href="mailto:kundeservice@3.dk">
            kundeservice
          </a>{' '}
          eller ring på 70 313 313
        </Body>
      </Box>
    </>
  );
};

SupportContactInfo.propTypes = {
  className: PropTypes.string,
};

SupportContactInfo.defaultProps = {
  className: null,
};

export default SupportContactInfo;
