import { gql } from '@apollo/client';
import pageHeaderFragment from './pageHeader';
import deviceListFragment from './deviceList';
import faqFragment from './faq';
import roamingCountriesDropdown from './roamingCountriesDropdown';
import productListFragment from './productList';
import productSliderFragment from './productSlider';
import simpleImageFragment from './simpleImage';
import simpleTextFragment from './simpleText';
import videoBlockFragment from './videoBlock';
import storeListFragment from './storeList';
import notificationBarFragment from './notificationBar';
import oneBlockFragment from './oneBlock';
import iframeFragment from './iframe';
import contentfulSubscriptionCardFragment from './contentfulSubscriptionCard';
import iconBlockFragment from './iconBlock';
import SearchBarFragment from './searchBar';
import legalTextFragment from './legalText';
import fwaContainerFragment from './fwaContainer';
import familySliderFragment from './familySlider';
import countryListFragment from './countryList';

// Remember to add your fragment as a ContentfulEntity in the apolloClient cache (see possible types),
// if you not add it there your data would appear in network but you will not be able to access it
const blockFragment = gql`
  fragment BlockFragment on ContentfulEntity {
    id
    type
    ...Contentful_SubscriptionCardFragment
    ...IFrameFragment
    ...PageHeaderFragment
    ...ProductListFragment
    ...DeviceListFragment
    ...FaqFragment
    ...SimpleImageFragment
    ...SimpleTextFragment
    ...VideoBlockFragment
    ...ProductSliderFragment
    ...NotificationBarFragment
    ...StoreListFragment
    ...OneBlockFragment
    ...LegalTextFragment
    ...IconBlockFragment
    ...SearchBarFragment
    ...RoamingCountriesDropdown
    ...FwaContainerFragment
    ...FamilySliderFragment
    ...CountryListFragment
  }
  ${SearchBarFragment}
  ${contentfulSubscriptionCardFragment}
  ${iframeFragment}
  ${pageHeaderFragment}
  ${deviceListFragment}
  ${faqFragment}
  ${productListFragment}
  ${productSliderFragment}
  ${simpleImageFragment}
  ${simpleTextFragment}
  ${videoBlockFragment}
  ${storeListFragment}
  ${notificationBarFragment}
  ${oneBlockFragment}
  ${iconBlockFragment}
  ${legalTextFragment}
  ${roamingCountriesDropdown}
  ${fwaContainerFragment}
  ${familySliderFragment}
  ${countryListFragment}
`;

export default blockFragment;
