import PropTypes from 'prop-types';
import Imgix, { Picture, Source } from 'react-imgix';
import { SimpleSelect, Headline, ChevronDownIcon } from '@3dk/3style';
import roamingCountriesQuery from 'apollo/queries/getRoamingCountries';
import { useQuery } from '@apollo/client';
import { getColorFromName, getFontColorFromBackgroundColor } from './Colors/Colors';
import SimpleText from './SimpleText';
import Error from '../../../../../components/Error';
import Loading from '../../../../../components/Loading';

const makeClasses = (props) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropdownContainer: {
    height: '140px',
    background: getColorFromName(props.dropdownColor),
  },
  image: {
    paddingBottom: '15px',
  },
  dropdown: (theme) => ({
    background: theme.palette.PRIMARY_WHITE,
    width: '80%',
    '& svg': {
      color: theme.palette.PRIMARY_ORANGE,
      width: '50px',
      fontSize: '25px',
    },
  }),
  text: {
    height: '140px',
    overflow: 'hidden',
  },
  headline: {
    color: getFontColorFromBackgroundColor(props.dropdownColor),
    padding: '25px 0 5px 0',
  },
});

// This value corresponds to to dropdownType field in contentful.
// It should no be changed unless the field is changed
const PRICES_FROM_DK = 'Priser fra Danmark';

const RoamingCountriesDropdown = ({
  image,
  text,
  label,
  dropdownHeadline,
  dropdownType,
  dropdownColor,
  isBusiness,
}) => {
  const { data, loading, error } = useQuery(roamingCountriesQuery);

  if (loading) {
    return <Loading colored />;
  }

  if (error) {
    return <Error />;
  }

  const isPricesFromDk = dropdownType === PRICES_FROM_DK;
  const urlSlug = isBusiness ? 'business' : 'privat';
  const classes = makeClasses({ dropdownColor });
  const imageUrl = image && image.url;
  const countryList = [...(data?.roamingCountries?.attributes?.countries || [])];
  countryList?.sort((a, b) => a.name.localeCompare(b.name));
  const countryListOptions = countryList.map((country) => ({
    label: country?.name,
    value: country?.name,
  }));

  const handleChange = (event) => {
    const country = event.target.value;
    window.location.href = isPricesFromDk
      ? `https://assets.3.dk/udland/${urlSlug}_priser_fra_danmark_til_udlandet#${country}`
      : `https:/assets.3.dk/udland/${urlSlug}_priser_i_udlandet#${country}`;
  };

  return (
    <div css={classes.root}>
      {image && (
        <div css={classes.image}>
          <Picture>
            <Source
              imgixParams={{ fit: 'max', fm: 'webp' }}
              height="180px"
              width="100%"
              htmlAttributes={{ type: 'image/webp' }}
              src={imageUrl}
            />
            <Imgix imgixParams={{ fit: 'max' }} width="100%" height="180px" src={imageUrl} />
          </Picture>
        </div>
      )}
      {text && (
        <div css={classes.text}>
          <SimpleText text={text} fontColor="Black" backgroundColor="None" textAlignment="Center" />
        </div>
      )}
      <div css={[classes.root, classes.dropdownContainer]}>
        <Headline css={classes.headline}>{dropdownHeadline}</Headline>
        <SimpleSelect
          css={classes.dropdown}
          placeholder={label}
          displayEmpty
          onChange={handleChange}
          options={countryListOptions}
          IconComponent={ChevronDownIcon}
        />
      </div>
    </div>
  );
};

RoamingCountriesDropdown.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  text: PropTypes.string,
  dropdownHeadline: PropTypes.string,
  dropdownType: PropTypes.string,
  dropdownColor: PropTypes.string,
  label: PropTypes.string,
  isBusiness: PropTypes.bool,
};

RoamingCountriesDropdown.defaultProps = {
  image: undefined,
  text: undefined,
  dropdownHeadline: undefined,
  dropdownType: undefined,
  dropdownColor: 'none',
  label: undefined,
  isBusiness: false,
};
export default RoamingCountriesDropdown;
