import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import runtimeConfig from '../utils/config';
import { DATA_LOCATION, getDataToInject } from '../utils/dataResolver/dataResolver';
import useIframeInjectionEvent from '../utils/customEvents/IframeInjectDataEvent';

const { BASE_URI } = runtimeConfig;
const URL_LOCATION = '{urlLocation}';

const IFrame = ({ anchor, height, source, contentDescription, dataToInject }) => {
  // React hooks has to be called at the same time each render. Early return means this must be called first.
  const { pathname, search } = useLocation();
  const ref = useRef(null);
  const injectData = () => {
    if (ref.current) {
      const iframeMessage = getDataToInject(dataToInject);
      ref.current.contentWindow.postMessage(iframeMessage, source);
    }
  };

  useIframeInjectionEvent(
    (event) => {
      if (ref.current) {
        ref.current.contentWindow.postMessage(event.detail, source);
      }
    },
    [ref.current, injectData],
  );

  if (!source) {
    return null;
  }

  // The search term allows for user-input. To avoid Cros Site Scripting (XSS) we encode it.
  const replacementSource = encodeURI(`${BASE_URI}${pathname}${search}`);
  const needsSourceUpdate = source.includes(URL_LOCATION);
  const updatedSource = source.replace(URL_LOCATION, replacementSource);
  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      id={anchor}
      ref={ref}
      onLoad={injectData}
      title={contentDescription}
      height={height}
      src={needsSourceUpdate ? updatedSource : source}
      style={{ border: 'none' }}
      width="100%"
    />
  );
};

IFrame.propTypes = {
  height: PropTypes.number,
  source: PropTypes.string,
  contentDescription: PropTypes.string,
  dataToInject: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        location: PropTypes.oneOf(Object.values(DATA_LOCATION)),
        propertyName: PropTypes.string,
      }),
    ),
    PropTypes.string,
  ]),
  anchor: PropTypes.string,
};

IFrame.defaultProps = {
  height: 100,
  contentDescription: '',
  source: '',
  dataToInject: undefined,
  anchor: undefined,
};

export default IFrame;
