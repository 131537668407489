import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import getProductQuery from '../../apollo/queries/getProduct';
import Loading from '../../components/Loading';
import getAccessoryBySlugAgentQuery from '../../apollo/queries/agentSales/accessoryBySlug';

const ProductDetails = loadable(() => import(/* webpackPrefetch: true */ './productDetailsContainer/ProductDetails'));

const ProductDetailsContainer = (props) => {
  const params = useParams();
  const { id } = params;
  const { isAgent } = props;

  const { loading, error, data } = useQuery(isAgent ? getAccessoryBySlugAgentQuery : getProductQuery, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { id },
    skip: !id,
  });

  if (loading) return <Loading colored />;
  if (error) return <ApolloErrorHandler error={error} />;

  return (
    <ProductDetails
      accessory={isAgent ? data?.accessoryBySlugAgent : data?.accessoryBySlugV4}
      hideBreadCrumbs={isAgent}
      {...props}
    />
  );
};

ProductDetailsContainer.propTypes = {
  isAgent: PropTypes.bool,
};

ProductDetailsContainer.defaultProps = {
  isAgent: false,
};

export default ProductDetailsContainer;
