// Routes Types
const SUBSCRIPTION_CARD = 'subscriptioncard';
const ICON_BLOCK = 'iconblock';
const IFRAME = 'iframe';
const PRODUCT_LIST = 'productlist';
const DEVICE_LIST = 'devicelist';
const PAGE_HEADER = 'pageheader';
const CONTACT_INFORMATION_BLOCK = 'contactinformationblock';
const FAQ = 'faq';
const CAROUSEL = 'carousel';
const LEGAL_TEXT = 'legaltext';
const GRID_CONTAINER = 'gridcontainer';
const ROAMING_COUNTRIES_DROPDOWN = 'roamingcountriesdropdown';
const ROW_CONTAINER = 'rowcontainer';
const SIMPLE_IMAGE = 'simpleimage';
const SIMPLE_TEXT = 'simpletext';
const VIDEO_BLOCK = 'videoblock';
const PRODUCT_SLIDER = 'productslider';
const ONE_BLOCK = 'oneblock';
const NOTIFICATION_BAR = 'notificationbar';
const STORE_LIST = 'storelist';
const COUNTDOWN = 'countdown';
const SEARCH_BAR = 'searchbar';
const NAVIGATION_BAR = 'navigationbar';
const TAB = 'tab';
const FWA = 'fwacontainer';
const SIM_ALONE = 'simalone';
const LANDING_PAGE = 'landingpage';
const FAMILY_SLIDER = 'familyslider';
const COUNTRY_LIST = 'countrylist';

export {
  SUBSCRIPTION_CARD,
  ICON_BLOCK,
  IFRAME,
  PRODUCT_LIST,
  DEVICE_LIST,
  PAGE_HEADER,
  CONTACT_INFORMATION_BLOCK,
  FAQ,
  CAROUSEL,
  GRID_CONTAINER,
  LEGAL_TEXT,
  ROAMING_COUNTRIES_DROPDOWN,
  ROW_CONTAINER,
  NAVIGATION_BAR,
  SIMPLE_IMAGE,
  SIMPLE_TEXT,
  VIDEO_BLOCK,
  PRODUCT_SLIDER,
  ONE_BLOCK,
  NOTIFICATION_BAR,
  STORE_LIST,
  COUNTDOWN,
  SEARCH_BAR,
  TAB,
  FWA,
  SIM_ALONE,
  LANDING_PAGE,
  FAMILY_SLIDER,
  COUNTRY_LIST,
};
