import { gql } from '@apollo/client';
import blockFragment from '../fragments/block';

const getHelpPageFooterQuery = gql`
  query getHelpPageFooter {
    helpPageFooter {
      title
      contactTabBlocks {
        ...BlockFragment
      }
      callTabBlocks {
        ...BlockFragment
      }
      findShopTabBlocks {
        ...BlockFragment
      }
    }
  }
  ${blockFragment}
`;

export default getHelpPageFooterQuery;
