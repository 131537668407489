import { gql } from '@apollo/client';

const roamingCountriesDropdown = gql`
  fragment RoamingCountriesDropdown on RoamingCountriesDropdown {
    image {
      url
    }
    text
    dropdownHeadline
    dropdownType
    dropdownColor
    label
    isBusiness
  }
`;

export default roamingCountriesDropdown;
