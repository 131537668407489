import { gql } from '@apollo/client';
import {
  legacyAccessoriesFragment,
  legacyAddonsFragment,
  legacyDeviceFragment,
  legacySimCardFragment,
  legacySubscriptionFragment,
} from '../fragments/legacyCart';

const getAccountLevelDiscountPreviewQuery = gql`
  query GetAccountLevelDiscountPreview($items: [String!]!, $upfront: Float, $bundledProducts: [[String!]]) {
    getAccountLevelDiscountPreview(items: $items, upfront: $upfront, bundledProducts: $bundledProducts) {
      accountLevelDiscountPreview
      currentAccountLevelDiscount
      products {
        id
        bindingPeriod
        monthlyInstallmentPeriod
        subscription {
          ...legacySubscriptionFragment
        }
        addons {
          ...legacyAddonsFragment
        }
        accessories {
          ...legacyAccessoriesFragment
        }
        device {
          ...legacyDeviceFragment
        }
        simCard {
          ...legacySimCardFragment
        }
      }
    }
  }
  ${legacyAddonsFragment}
  ${legacyAccessoriesFragment}
  ${legacySimCardFragment}
  ${legacyDeviceFragment}
  ${legacySubscriptionFragment}
`;

export default getAccountLevelDiscountPreviewQuery;
