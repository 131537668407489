import { gql } from '@apollo/client';

const getReceiptPageQuery = gql`
  query getReceiptPage {
    receiptPage {
      metaTitle
      metaDescription
      timeToReceipt
      bannerBackgroundImage {
        url
        description
      }
      marketingCards {
        id
        headlineText
        headlineColor
        headlineAlignment
        bodyText
        bodyAlignment
        buttonText
        linkPicker {
          url
          openAs
        }
        buttonColor
        buttonAlignment
        legalText
        legalAlignment
        legalColor
        backgroundColor
        backgroundImage {
          url
        }
        splashText
        splashTextColor
        splashBackgroundColor
        splashXPosition
        splashYPosition
      }
      blocks {
        type
        id
        ... on ContactInformationBlock {
          headline
          callText
          phonenumber
          emailText
          email
          openingHoursHeadline
          openingHoursWeekdaysText
          openingHoursWeekdaysValue
          openingHoursFridayText
          openingHoursFridayValue
          openingHoursNotWeekdaysText
          openingHoursNotWeekdaysValue
        }
      }
      disabledCriiptoReceiptText
    }
  }
`;

export default getReceiptPageQuery;
