import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LoadingBubbles } from '@3dk/3style';
import useRecoverBasket from '../../utils/hooks/useRecoverBasket';
import { PRE_DC_CHECKOUT, CHECKOUT } from '../../constants/urls';
import updateCwaCaseIdMutation from '../../apollo/mutations/updateCwaCaseId';
import cartIsEmpty from '../../utils/cart/dc/cartIsEmpty';
import legacyCartIsEmpty from '../../utils/cart/legacy/legacyCartIsEmpty';
import CHECKOUT_ACCORDION_IDS from '../../constants/checkoutAccordionIds';
import URL_SEARCH_PARAMETERS from '../../constants/urlSearchParameters';

const classes = {
  loadingContainer: { minHeight: '340px' },
};

const UnifiedCartRecovery = () => {
  const { recoverBasket } = useRecoverBasket();
  const history = useHistory();
  const location = useLocation();
  const parameters = new URLSearchParams(location.search);
  const caseId = parameters.get(URL_SEARCH_PARAMETERS.CASE_ID);

  const [updateCaseId] = useMutation(updateCwaCaseIdMutation);

  const resolvePromisesAndNavigate = async (recoverCartPromise, recoverLegacyCartPromise) => {
    const [cartResult, legacyCartResult] = await Promise.allSettled([recoverCartPromise, recoverLegacyCartPromise]);

    const legacyCart = legacyCartResult?.value?.data?.recoverBasket?.response;
    const cart = cartResult?.value?.data?.recoverCart;

    const cartHasOffers = !cartIsEmpty(cart);
    const legacyCartHasOffers = !legacyCartIsEmpty(legacyCart);

    ['legacyCartId', 'cartId', URL_SEARCH_PARAMETERS.CASE_ID].forEach((param) => parameters.delete(param));
    let searchParams = parameters.size > 0 ? `?${parameters}` : '';

    if (legacyCartHasOffers) {
      if (caseId) {
        parameters.append(URL_SEARCH_PARAMETERS.STEP_ID, CHECKOUT_ACCORDION_IDS.CREDIT_CHECK);
      }
      searchParams = parameters.size > 0 ? `?${parameters}` : '';
      history.replace(`${PRE_DC_CHECKOUT}${searchParams}`);
    } else if (cartHasOffers) {
      history.replace(`${CHECKOUT}${searchParams}`);
    } else {
      window.location.href = `/${searchParams}`;
    }
  };

  useEffect(() => {
    const { recoverCartPromise, recoverLegacyCartPromise } = recoverBasket(true);
    if (caseId) {
      updateCaseId({ variables: { caseId } }).then(() =>
        resolvePromisesAndNavigate(recoverCartPromise, recoverLegacyCartPromise),
      );
    } else {
      resolvePromisesAndNavigate(recoverCartPromise, recoverLegacyCartPromise);
    }
  }, []);

  return <LoadingBubbles css={classes.loadingContainer} />;
};

export default UnifiedCartRecovery;
