import { gql } from '@apollo/client';
import linkFragment from './link';

const oneBlockFragment = gql`
  fragment OneBlockFragment on OneBlock {
    id
    header
    subheader
    contentText
    links {
      ...LinkFragment
    }
    clickable
    anchorTag
    splashLines
    disclaimer
    labels
    backgroundMedia {
      url
    }
    desktopBackgroundMedia {
      url
    }
    image {
      id
      url
      title
      description
    }
    layout
  }
  ${linkFragment}
`;

export default oneBlockFragment;
