import { gql } from '@apollo/client';
import linkFragment from './link';

const contentfulSubscriptionCardFragment = gql`
  fragment Contentful_SubscriptionCardFragment on Contentful_SubscriptionCard {
    title
    header
    subTitle
    ribbonTopText
    ribbonBottomText
    pdbId
    disclaimerText
    color
    buttonText
    buttonLink
    button {
      ...LinkFragment
    }
  }
  ${linkFragment}
`;

export default contentfulSubscriptionCardFragment;
