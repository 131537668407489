import PropTypes from 'prop-types';

const makeClasses = (props) => ({
  flex: {
    display: 'flex',
    alignItems: props.alignItems,
    flex: props.flex,
    flexDirection: props.flexDirection,
    justifyContent: props.justifyContent,
  },
});

const Flex = (props) => {
  const { alignItems, children, className, flex, flexDirection, justifyContent } = props;

  const classes = makeClasses({ alignItems, flex, flexDirection, justifyContent });
  return (
    <div className={className} css={classes.flex}>
      {children}
    </div>
  );
};

Flex.propTypes = {
  alignItems: PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end', 'baseline']),
  children: PropTypes.node,
  className: PropTypes.string,
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
    'start',
    'end',
    'left',
    'right',
  ]),
};

Flex.defaultProps = {
  alignItems: null,
  children: null,
  className: null,
  flex: null,
  flexDirection: null,
  justifyContent: null,
};

export default Flex;
