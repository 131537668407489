import { Box, Banner, Body, Headline, Title, Subhead } from '@3dk/3style';
import Imgix, { Picture, Source } from 'react-imgix';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import getInternalLinkPageType from 'utils/url/getInternalLinkPageType';
import LinkResolver from '../../components/LinkResolver';
import { COUNTDOWN, ICON_BLOCK, SEARCH_BAR } from '../../app/routes/pageContainer/page/constants';
import Countdown from '../../components/Countdown';
import IconBlock from '../../app/routes/pageContainer/page/Entry/IconBlock';
import SearchBar from '../../app/routes/pageContainer/page/Entry/SearchBar';
import renderLink from './renderLink';
import LINK_TYPES from '../../constants/linkTypes';

const makeClasses = (props) => ({
  image: {
    '& > Picture > img': {
      maxWidth: '100%',
      width: props.width,
    },
  },
});

const renderOptions = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      // eslint-disable-next-line react/destructuring-assignment
      const { fields } = node.data.target;
      return (
        <LinkResolver
          linkType={LINK_TYPES.ENTRY}
          url={fields?.slug}
          internalLink={{ type: getInternalLinkPageType(fields) }}
        >
          {children}
        </LinkResolver>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      // eslint-disable-next-line react/destructuring-assignment
      const { file } = node.data.target.fields;
      return (
        <LinkResolver linkType={LINK_TYPES.ASSET} url={file.url}>
          {children}
        </LinkResolver>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Body color="inherit" css={{ margin: '0 0 16px 0' }}>
        {children}
      </Body>
    ),
    [BLOCKS.HEADING_2]: (node, children) => <Banner as="h2">{children}</Banner>,
    [BLOCKS.HEADING_3]: (node, children) => <Headline as="h3">{children}</Headline>,
    [BLOCKS.HEADING_4]: (node, children) => <Title as="h4">{children}</Title>,
    [BLOCKS.HEADING_5]: (node, children) => <Subhead as="h5">{children}</Subhead>,
    [BLOCKS.LIST_ITEM]: (_, children) => <Body as="li">{children}</Body>,
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;
      const { sys, fields } = data.target || {};

      switch (sys?.contentType?.sys?.id.toLowerCase()) {
        case ICON_BLOCK:
          return (
            <div css={{ display: 'inline-flex', verticalAlign: 'sub' }}>
              <IconBlock {...fields} />
            </div>
          );
        case COUNTDOWN:
          return <Countdown asInlineEntry {...fields} />;
        case SEARCH_BAR:
          return <SearchBar {...fields} />;
        default:
          return <span css={{ marginRight: '15px' }}> {renderLink(fields)} </span>;
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { data } = node;
      const { sys, fields } = data.target || {};

      switch (sys?.contentType?.sys?.id.toLowerCase()) {
        case COUNTDOWN:
          return <Countdown {...fields} />;
        case ICON_BLOCK:
          return (
            <div css={{ display: 'inline-flex', verticalAlign: 'sub' }}>
              <IconBlock {...fields} />
            </div>
          );
        case SEARCH_BAR:
          return <SearchBar {...fields} />;
        default:
          return <span>{renderLink(fields)}</span>;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      // eslint-disable-next-line react/destructuring-assignment
      const { file: assetFile } = node.data.target.fields;
      const classes = makeClasses({ width: assetFile.details.image.width });
      return (
        <Box sx={classes.image}>
          <Picture>
            <Source imgixParams={{ fm: 'webp' }} htmlAttributes={{ type: 'image/webp' }} src={assetFile?.url} />
            <Imgix src={assetFile?.url} />
          </Picture>
        </Box>
      );
    },
  },
};

export default renderOptions;
