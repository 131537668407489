import { useState } from 'react';
import { GlobalHeader, Snackbar } from '@3dk/3style';
import { useMinimalAuth } from '@3dk/3common';
import WidthWrapper from 'components/WidthWrapper';

// This component is used to avoid some of the heavy calls to my3 that the global header makes.
// It is a minimal global header with only the option to logout
const MinimalGlobalHeader = () => {
  const [open, setOpen] = useState(false);

  const { user, useLogout } = useMinimalAuth({
    logoutMutationOptions: {
      onSuccess: () => {
        if (typeof window !== 'undefined') {
          window.location.reload();
        }
      },
    },
  });

  if (!user?.data?.sessionUser) {
    return null;
  }

  const { firstName, lastName } = user.data.sessionUser;
  const fullName = [firstName, lastName].filter(Boolean).join(' ');
  const { logout, isLoading } = useLogout;

  if (!fullName) {
    return null;
  }

  return (
    <WidthWrapper>
      <Snackbar isOpen={open} onClose={() => setOpen(false)} />
      <GlobalHeader user={{ fullName }} auth={{ logout, isLoading }} showMinimalGlobalHeader />
    </WidthWrapper>
  );
};

export default MinimalGlobalHeader;
