import { gql } from '@apollo/client';
import { receiptCartFragment } from '../fragments/cart';
import deliveryDetailsFragment from '../fragments/deliveryDetails';

const receiptQuery = gql`
  query receipt($orderReference: String!) {
    receipt(orderReference: $orderReference) {
      orderReference
      cart {
        ...ReceiptCart
      }
      deliveryDetails {
        ...deliveryDetailsFragment
      }
    }
  }
  ${deliveryDetailsFragment}
  ${receiptCartFragment}
`;

export default receiptQuery;
