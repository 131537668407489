import { gql } from '@apollo/client';

const getFormById = gql`
  query getFormById($id: String) {
    formById(id: $id) {
      id
      fields {
        id
        label
        required
        description
      }
    }
  }
`;

export default getFormById;
