import { gql } from '@apollo/client';

const getPredictedModels = gql`
  query PredictedModels($modelName: String) {
    predictedModels(modelName: $modelName) {
      totalCount
      data {
        id
        itemVariantType
        itemId
        itemName
        productName
        productNameShort
        itemGroupId
        manufacturerId
        price
        currencyIsoCode
        currencySymbol
        exchangeRate
        pictureUrl
        lastModified
        capacity
      }
    }
  }
`;

export default getPredictedModels;
