import { Small } from '@3dk/3style';
import { BLOCKS } from '@contentful/rich-text-types';
import PropTypes from 'prop-types';
import convertRichText from '../../../../../utils/convertRichText';
import legalTextPropType from '../../../../../utils/propTypes/legalTextPropType';

const makeClasses = (props) => ({
  textContainer: {
    padding: '0.05px 8px', // PaddingTop and PaddingBottom disables collapsing margin
    marginBottom: '16px',
    textAlign: props.textAlignment?.toLowerCase(),
  },
});

const Text = ({ children }) => <Small css={(theme) => ({ color: theme.palette.SECONDARY_BLACK })}>{children}</Small>;

const legalTextRenderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <Text>{children}</Text>,
  },
};

const LegalText = ({ text, textAlignment, additionalText }) => {
  const classes = makeClasses({ textAlignment });
  const richText = text ? convertRichText(text, legalTextRenderOptions) : null;

  return (
    <div css={classes.textContainer}>
      {richText}
      {additionalText && <Text>{additionalText}</Text>}
    </div>
  );
};

Text.propTypes = {
  children: PropTypes.node,
};

Text.defaultProps = {
  children: null,
};

LegalText.propTypes = legalTextPropType;

LegalText.defaultProps = {
  text: undefined,
  additionalText: null,
  textAlignment: 'Left',
};

export default LegalText;
