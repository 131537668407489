import { Container, Poster, Link, Subhead, largeDeviceUp, Grid, Box, Body } from '@3dk/3style';
import fejl404hund from '../../../public/media/fejl404hund.png';
import Status from '../Status';

const classes = {
  root: {
    textAlign: 'center',
    height: '100vh',
    [largeDeviceUp]: {
      background: `url('${fejl404hund}')`,
      backgroundRepeat: 'no-repeat',
      textAlign: 'left',
    },
  },
  headline: {
    margin: '26px 0',
    [largeDeviceUp]: {
      fontSize: '93px',
      marginTop: '100px',
    },
  },
  subHead: { margin: '14px 0' },
  paragraph: {
    marginBottom: '28px',
  },
  contactParagraph: {
    marginBottom: '14px',
  },
  image: {
    width: '100%',
  },
};

const PageNotFound = () => (
  <Status code={404}>
    <Container css={classes.root}>
      <Grid container>
        <Grid xs={12} lg={5}>
          <Poster variant="h1" css={classes.headline}>
            Vovsa.
          </Poster>
          <Subhead css={classes.subHead} type="light">
            Siden du leder efter eksisterer ikke.
          </Subhead>
          <Subhead css={classes.paragraph} type="light">
            Vi beklager, at du ikke fandt, hvad du søgte.
            <br />
            Du er meget velkommen til at kontakte os, så vi kan hjælpe dig videre.
          </Subhead>
          <Subhead css={classes.contactParagraph} type="light">
            Ring til os på{' '}
            <Body type="bold" component={Link} href="tel:70313313">
              70 313 313{' '}
            </Body>
            eller send os en
            <br /> mail på{' '}
            <Body type="bold" component={Link} href="mailto:webshop@3.dk">
              webshop@3.dk.
            </Body>
          </Subhead>
          <Subhead type="light">Vi beklager ulejligheden.</Subhead>
        </Grid>
        <Box display={{ xs: 'block', lg: 'none' }}>
          <Grid xs={12}>
            <img css={classes.image} src={fejl404hund} alt="dog" />
          </Grid>
        </Box>
      </Grid>
    </Container>
  </Status>
);

export default PageNotFound;
