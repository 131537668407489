import { getRemainder } from 'luhn-js';

export const getLuhnRemainder = (value) => !!value && /^[0-9]{2,}$/.test(value) && getRemainder(value) === 0;

export const mapEditingProductToChosen = (edit) => ({
  id: edit.id,
  answers: edit.answers,
  imei: edit.imei || '',
  pictureUrl: edit.pictureUrl,
  price: edit.price,
  priceQuoteId: edit.priceQuoteId || '',
  productNameShort: edit.productNameShort,
});

export const mapPriceQuoteToCampaign = (priceQuote, campaignDevice) => {
  if (priceQuote?.potentialCampaign) {
    const { dealBonusInclVat, campaignId } = priceQuote.potentialCampaign;
    if (dealBonusInclVat) {
      return {
        campaignId,
        campaignHeading: campaignDevice.campaignName,
        campaignDeviceId: campaignDevice.campaignId,
        discount: dealBonusInclVat,
      };
    }
  }
  return null;
};

export const getBestCampaignDiscount = (campaignDiscounts) => {
  if (campaignDiscounts?.length === 0) {
    return undefined;
  }
  return campaignDiscounts.reduce((acc, current) => (current.discount > acc.discount ? current : acc), { discount: 0 });
};
