import { useEffect } from 'react';
import Loading from '../../components/Loading';
import useCart from '../../utils/hooks/useCartHook';
import useLegacyCart from '../../utils/hooks/useLegacyCartHook';

const AbandonCheckout = () => {
  const { deleteCart } = useCart();
  const { deleteLegacyCart } = useLegacyCart();

  useEffect(() => {
    Promise.allSettled([deleteCart(), deleteLegacyCart()]).then(() => {
      window.location.replace('/');
    });
  }, [deleteCart, deleteLegacyCart]);

  return <Loading colored />;
};

export default AbandonCheckout;
