import { Headline } from '@3dk/3style';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useEffect } from 'react';
import preDcCheckoutQuery from '../../apollo/queries/preDcCheckout';
import Loading from '../../components/Loading';
import WidthWrapper from '../../components/WidthWrapper';
import ApolloErrorHandler from '../../components/errorHandling/ApolloErrorHandler';
import convertRichText from '../../utils/convertRichText';
import useCart from '../../utils/hooks/useCartHook';
import useLegacyCart from '../../utils/hooks/useLegacyCartHook';
import renderOptions from '../../utils/richTextRenderOptions/defaultRenderOptions';

const classes = {
  container: {
    marginTop: '40px',
  },
};

const CreditCheckRejection = () => {
  const { data, loading, error } = useQuery(preDcCheckoutQuery);

  const { deleteCart } = useCart();
  const { deleteLegacyCart } = useLegacyCart();

  useEffect(() => {
    deleteCart();
    deleteLegacyCart();
  }, []);

  if (loading) return <Loading />;

  if (error) return <ApolloErrorHandler error={error} />;
  const { creditCheckRejectedText } = data.preDcCheckout;

  return (
    <WidthWrapper>
      <div css={classes.container}>
        <Headline>{t('Checkout.Credit_Check_Step.Rejected_Header')}</Headline>
        {convertRichText(creditCheckRejectedText, renderOptions)}
      </div>
    </WidthWrapper>
  );
};

export default CreditCheckRejection;
