import { gql } from '@apollo/client';

const pageHeaderFragment = gql`
  fragment PageHeaderFragment on PageHeader {
    id
    type
    header
    backgroundImage {
      url
    }
    backgroundColor
    fontColor
    uspItems {
      __typename
      ... on ContentfulUSPs {
        uspText
        icon
      }
      ... on IconBlock {
        text
        icon
      }
    }
    uspColor
    uspSize
    useNarrowPadding
  }
`;

export default pageHeaderFragment;
