import { gql } from '@apollo/client';
import pageHeaderFragment from 'apollo/fragments/pageHeader';
import faqFragment from 'apollo/fragments/faq';
import blockFragment from '../fragments/block';
import seoFieldsFragment from '../fragments/seoFields';
import gridContainerFragment from '../fragments/gridContainer';
import carouselFragment from '../fragments/carousel';
import rowContainerFragment from '../fragments/rowContainer';
import navigationBarFragment from '../fragments/navigationBar';

const getPageQuery = gql`
  query getPage($slug: String!, $pageType: String) {
    page(slug: $slug, pageType: $pageType) {
      id
      type
      pageTitle
      blocks {
        __typename
        type
        id
        ...GridContainerFragment
        ...RowContainerFragment
        ...BlockFragment
        ...CarouselFragment
        ...NavigationBarFragment
      }
      ...SeoFieldsFragment
    }
  }
  ${seoFieldsFragment}
  ${blockFragment}
  ${carouselFragment}
  ${gridContainerFragment}
  ${rowContainerFragment}
  ${navigationBarFragment}
`;
const getAgentPageQuery = gql`
  query getPage($slug: String!, $pageType: String!) {
    page(slug: $slug, pageType: $pageType) {
      id
      type
      blocks {
        __typename
        id
        type
        ...PageHeaderFragment
        ...FaqFragment
        ... on ProductList {
          title
          filterHeadline
          appliedFilters {
            categories {
              filterKey
              filterOptions
            }
            secondaryCategories {
              filterKey
              order
              headline
              filterOptions
            }
            brands {
              filterKey
              order
              headline
              filterOptions
            }
            colours {
              filterKey
              order
              headline
              filterOptions
            }
            compatibleHardware {
              filterKey
              order
              headline
              filterOptions
            }
          }
        }
      }
      ...SeoFieldsFragment
    }
  }
  ${pageHeaderFragment}
  ${faqFragment}
  ${seoFieldsFragment}
`;

export { getPageQuery, getAgentPageQuery };
