import { gql } from '@apollo/client';
import linkFragment from './link';

const simpleImageFragment = gql`
  fragment SimpleImageFragment on SimpleImage {
    id
    title
    image {
      url
      width
    }
    alignment
    link {
      ...LinkFragment
    }
  }
  ${linkFragment}
`;

export default simpleImageFragment;
