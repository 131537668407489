import { SubscriptionCard } from '@3dk/3style';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import PageHelmet from '../../../app/routes/PageHelmet';
import ProductPage from '../../ProductPage/ProductPage';
import SubscriptionPageConfigurator from './SubscriptionPageConfigurator';
import SubscriptionProvider from './SubscriptionProvider';
import { CHILDREN_TYPE, CONTEXT_TYPE, IMAGE_TYPE, SUBSCRIPTION_CARD_PROPS_TYPE } from './utils/types';
import runtimeConfig from '../../../utils/config';
import { getColorFromName } from '../../../app/routes/pageContainer/page/Entry/Colors/Colors';
import FreeTextSplash from '../../FreeTextSplash';
import useAccountLevelDiscountPreview from '../../../utils/useAccountLevelDiscountPreview';

const { BASE_URI } = runtimeConfig;

const SubscriptionPage = ({ children, context, subscriptionPage, showAld }) => {
  const {
    title,
    metaTitle,
    metaDescription,
    openGraphTitle,
    openGraphType,
    openGraphDescription,
    openGraphImage,
    hideFromSearchEngines,
    canonicalUrl,
    backgroundImage,
    backgroundImageLargeDevices,
    cardProps,
    subscriptionId,
    variants,
    backgroundColor,
    disableAldPreviewForProduct,
  } = subscriptionPage;

  const background = {
    small: backgroundImage?.url,
    large: backgroundImageLargeDevices?.url,
  };
  const { pathname } = useLocation();

  const aldIsEnabled = showAld && !disableAldPreviewForProduct;
  const accountLevelDiscountPreview = useAccountLevelDiscountPreview({ items: [subscriptionId] }, aldIsEnabled);
  const { isBetterDiscount } = accountLevelDiscountPreview;

  const currentVariant = variants?.find((variant) => variant.id === subscriptionId) ?? variants?.[0];
  const images =
    currentVariant.images?.map((image) => ({
      alt: image.description ?? image.title,
      src: image.url,
      title: image.title,
    })) ?? [];

  if (images.length > 0) {
    images[0].ExtraComponent = <SubscriptionCard {...cardProps} />;
  }

  return (
    <>
      <PageHelmet
        pageTitle={title}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        openGraphTitle={openGraphTitle}
        openGraphType={openGraphType || 'website'}
        openGraphDescription={openGraphDescription}
        openGraphImage={openGraphImage}
        hideFromSearchEngines={hideFromSearchEngines}
        canonicalUrl={canonicalUrl || `${BASE_URI}${pathname}`}
      />
      <SubscriptionProvider context={context}>
        <ProductPage
          background={background}
          backgroundColor={getColorFromName(backgroundColor)}
          images={images}
          Configurator={
            <SubscriptionPageConfigurator
              subscriptionPage={subscriptionPage}
              currentVariant={currentVariant}
              showAld={aldIsEnabled}
              accountLevelDiscountPreview={accountLevelDiscountPreview}
              isBetterAldDiscount={isBetterDiscount}
            />
          }
          SubscriptionCard={
            images.length > 0 ? null : (
              <SubscriptionCard
                {...cardProps}
                splash={
                  isBetterDiscount ? (
                    <FreeTextSplash
                      line1={t('Product_Page.Account_Level_Discount.Splash_Line_1')}
                      line2={t('Product_Page.Account_Level_Discount.Splash_Line_2')}
                      line3={t('Product_Page.Account_Level_Discount.Splash_Line_3')}
                      backgroundColour="Black"
                    />
                  ) : (
                    cardProps.splash
                  )
                }
              />
            )
          }
        >
          {children}
        </ProductPage>
      </SubscriptionProvider>
    </>
  );
};

SubscriptionPage.propTypes = {
  children: CHILDREN_TYPE,
  context: CONTEXT_TYPE.isRequired,
  subscriptionPage: PropTypes.shape({
    backgroundImage: IMAGE_TYPE,
    backgroundImageLargeDevices: IMAGE_TYPE,
    backgroundColor: PropTypes.string,
    cardProps: SUBSCRIPTION_CARD_PROPS_TYPE,
    subscriptionId: PropTypes.string,
    title: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    openGraphTitle: PropTypes.string,
    openGraphType: PropTypes.string,
    openGraphDescription: PropTypes.string,
    openGraphImage: PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    hideFromSearchEngines: PropTypes.bool,
    canonicalUrl: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    disableAldPreviewForProduct: PropTypes.bool,
  }).isRequired,
  showAld: PropTypes.bool,
};

SubscriptionPage.defaultProps = {
  children: null,
  showAld: true,
};

export default SubscriptionPage;
