import { gql } from '@apollo/client';

const legacyDevicePagePriceFragment = gql`
  fragment DevicePage_Price on Legacy_DevicePagePrice {
    basketDiscountedRecurringPrice
    basketDiscountedUpfrontPrice
    basketMinimumPrice
    deviceBindingPeriod
    deviceDiscountedRecurringPrice
    deviceDiscountedUpfrontPrice
    deviceTotalDiscountedRecurringPrice
    deviceTotalDiscountedPrice
    deviceUpfrontDiscount
    subscriptionBindingPeriod
    subscriptionDiscountedRecurringPrice
    subscriptionDiscountedUpfrontPrice
    subscriptionTotalDiscountedRecurringPrice
    subscriptionDiscountedPeriod
    subscriptionNonDiscountedPeriod
    subscriptionRegularRecurringPrice
  }
`;

export default legacyDevicePagePriceFragment;
