import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CartLine, QuestionGroup } from '@3dk/3style';
import { t } from 'i18next';
import { deviceQuestions, SmartWatchItemGroupId, watchQuestions } from './consts';
import { ProductType } from './TradeInPropTypes';

const styles = {
  root: {
    margin: 'auto 0',
  },
};

const setParentQuestion = (question, tre, questionIndex) => [
  {
    ...question,
    possibleAnswers: [{ ...question.possibleAnswers[0], followupQuestions: tre }, { ...question.possibleAnswers[1] }],
    icon: questionIndex,
    isRequired: true,
    viewPath: true,
  },
];

const TradeInQuestions = (props) => {
  const { product, confirmQuestions, removeProduct } = props;

  const [result, setResult] = useState({});

  const questions = useMemo(() => {
    const q = product?.itemGroupId === SmartWatchItemGroupId ? watchQuestions : deviceQuestions;
    return {
      questions: q,
      questionMap: [...q]
        .reverse()
        .reduce((res, question, index) => setParentQuestion(question, res, q.length - index), null),
    };
  }, [product]);

  const submitAnswers = () => {
    confirmQuestions(result.isValid ? Object.values(result.answers).map((val) => Number(val.answerIndex)) : []);
  };

  return (
    <div>
      {product && (
        <CartLine
          image={
            product.pictureUrl
              ? {
                  url: product.pictureUrl,
                  alt: product.productNameShort,
                  title: product.productNameShort,
                }
              : undefined
          }
          productTitle={product.productNameShort}
          removeFromCart={() => removeProduct()}
          removePrice
        />
      )}

      <div css={styles.root}>
        <QuestionGroup
          questions={questions.questionMap}
          buttonLabel={t('Trade_In.General.Accept_Questionnaire_Label')}
          onResultChange={(answers) => setResult(answers || {})}
          clearTreeOnChange
          buttonProps={{ onClick: () => submitAnswers() }}
        />
      </div>
    </div>
  );
};

TradeInQuestions.propTypes = {
  confirmQuestions: PropTypes.func,
  removeProduct: PropTypes.func,
  product: ProductType,
};

TradeInQuestions.defaultProps = {
  confirmQuestions: () => null,
  removeProduct: () => null,
  product: null,
};

export default TradeInQuestions;
