// Do not change this text. The Monolith uses this text to make GMC feeds to all pre-dc products.
export default function formatExpectedDelivery(delivery) {
  const { dateInterval, deliveryDate } = delivery ?? {};
  if (deliveryDate) {
    const formattedDeliveryDate = new Date(deliveryDate).toLocaleDateString('da-DK', { day: 'numeric', month: 'long' });
    if (formattedDeliveryDate.toString() !== 'Invalid Date') {
      return `Forventet levering: ${formattedDeliveryDate}`;
    }
    return `Forventet levering: ${deliveryDate}`;
  }

  if (dateInterval) {
    return `På lager - Forventet levering: ${dateInterval.from}-${dateInterval.to} dage`;
  }

  return 'Forventet levering: Ukendt';
}
