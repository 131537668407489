import {
  PRIMARY_ORANGE,
  SECONDARY_GREEN,
  SECONDARY_ORANGE,
  TYPOGRAPHY_BLACK,
  TYPOGRAPHY_WHITE,
  PRIMARY_LIGHT_GREY,
  SECONDARY_BLUE,
  PRIMARY_DARK_GREEN,
  PRIMARY_BLUE,
  PRIMARY_GREEN,
  PRIMARY_WHITE,
  PRIMARY_SAND,
  PRIMARY_DARK_NAVY,
  PRIMARY_DARK_GREY,
  SECONDARY_DARK_GREY,
  PRIMARY_GOLD,
  PRIMARY_WARM_GREY,
} from '@3dk/3style';

export const backgroundColors = {
  BLACK: TYPOGRAPHY_BLACK,
  DARK_GREY: PRIMARY_DARK_GREY,
  GREY: SECONDARY_DARK_GREY,
  LIGHT_GREY: PRIMARY_LIGHT_GREY,
  WHITE: TYPOGRAPHY_WHITE,
  LIGHT_ORANGE: SECONDARY_ORANGE,
  ORANGE: PRIMARY_ORANGE,
  LIGHT_BLUE: SECONDARY_BLUE,
  LIGHT_GREEN: SECONDARY_GREEN,
  GREEN: PRIMARY_DARK_GREEN,
  GOLD: PRIMARY_GOLD,
  DARK_BLUE: PRIMARY_DARK_NAVY,
  WARM_GREY: PRIMARY_WARM_GREY,
  SAND: PRIMARY_SAND,
  NONE: 'none',
};

const splashColors = {
  RED: '#b1230d',
  ORANGE: PRIMARY_ORANGE,
  BLUE: PRIMARY_BLUE,
  BLACK: TYPOGRAPHY_BLACK,
  GREEN: PRIMARY_GREEN,
  YELLOW: '#f7c305',
  WHITE: PRIMARY_WHITE,
};

export const fontColors = {
  BLACK: TYPOGRAPHY_BLACK,
  WHITE: TYPOGRAPHY_WHITE,
};

export const contentfulTextColors = {
  orange: PRIMARY_ORANGE,
  gold: PRIMARY_GOLD,
  blue: PRIMARY_DARK_NAVY,
};
const formatName = (name) => name?.toUpperCase().replaceAll(' ', '_');

export const getFontColorFromName = (name) => fontColors[formatName(name)];

export const getColorFromName = (name) => backgroundColors[formatName(name)];

export const getSplashColorFromName = (name) => splashColors[formatName(name)];

export const getFontColorFromBackgroundColor = (backgroundColor) => {
  switch (formatName(backgroundColor)) {
    case 'BLACK':
    case 'DARK_GREY':
    case 'ORANGE':
      return fontColors.WHITE;
    default:
      return fontColors.BLACK;
  }
};

export const getFontColorFromBackgroundHEXColor = (backgroundColor) => {
  switch (backgroundColor) {
    case PRIMARY_ORANGE:
    case TYPOGRAPHY_BLACK:
    case PRIMARY_DARK_GREY:
    case PRIMARY_DARK_NAVY:
    case PRIMARY_WARM_GREY:
      return fontColors.WHITE;
    default:
      return fontColors.BLACK;
  }
};

export const backgroundColorsToPropType = Object.keys(backgroundColors).map((key) =>
  key.toLowerCase().replaceAll('_', ' '),
);
