import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { triggerTradeInPanel } from '../customEvents/TradeInEvent';

const useQueryAction = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const parameters = new URLSearchParams(location.search);

    const shouldOpen = parameters.get('tradeIn');
    const saveToBasket = (parameters.get('pageVariant') || '').toLowerCase() === 'true';
    if (shouldOpen) {
      triggerTradeInPanel({
        isOpen: shouldOpen.toLowerCase() === 'true',
        saveToBasket,
        device: null,
        paymentPeriod: 10,
      });
      parameters.delete('tradeIn');
      parameters.delete('pageVariant');
      history.replace({ search: parameters.toString() });
    }
  }, [location, history]);
};

export default useQueryAction;
