import { gql } from '@apollo/client';

const getCheckoutPageQuery = gql`
  query getCheckoutPage {
    checkoutPage {
      stickyBar {
        backgroundColor
        mobileContent
        desktopContent
        textColor
        linkColor
        showLinkAsButton
      }
      creditCheckStep {
        sidePanelContent
        sidePanelContactInformationBlock {
          headline
          callText
          phonenumber
          emailText
          email
          openingHoursHeadline
          openingHoursWeekdaysText
          openingHoursWeekdaysValue
          openingHoursFridayText
          openingHoursFridayValue
          openingHoursNotWeekdaysText
          openingHoursNotWeekdaysValue
        }
        declinedHeadline
        declinedContent
        declinedBackgroundColor
        declinedLinkText
      }
      nemIdMismatchStep {
        infoPanelHeadline
        infoPanelContent
        alternativeLoginText
      }
      confirmStep {
        contractTerms {
          url
        }
        termsOfSaleAndDelivery {
          url
        }
        portInInformationText
      }
      paymentStep {
        errorBoxHeadline
        errorBoxContent
        errorBoxBackgroundColor
        errorBoxFontColor
      }
      metaTitle
    }
  }
`;

export default getCheckoutPageQuery;
