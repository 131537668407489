const menuConfig = {
  placeholderCart: {
    device: {
      id: null,
      gtmId: null,
      name: null,
      type: null,
      size: null,
      color: null,
      colorCode: null,
      url: null,
      brand: null,
      subHeading: null,
      images: [],
      price: {
        bindingPeriod: null,
        discountedRecurringPrice: null,
        discountedUpfrontPrice: null,
        totalRecurringDiscount: null,
        upfrontDiscount: null,
        totalDiscount: null,
        totalRegularPrice: null,
        totalDiscountedPrice: null,
        regularUpfrontPrice: null,
        regularRecurringPrice: null,
        totalDiscountedRecurringPrice: null,
        totalRegularRecurringPrice: null,
        recurringDiscount: null,
      },
      hideStockAvailability: false,
      contentImage: null,
      deviceIcons: [
        {
          Title: null,
          SubTitle: null,
          Image: null,
        },
        {
          Title: null,
          SubTitle: null,
          Image: null,
        },
        {
          Title: null,
          SubTitle: null,
          Image: null,
        },
        {
          Title: null,
          SubTitle: null,
          Image: null,
        },
      ],
      introductionText: null,
      introductionTextLabel: null,
    },
    subscription: {
      id: null,
      name: null,
      displayName: null,
      subscriptionType: null,
      price: {
        bindingPeriod: null,
        discountedRecurringPrice: null,
        discountedUpfrontPrice: null,
        totalRecurringDiscount: null,
        upfrontDiscount: null,
        totalDiscount: null,
        totalRegularPrice: null,
        totalDiscountedPrice: null,
        regularUpfrontPrice: null,
        regularRecurringPrice: null,
        totalDiscountedRecurringPrice: null,
        totalRegularRecurringPrice: null,
        recurringDiscount: null,
      },
      options: {
        MsisdnExpiredDate: null,
        subscriptionSaleType: null,
        searchEngineVisibilityType: null,
        directoryServiceType: null,
        isProlonging: null,
      },
      type: null,
      description: null,
      dataLimit: null,
      dataLimitFree: null,
      talkTimeFree: null,
      campaignLogos: [
        {
          Url: null,
          Text: null,
          Link: null,
        },
        {
          Url: null,
          Text: null,
          Link: null,
        },
        {
          Url: null,
          Text: null,
          Link: null,
        },
      ],
      customertype: null,
      attributes: {
        'Abonnement opkræves': null,
        'Inkluderet taletid': null,
        'Inkluderet data': null,
        '4G LTE': null,
        '4G tale': null,
        'WiFi Opkald': null,
        '3LikeHome': null,
        '3LikeHome forbrugsgrænse': null,
        'Sms pr. stk.': null,
        'Pris pr. inkluderet MB': null,
        Opsigelsesvarsel: null,
      },
      bindingPeriodMonths: null,
      offersClarificationText: null,
      smsAndMmsFree: null,
    },
    addons: [],
    accessories: [],
    simcard: null,
    freight: {
      psid: null,
      price: null,
      deliveryMethod: null,
      deliveryMethodDescription: null,
    },
    merchant: null,
    vouchers: [],
    totalUpfrontPrice: null,
    minimumPrice: null,
    monthlyInstallmentPeriod: null,
    bindingPeriod: null,
    initialCheckoutStep: null,
    currentCheckoutStep: null,
    currentCheckoutStepGroup: null,
    customerInfo: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      street: null,
      postalCode: null,
      city: null,
      email: null,
      needsToPayAccessoriesUpfront: null,
    },
    price: {
      bindingPeriod: null,
      discountedRecurringPrice: null,
      discountedUpfrontPrice: null,
      totalRecurringDiscount: null,
      upfrontDiscount: null,
      totalDiscount: null,
      totalRegularPrice: null,
      totalDiscountedPrice: null,
      regularUpfrontPrice: null,
      regularRecurringPrice: null,
      totalDiscountedRecurringPrice: null,
      totalRegularRecurringPrice: null,
      recurringDiscount: null,
    },
    nextCheckoutStep: null,
    count: 0,
    upfrontPaymentMethod: null,
    recurringPaymentMethod: null,
    url: null,
    accessoriesTotalPrice: null,
    accessoriesTotalDiscount: null,
    hasInsurance: null,
    eSimSelected: null,
  },
};

export default menuConfig;
