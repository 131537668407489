import { gql } from '@apollo/client';

const seoFieldsFragment = gql`
  fragment SeoFieldsFragment on SeoFields {
    metaTitle
    metaDescription
    openGraphTitle
    openGraphType
    openGraphDescription
    openGraphImage {
      url
      description
      width
      height
    }
    hideFromSearchEngines
    canonicalUrl
  }
`;

export default seoFieldsFragment;
