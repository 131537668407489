import { useEffect } from 'react';
import { Container } from '@3dk/3style';

const NewsPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://via.ritzau.dk/embedded/prs_embedded.js';
    script.type = 'text/javascript';
    script.id = 'newsViaRitzau';
    document.body.appendChild(script);
  }, []);

  return (
    <Container disableGutters>
      <div id="embedded-pressroom" data-publisher="13561616" />
    </Container>
  );
};
export default NewsPage;
