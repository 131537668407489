import { Box } from '@3dk/3style';
import PropTypes from 'prop-types';
import Flex from '../Flex/Flex';
import Hero from './Hero';
import ImageCarousel from './ImageCarousel';
import SupportContactInfo from './SupportContactInfo';
import UspRibbon from './UspRibbon';
import { BreadCrumbs, BreadCrumbsType } from '../BreadCrumbs';

const classes = {
  imageSection: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carouselAndUsp: (theme) => ({
    position: 'relative', // Required because the splash is 'position: absolute'.
    // TODO(NOV-2539): this value depends on values in Hero.jsx.
    // It is not an obvious dependency and should be refactored.
    height: '400px',
    [theme.breakpoints.up('lg')]: {
      // TODO(NOV-2539): this value depends on classes.container.paddingTop in Hero.jsx.
      // It is not an obvious dependency and should be refactored.
      height: '550px',
    },
  }),
  childrenContainer: {
    marginBottom: '50px',
  },
  contactInfo: {
    marginTop: '50px',
  },
  hero: (theme) => ({
    marginBottom: '20px',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '30px',
    },
  }),
  heroLeftSide: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      width: '550px',
    },
  }),
  splash: (theme) => ({
    position: 'absolute',
    right: 0,
    top: '20px',
    zIndex: 10,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      top: '40px',
    },
  }),
};

const ProductPage = (props) => {
  const {
    background,
    backgroundColor,
    children,
    images,
    Configurator,
    Highlight,
    Splash,
    SubscriptionCard,
    breadCrumbs,
  } = props;

  return (
    <div>
      <UspRibbon />
      <Hero css={classes.hero} background={background} backgroundColor={backgroundColor}>
        <Flex css={classes.heroLeftSide} flexDirection="column" justifyContent="space-between">
          <Flex css={classes.carouselAndUsp} flexDirection="column">
            <div css={classes.splash}>{Splash}</div>
            {breadCrumbs.length > 0 && <BreadCrumbs breadCrumbs={breadCrumbs} />}
            {images?.length > 0 && <ImageCarousel css={classes.imageSection} images={images} />}
            {SubscriptionCard && <div css={classes.imageSection}>{SubscriptionCard}</div>}
          </Flex>
          <Box display={{ xs: 'none', lg: 'flex' }} flexGrow={1}>
            {Highlight}
          </Box>
        </Flex>
        {Configurator}
        <Box display={{ xs: 'block', lg: 'none' }}>{Highlight}</Box>
      </Hero>
      <div css={classes.childrenContainer}>
        {children}
        <SupportContactInfo css={classes.contactInfo} />
      </div>
    </div>
  );
};

ProductPage.propTypes = {
  background: Hero.propTypes.background,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  images: ImageCarousel.propTypes.images,
  Configurator: PropTypes.node,
  Highlight: PropTypes.node,
  Splash: PropTypes.node,
  SubscriptionCard: PropTypes.node,
  breadCrumbs: BreadCrumbsType,
};

ProductPage.defaultProps = {
  background: null,
  backgroundColor: null,
  children: null,
  images: null,
  Configurator: null,
  Highlight: null,
  Splash: null,
  SubscriptionCard: null,
  breadCrumbs: [],
};

export default ProductPage;
