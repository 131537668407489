import { gql } from '@apollo/client';

const storeListFragment = gql`
  fragment StoreListFragment on StoreList {
    header
    subheader
    searchPlaceholder
  }
`;

export default storeListFragment;
