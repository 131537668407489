import { Button, PencilIcon, FileUploadButton, RadioGroup, FormControlLabel, RadioButton } from '@3dk/3style';
import * as Yup from 'yup';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import Loading from 'components/Loading';
import { useState } from 'react';
import GoogleReCaptcha from 'utils/googleReCaptcha/googleReCaptchaV3';
import useGoogleReCaptcha from 'utils/hooks/useGoogleReCaptcha';
import { useQuery, useMutation } from '@apollo/client';
import getFormById from 'apollo/queries/getFormById';
import submitFormstackFormMutation from 'apollo/mutations/submitFormstackForm';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import FormikInputField from 'components/formikFields/FormikInputField';
import convertFileToBase64 from './util/convertFileToBase64';

const classes = {
  form: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr ',
      columnGap: '10px',
    },
  }),
  message: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      gridColumnStart: '1',
      gridColumEnd: '1',
    },
  }),
  submitButton: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  radioButton: (theme) => ({ color: theme.palette.SECONDARY_BLACK }),
};

const generateValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    cpr: Yup.string(),
    // TODO: update this validation to the new Email Validation scheme OP-2933
    email: Yup.string().email().required(),
    phoneNumber: Yup.string()
      .matches(/^(\+45|0045)?[0-9]{8}$/)
      .required(),
    subject: Yup.string().required(),
    message: Yup.string().required(),
  });

const ALLOWED_FILE_TYPES = '.doc, .docx, .odt, .pages, .rtf, .txt, .wpd, .pdf, .bmp, .gif, .jpg, .jpeg, .png';

const MAX_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024; // 10 MB
const FORMSTACK_FILE_FIELD_NAME = 'file';
const RECAPTCHA_ACTIONS = { SUBMIT: 'SUBMIT' };

const inputFieldIcon = <PencilIcon />;

const HelpForm = ({ formstackFormId, isBusiness }) => {
  const { verifyUserToken } = useGoogleReCaptcha();
  const [inputFile, setInputFile] = useState(null);
  const [selectedBusinessConsumer, setSelectedBusinessConsumer] = useState(isBusiness ? 'Business' : 'Consumer');
  const [reCaptchaIsLoaded, setReCaptchaIsLoaded] = useState(false);

  const { data, loading, error } = useQuery(getFormById, { variables: { id: formstackFormId } });

  const [submitForm, { mutationLoading, mutationError }] = useMutation(submitFormstackFormMutation);
  if (loading || mutationLoading) return <Loading />;
  if (error || mutationError) return <ApolloErrorHandler error={error} />;

  const formFields = data.formById.fields;

  const validateAndSubmitForm = async (userInput) => {
    let convertedFile = '';
    // files should be base64 encoded before submission
    if (inputFile) {
      const encodedFile = await convertFileToBase64(inputFile);
      convertedFile = `${inputFile.name};${encodedFile}`;
    }
    const submissionData = { ...userInput, business_consumer: selectedBusinessConsumer };

    submitForm({
      variables: {
        submission: {
          id: formstackFormId,
          fields: formFields.map((field) => ({
            id: field.id,
            userData:
              field.description !== FORMSTACK_FILE_FIELD_NAME ? submissionData[field.description] : convertedFile,
          })),
        },
      },
    });
  };

  const handleSubmit = (userInput) => {
    verifyUserToken(() => validateAndSubmitForm(userInput), RECAPTCHA_ACTIONS.SUBMIT);
  };

  const handleFileUpload = (event) => {
    const uploadFile = event.target.files[0];

    if (uploadFile && uploadFile.size <= MAX_FILE_SIZE_IN_BYTES) {
      setInputFile(uploadFile);
    }
  };

  const removeFile = () => {
    setInputFile(null);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: '',
        cpr: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
      }}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.resetForm();
        removeFile();
      }}
      validationSchema={generateValidationSchema}
      validateOnChange
      validateOnMount={false}
    >
      <Form>
        <div css={classes.form}>
          <RadioGroup onChange={(value) => setSelectedBusinessConsumer(value)} row css={classes.radioButton}>
            <FormControlLabel
              label="Privat"
              value="Consumer"
              control={<RadioButton />}
              checked={selectedBusinessConsumer === 'Consumer'}
            />
            <FormControlLabel
              label="Erhverv"
              value="Business"
              control={<RadioButton />}
              checked={selectedBusinessConsumer === 'Business'}
            />
          </RadioGroup>
          <div css={classes.message}>
            <FormikInputField fullWidth type="text" name="name" label="Navn" iconPosition="end" icon={inputFieldIcon} />
            <FormikInputField
              fullWidth
              type="text"
              name="cpr"
              label="CPR- eller kundenummer"
              iconPosition="end"
              icon={inputFieldIcon}
              errorText="Skriv venligst dit CPR-nummer"
            />
            <FormikInputField
              fullWidth
              type="text"
              name="phoneNumber"
              iconPosition="end"
              label="Telefonnummer"
              icon={inputFieldIcon}
              errorText="Skriv venligst dit telefonnummer"
            />
            <FormikInputField
              fullWidth
              type="email"
              name="email"
              iconPosition="end"
              label="Email"
              icon={inputFieldIcon}
              errorText="Skriv venligst din email"
            />
          </div>
          <div>
            <FormikInputField
              fullWidth
              type="text"
              name="subject"
              iconPosition="end"
              label="Emne"
              icon={inputFieldIcon}
            />
            <FormikInputField
              css={{ paddingBottom: '14px' }}
              rows={3}
              fullWidth
              multiline
              type="text"
              name="message"
              iconPosition="end"
              label="Skriv venligst din besked"
              icon={inputFieldIcon}
            />
            <FileUploadButton
              allowedFileTypes={ALLOWED_FILE_TYPES}
              selectedFiles={[inputFile]}
              uploadFile={handleFileUpload}
              removeFile={removeFile}
            />
          </div>
        </div>
        <div css={classes.submitButton}>
          <GoogleReCaptcha setReCaptchaLoaded={setReCaptchaIsLoaded} />
          <Button type="submit" disabled={!reCaptchaIsLoaded}>
            Submit
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

HelpForm.propTypes = {
  formstackFormId: PropTypes.string.isRequired,
  isBusiness: PropTypes.bool.isRequired,
};

export default HelpForm;
