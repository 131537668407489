import { useQuery } from '@apollo/client';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import PropTypes from 'prop-types';
import PAGE_TYPE from 'constants/pageTypes';
import Loading from '../../components/Loading';
import getExternalPage from '../../apollo/queries/getExternalPage';
import NewsPage from './externalPage/NewsPage';
import PageHelmet from './PageHelmet';
import GuidePage from './externalPage/GuidePage';
import PageEntries from './pageContainer/page/PageEntries';
import ContentfulProvider from './pageContainer/ContentfulContext';
import CookieInformationPage from './externalPage/CookieInformationPage';

const ExternalPage = (props) => {
  const { slug } = props;
  const queryOptions = {
    variables: { slug },
  };

  const { loading, error, data } = useQuery(getExternalPage, queryOptions);

  if (loading) return <Loading colored />;
  if (error) return <ApolloErrorHandler error={error} />;

  const { externalPageType, topBlocks, bottomBlocks, ...rest } = data?.externalPage || {};

  let externalContent;
  switch (externalPageType) {
    case 'News':
      externalContent = <NewsPage />;
      break;
    case 'Guide':
      externalContent = <GuidePage />;
      break;
    case 'Cookie Information':
      externalContent = <CookieInformationPage />;
      break;
    default:
      break;
  }

  return (
    <ContentfulProvider data={data?.externalPage} contentfulPageType={PAGE_TYPE.EXTERNAL_PAGE}>
      <PageHelmet {...rest} baseUrl={slug} />
      <PageEntries blocks={topBlocks} />
      {externalContent}
      <PageEntries blocks={bottomBlocks} isBelowContent />
    </ContentfulProvider>
  );
};

ExternalPage.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default ExternalPage;
