import { LoadingDots, Fade } from '@3dk/3style';

const classes = {
  centerLoading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const Loading = ({ ...props }) => (
  <div css={classes.centerLoading}>
    <Fade in>
      <LoadingDots size="large" {...props} />
    </Fade>
  </div>
);

export default Loading;
