import { gql } from '@apollo/client';

const getAllPriceQuotesQuery = gql`
  query PriceQuotes($questionnaireAnswers: [QuestionnaireAnswers]) {
    priceQuotes(questionnaireAnswers: $questionnaireAnswers) {
      identifierSuitable
      priceQuoteId
      grade
      offeredPrice
      offeredPriceInclVat
      offeredPriceMVAT
      currencyIsoCode
      currencySymbol
      exchangeRate
      potentialCampaign {
        campaignId
        dealBonusInclVat
      }
    }
  }
`;

export default getAllPriceQuotesQuery;
