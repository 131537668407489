import { gql } from '@apollo/client';

const updateCustomerConsentsMutation = gql`
  mutation updateCustomerConsentsV2(
    $consentList: [ConsentListV2!]!
    $caller: String
    $customerType: String
    $cvr: String
  ) {
    updateCustomerConsentsV2(consentList: $consentList, caller: $caller, customerType: $customerType, cvr: $cvr) {
      data {
        attributes {
          consentUpdated
        }
      }
    }
  }
`;

export default updateCustomerConsentsMutation;
