import { useEffect } from 'react';
import PropTypes from 'prop-types';
import runtimeConfig from 'utils/config';

const { GOOGLE_RECAPTCHA_SITE_KEY } = runtimeConfig;

const GoogleReCaptcha = ({ setReCaptchaLoaded }) => {
  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.addEventListener('load', setReCaptchaLoaded(true));
    document.body.appendChild(script);

    return () => script.removeEventListener('load', setReCaptchaLoaded(false));
  }, []);

  return <div className="g-recaptcha" data-sitekey={GOOGLE_RECAPTCHA_SITE_KEY} data-size="invisible" />;
};

GoogleReCaptcha.propTypes = {
  setReCaptchaLoaded: PropTypes.func,
};

GoogleReCaptcha.defaultProps = {
  setReCaptchaLoaded: () => null,
};

export default GoogleReCaptcha;
