import { gql } from '@apollo/client';

const legalTextFragment = gql`
  fragment LegalTextFragment on LegalText {
    text
    textAlignment
  }
`;

export default legalTextFragment;
