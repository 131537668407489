import PropTypes from 'prop-types';
import AddressCoverageCheck from '../../../../../components/addressCoverage/AddressCoverageCheck';

const classes = {
  addressCoverageContainer: (theme) => ({
    minHeight: '100px',
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // The following css is to prevent overwritten styles from contentTypes using the searchBar
    '& p': {
      color: theme.palette.PRIMARY_BLACK,
    },
  }),
};

const AddressCoverageEntry = ({ label, resultingProductPdbId }) => (
  <div css={classes.addressCoverageContainer}>
    <AddressCoverageCheck label={label} resultingProductPdbId={resultingProductPdbId} redirectToLandingPages />
  </div>
);

AddressCoverageEntry.propTypes = {
  label: PropTypes.string,
  resultingProductPdbId: PropTypes.string,
};

AddressCoverageEntry.defaultProps = {
  label: undefined,
  resultingProductPdbId: undefined,
};

export default AddressCoverageEntry;
