import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import getAddressById from '../../../../../apollo/queries/getAddressById';
import Loading from '../../../../../components/Loading';
import useQueryParams from '../../../../../utils/hooks/useQueryParams';
import checkCoverageQuery from '../../../../../apollo/queries/checkCoverage';
import trimSlugAndRemoveStartingSlash from '../../../../../utils/url/trimSlugAndRemoveStartingSlash';
import addTrailingSlash from '../../../../../utils/url/addTrailingSlash';
import { triggerDataIframeInjection } from '../../../../../utils/customEvents/IframeInjectDataEvent';
import getOneClickProducts from '../../../../../utils/getOneClickProducts';
import addProductToLegacyCartPreviewQuery from '../../../../../apollo/queries/addProductToLegacyCartPreview';
import dataLayer from '../../../../../utils/dataLayer/dataLayer';
import FwaView from './Fwa/FwaView';
import LINK_TYPES from '../../../../../constants/linkTypes';
import useAccountLevelDiscountPreview from '../../../../../utils/useAccountLevelDiscountPreview';
import AldInformationView from '../../../../../components/AldInformationView/AldInformationView';
import { LANDING_PAGE } from '../constants';

const classes = {
  aldLink: {
    marginTop: '25px',
  },
};

const FwaConsumer = (props) => {
  const { fwaProps } = props || {};
  const disableAldPredictions = fwaProps?.disableAldPredictions;
  const variables = useMemo(() => {
    const { oneClickDevice, oneClickSubscriptions, oneClickAccessories, oneClickVas, linkType } =
      fwaProps?.productLink || {};
    if (linkType === LINK_TYPES.ONE_CLICK) {
      const oneClickProducts = getOneClickProducts(
        oneClickDevice,
        oneClickSubscriptions,
        oneClickAccessories,
        oneClickVas,
      );
      const mainProduct = oneClickProducts.find((product) => product.isMainProduct);
      const secondaryProducts = (oneClickProducts || [])
        .filter((product) => !product.isMainProduct)
        .flatMap((product) => product.product);
      return {
        items: mainProduct?.product,
        bundledProducts: secondaryProducts,
        upfront: 0,
      };
    }
    return null;
  }, [fwaProps?.productLink]);

  useQuery(addProductToLegacyCartPreviewQuery, {
    variables,
    skip: !variables,
    ssr: false,
    onCompleted: (data) => dataLayer.oneClickBasketDetailsEvent(data?.addProductToLegacyCartPreview || []),
  });

  const mapAldAccountData = (accountLevelDiscountPreview) => {
    const { accountLevelDiscount, isBetterDiscount, subscriptionTotal } = accountLevelDiscountPreview || {};
    if (!accountLevelDiscount) {
      return null;
    }
    const { accountLevelDiscountPreview: newDiscount } = accountLevelDiscount;
    if (isBetterDiscount) {
      return {
        lines: [
          {
            header: t('FWA.ALD.Table_Product_Label'),
            price: `${subscriptionTotal} kr.`,
          },
          {
            header: t('FWA.ALD.Table_ALD_Label'),
            price: `-${Math.abs(newDiscount)} kr.`,
          },
          {
            header: t('FWA.ALD.Table_ALD_Result_Label'),
            price: `${subscriptionTotal - Math.abs(newDiscount)} kr.`,
          },
        ],
        disclaimer: t('FWA.ALD.Disclaimer'),
        splash: t('FWA.ALD.Splash'),
      };
    }
    return null;
  };

  const accountLevelDiscountPreview = useAccountLevelDiscountPreview(variables, !disableAldPredictions);
  const accountLevelDiscountData = mapAldAccountData(accountLevelDiscountPreview);

  return (
    <FwaView {...props} accountLevelDiscount={accountLevelDiscountData}>
      {!disableAldPredictions && (
        <AldInformationView
          css={classes.aldLink}
          accountLevelDiscountPreview={accountLevelDiscountPreview}
          useCase={LANDING_PAGE}
        />
      )}
    </FwaView>
  );
};

const FwaBusiness = (props) => {
  const { address, coverage, fwaProps } = props || {};
  useEffect(() => {
    const { formstackAddressTitle, formstackProductTitle, formstackBandwidthTitle, formstackTechnologyTitle } =
      fwaProps || {};
    const { displayName } = address || {};
    const { primaryProduct, maxSpeed, recommendedTechnology } = coverage || {};
    const values = [
      [formstackAddressTitle, displayName],
      [formstackProductTitle, primaryProduct],
      [formstackBandwidthTitle, maxSpeed],
      [formstackTechnologyTitle, recommendedTechnology],
    ].filter(([key, value]) => value && key);

    triggerDataIframeInjection(Object.fromEntries(values));
  }, [address, coverage, fwaProps]);

  return <FwaView {...props} />;
};

const FwaEntry = (props) => {
  const { validateLocation, isBusiness } = props;
  const { addressId, longitude, latitude } = useQueryParams('addressId', 'longitude', 'latitude');
  const history = useHistory();
  const { data: addressData, loading: addressLoading } = useQuery(getAddressById, {
    variables: { addressId },
    skip: !addressId,
  });
  const { data: coverageData, loading: coverageLoading } = useQuery(checkCoverageQuery, {
    variables: {
      location: {
        longitude,
        latitude,
      },
    },
  });

  useEffect(() => {
    const { checkCoverage } = coverageData || {};
    if (validateLocation) {
      if (checkCoverage) {
        const { landingPage } = checkCoverage;
        const currentLocation = trimSlugAndRemoveStartingSlash(history.location.pathname);
        const requiredLocation = trimSlugAndRemoveStartingSlash(landingPage);
        if (currentLocation !== requiredLocation) {
          history.push(addTrailingSlash(landingPage, history.location.search));
        }
      }
    }
  }, [history, validateLocation, coverageData]);

  if (addressLoading && coverageLoading) {
    return <Loading />;
  }

  if (!addressData?.getAddressById || !coverageData?.checkCoverage) {
    return null;
  }
  const Element = isBusiness ? FwaBusiness : FwaConsumer;
  return <Element fwaProps={props} address={addressData.getAddressById} coverage={coverageData.checkCoverage} />;
};

FwaEntry.propTypes = {
  isBusiness: PropTypes.bool,
  validateLocation: PropTypes.bool,
};

FwaEntry.defaultProps = {
  isBusiness: false,
  validateLocation: false,
};

export default FwaEntry;
