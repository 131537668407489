import { gql } from '@apollo/client';

const SearchBarFragment = gql`
  fragment SearchBarFragment on SearchBar {
    cludoCategory
    label
    placeholder
    searchBarType
    resultingProductPdbId
  }
`;

export default SearchBarFragment;
