import { gql } from '@apollo/client';

const priceFragment = gql`
  fragment PriceFragment on Confirmation_Price {
    original
    discounted
    discount {
      amount
      description
      duration
      discountType
      discountPercentage
    }
  }
`;

const pricesFragment = gql`
  fragment PricesFragment on Confirmation_Prices {
    upfront {
      original
      discounted
      discount {
        amount
        description
        duration
      }
    }
    installment {
      original
      duration
      discounted
    }
  }
`;

const customerFragment = gql`
  fragment CustomerFragment on Confirmation_Customer {
    phoneNumber
    name
    email
    address {
      careOf
      streetAddress
      postalCode
      city
    }
  }
`;

const portInDetailsFragment = gql`
  fragment portInDetailsFragment on Confirmation_PortInDetails {
    msisdn
    type
    date
  }
`;

const confirmationFragment = gql`
  fragment Confirmation on Confirmation_Offer {
    orderRef
    sourceSystem
    customer {
      ...CustomerFragment
    }
    subscriptions {
      name
      msisdn
      portIn {
        mobile {
          ...portInDetailsFragment
        }
        fixed {
          ...portInDetailsFragment
        }
      }
      bindingPeriod
      bindingPeriodDescription
      price {
        ...PriceFragment
      }
      devices {
        productId
        name
        prices {
          ...PricesFragment
        }
      }
      vas {
        productId
        type
        name
        description
        price {
          ...PriceFragment
        }
      }
    }
    delivery {
      method
      price {
        ...PriceFragment
      }
    }
    activationFee {
      name
      count
      price {
        ...PriceFragment
      }
      total
    }
    summary {
      total
      upfront
      firstMonthPrice
      installmentPlans {
        period
        amount
      }
    }
    accessories {
      productId
      name
      paymentMethod
      prices {
        ...PricesFragment
      }
      contentImage {
        type
        url
        title
        description
      }
    }
    expirationTime
    cwaStatus {
      decision
      decisionReasons {
        code
        description
      }
    }
  }
  ${priceFragment}
  ${pricesFragment}
  ${customerFragment}
  ${portInDetailsFragment}
`;

export default confirmationFragment;
