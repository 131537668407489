import PropTypes from 'prop-types';
import CludoSearchBar from './CludoSearchBar';
import AddressCoverageEntry from './AddressCoverageEntry';
import SEARCH_TYPES from '../../../../../constants/searchBarTypes';

const SearchBar = (props) => {
  const { searchBarType } = props;

  switch (searchBarType) {
    case SEARCH_TYPES.CLUDO:
      return <CludoSearchBar {...props} />;
    case SEARCH_TYPES.ADDRESS:
      return <AddressCoverageEntry {...props} />;
    default:
      return null;
  }
};

SearchBar.propTypes = {
  searchBarType: PropTypes.oneOf([SEARCH_TYPES.CLUDO, SEARCH_TYPES.ADDRESS]).isRequired,
};

export default SearchBar;
