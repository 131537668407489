import { useLazyQuery, useMutation } from '@apollo/client';
import { error as logError } from '@hi3g-access/client-logger';
import getDefaultCustomerConsentsQuery from '../../apollo/queries/getDefaultCustomerConstents';
import updateCustomerConsentsMutation from '../../apollo/mutations/updateCustomerConsents';
import { getAcceptedCustomerConsent } from '../cookieHelpers/cookies';

/**
 * Custom consent hook to wrap logic related to updating consent
 */
const useConsent = () => {
  const [getDefaultConsent] = useLazyQuery(getDefaultCustomerConsentsQuery);
  const [updateCustomerConsents] = useMutation(updateCustomerConsentsMutation);
  const hasAcceptedConsent = getAcceptedCustomerConsent() === 'true';

  const updateMarketingConsentIfAccepted = async (caller = 'www.3.dk') => {
    if (hasAcceptedConsent) {
      const { data, error } = await getDefaultConsent({
        variables: { category: 'Marketing' },
      });

      if (error) {
        logError('Error while fetching default customer consents', error);
        return null;
      }

      const { consents, version } = data?.getDefaultCustomerConsents?.consents?.[0] || {};

      const consentList = consents?.map((consent) => ({
        consentId: consent.consentId,
        accepted: true,
        version,
      }));

      return updateCustomerConsents({
        variables: {
          consentList,
          caller,
        },
      }).catch((e) => {
        logError('Error while updating customer consents', e);
      });
    }
    return null;
  };

  return {
    updateMarketingConsentIfAccepted,
  };
};

export default useConsent;
