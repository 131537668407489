import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { DrawerWithCloseButton, Spinner } from '@3dk/3style';
import { campaignActive } from '../../components/tradeIn/consts';
import useTradeInEvent from '../../utils/customEvents/TradeInEvent';
import legacyCartQuery from '../../apollo/queries/legacyCartQuery';
import getCampaignDetailsQuery from '../../apollo/queries/getCampaignDetails';
import TradeIn from '../../components/tradeIn/TradeIn';
import getPriceQuotesQuery from '../../apollo/queries/getAllPriceQuotes';
import { mapPriceQuoteToCampaign } from '../../components/tradeIn/helpers';

const classes = {
  spinnerContainer: {
    paddingTop: '33%',
  },
  spinner: {
    scale: '1.5',
  },
};

const defaultDeviceSettings = {
  paymentPeriod: 10,
  campaignName: '',
  campaignId: null,
};

const TradeInContainer = () => {
  const [tradeInContainerSettings, setTradeInContainerSettings] = useState({});
  const [tradeInData, setTradeInData] = useState({ campaigns: [], products: [], reservedImeiNumbers: [] });

  const [getBasket, { loading: basketIsLoading }] = useLazyQuery(legacyCartQuery);
  const [getCampaignDetailsData, { loading: campaignDetailsLoading }] = useLazyQuery(getCampaignDetailsQuery);
  const [getPriceQuotes, { loading: priceQuoteLoading }] = useLazyQuery(getPriceQuotesQuery, {
    fetchPolicy: 'network-only',
  });

  const getTradeInDevices = useCallback(
    async (eventSetting) => {
      if (eventSetting.saveToBasket) {
        const response = await getBasket().catch(() => ({}));
        const basket = response.data?.legacyCart;
        const devices = (basket?.tradeInProducts || []).find(({ basketRowGuid }) => !basketRowGuid)?.devices || [];

        const reservedImeiNumbers = (basket?.tradeInProducts || [])
          .filter(({ basketRowGuid }) => basketRowGuid)
          .flatMap((row) => row.devices.map((device) => device.imei));

        const campaignsResponse = await getPriceQuotes({
          variables: {
            questionnaireAnswers: devices.map((device) => ({
              modelId: device.id || 0,
              answers: device.answers,
              campaignId: eventSetting.device.campaignId,
            })),
          },
        });

        const campaigns = (campaignsResponse.data?.priceQuotes || [])
          .map((priceQuote) => mapPriceQuoteToCampaign(priceQuote, eventSetting.device))
          .filter(Boolean);
        setTradeInData({ campaigns, products: devices, reservedImeiNumbers });
      } else {
        setTradeInData({ campaigns: [], products: [], reservedImeiNumbers: [] });
      }
    },
    [setTradeInData, getBasket, getPriceQuotes],
  );

  const updateSettingsDeviceDetails = useCallback(
    async (eventSetting) => {
      const settings = { ...eventSetting };
      settings.device = { ...defaultDeviceSettings, ...(settings.device || {}) };
      if (settings.device.campaignId) {
        const campaignRequest = { variables: { campaignId: settings.device.campaignId } };
        const response = await getCampaignDetailsData(campaignRequest).catch(() => ({}));
        const { data: campaignResponse } = response;
        if (campaignResponse && campaignResponse.campaignDetails.status === campaignActive) {
          settings.device.campaignName = campaignResponse.campaignDetails.name;
          settings.device.campaignId = campaignResponse.campaignDetails.id;
        }
      }
      return settings;
    },
    [getCampaignDetailsData],
  );

  useTradeInEvent(
    async (event) => {
      const eventSetting = event.detail || {};
      const panelIsOpening = eventSetting.isOpen && !tradeInContainerSettings.isOpen;
      if (!panelIsOpening) {
        return;
      }

      const settings = await updateSettingsDeviceDetails(eventSetting);
      await getTradeInDevices(settings);

      setTradeInContainerSettings(settings);
    },
    [tradeInContainerSettings, setTradeInContainerSettings, updateSettingsDeviceDetails, getTradeInDevices],
  );

  const closeDrawer = () => {
    setTradeInContainerSettings({ isOpen: false });
  };

  const campaignAccepted = () => {
    closeDrawer();
  };

  return (
    <DrawerWithCloseButton
      open={tradeInContainerSettings.isOpen}
      onClose={closeDrawer}
      onCloseButtonClick={closeDrawer}
    >
      {basketIsLoading || campaignDetailsLoading || priceQuoteLoading ? (
        <div css={classes.spinnerContainer}>
          <Spinner css={classes.spinner} />
        </div>
      ) : (
        <TradeIn
          initData={tradeInData}
          saveToBasket={tradeInContainerSettings.saveToBasket}
          device={tradeInContainerSettings.device || defaultDeviceSettings}
          onTradeInCanceled={closeDrawer}
          campaignAccepted={campaignAccepted}
        />
      )}
    </DrawerWithCloseButton>
  );
};

export default TradeInContainer;
