import { gql } from '@apollo/client';
import blockFragment from './block';

const carouselFragment = gql`
  fragment CarouselFragment on Carousel {
    loop
    autoplay
    pauseOnHover
    showPreviousAndNextButton
    blocks {
      type
      id
      ...BlockFragment
    }
  }
  ${blockFragment}
`;

export default carouselFragment;
