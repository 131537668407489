import { Button } from '@3dk/3style';
import getInternalLinkPageType from 'utils/url/getInternalLinkPageType';
import {
  backgroundColors,
  fontColors,
  getColorFromName,
} from '../../app/routes/pageContainer/page/Entry/Colors/Colors';
import LinkResolver from '../../components/LinkResolver';
import getOneClickProducts from '../getOneClickProducts';
import LINK_TYPES from '../../constants/linkTypes';

const makeClasses = (props) => ({
  linkResolverButton: {
    color: props.fontColor,
    background: getColorFromName(props.buttonColor) ?? props.buttonColor,
  },
});

export const extractLinkProps = (link) => {
  const { externalLink, internalLink, assetLink, internalAnchorLink, linkType } = link;
  let url;
  let internalLinkPageType;
  switch (linkType) {
    case LINK_TYPES.ASSET:
      url = assetLink?.fields?.file?.url || assetLink?.url;
      break;
    case LINK_TYPES.ENTRY:
      url = internalLink?.fields?.slug || internalLink?.slug;
      internalLinkPageType = { type: getInternalLinkPageType(internalLink) };
      break;
    case LINK_TYPES.URL:
      url = externalLink;
      break;
    case LINK_TYPES.ANCHOR:
      url = internalAnchorLink?.fields?.slug || internalLink?.slug;
      internalLinkPageType = { type: getInternalLinkPageType(internalAnchorLink || internalLink) };
      break;
    default:
      break;
  }
  return { url, internalLinkPageType };
};

const renderLink = (node) => {
  const {
    linkType,
    text,
    buttonColor,
    oneClickDevice,
    oneClickSubscriptions,
    oneClickAccessories,
    oneClickVas,
    anchorLink,
    className,
  } = node;

  const { url, internalLinkPageType } = extractLinkProps(node);

  let fontColor;
  if (buttonColor?.toUpperCase() === fontColors.WHITE || getColorFromName(buttonColor) === fontColors.WHITE) {
    fontColor = fontColors.BLACK;
  } else if (getColorFromName(buttonColor) === backgroundColors.GOLD) {
    fontColor = backgroundColors.DARK_BLUE;
  } else {
    fontColor = fontColors.WHITE;
  }

  const classes = makeClasses({ buttonColor, fontColor });
  return (
    <LinkResolver
      buttonColor={buttonColor}
      fontColor={fontColor}
      linkType={linkType}
      url={url}
      internalLink={internalLinkPageType}
      oneClickProducts={getOneClickProducts(oneClickDevice, oneClickSubscriptions, oneClickAccessories, oneClickVas)}
      anchorLink={anchorLink}
      className={className}
    >
      {
        /* use inline styling here to avoid SSR problems caused by CSS miss match */
        linkType === LINK_TYPES.ONE_CLICK ? text : <Button style={classes.linkResolverButton}>{text}</Button>
      }
    </LinkResolver>
  );
};

export default renderLink;
