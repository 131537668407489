import { useAuthContext, useAuth, getNotificationBarPropsFromType } from '@3dk/3common';
import { CHECKOUT, CONFIRMATION_SELECTION, PRE_DC_CHECKOUT } from '../../../constants/urls';
import { triggerLogoutEvent } from '../../../utils/customEvents/LogoutEvent';

const useAuthState = (toggleSidePanelChangeName, toggleSidePanelChangePassword) => {
  const { user, refetch: refetchUserDetails, useGetCustomer } = useAuthContext();

  const { useLogout, useChangeUsername, useChangePassword, accounts } = useAuth({
    changeUsernameMutationOptions: {
      onSuccess: () => {
        refetchUserDetails().then(() => {
          toggleSidePanelChangeName();
        });
      },
    },
    changePasswordMutationOptions: {
      onSuccess: () => {
        setTimeout(() => toggleSidePanelChangePassword(), 2000);
      },
    },
    logoutMutationOptions: {
      // force reload to trigger BE logic
      // let m3c BE decide what to do when logging out
      onSuccess: () => {
        if (typeof window === 'undefined') {
          return;
        }
        const loginProtectedPaths = [CHECKOUT, CONFIRMATION_SELECTION, PRE_DC_CHECKOUT];
        if (loginProtectedPaths.some((route) => window.location.pathname.startsWith(route))) {
          window.location.reload();
        }
        triggerLogoutEvent();
        refetchUserDetails();
      },
    },
  });

  const {
    logout,
    isLoading: isLoggingOut,
    isError: isErrorLoggingOut,
    isSuccess: successLoggingOut,
    reset: resetLogout,
    error: errorLoggingOut,
  } = useLogout;

  const {
    changeUsername,
    isLoading: isChangingUsername,
    isError: isErrorChangingUsername,
    isSuccess: successChangingUsername,
    reset: resetChangeUsernameMutation,
    error: errorChangingUsername,
  } = useChangeUsername;

  const {
    changePassword,
    isLoading: isChangingPassword,
    isError: isErrorChangingPassword,
    isSuccess: successChangingPassword,
    reset: resetChangePasswordMutation,
    error: errorChangingPassword,
  } = useChangePassword;

  const isSuccess = successLoggingOut || successChangingUsername || successChangingPassword;

  const hasError = isErrorLoggingOut || isErrorChangingUsername || isErrorChangingPassword;

  const error = errorLoggingOut || errorChangingUsername || errorChangingPassword;

  const isLoading = isLoggingOut || isChangingUsername || isChangingPassword;

  const notificationBarProps = getNotificationBarPropsFromType({
    successLoggingOut,
    success: isSuccess,
    error: hasError,
  });

  // Query/Mutation outcomes after resolved, will not default back to original state, unless we reset manually, or component is reloaded
  const cleanupState = () => {
    if (isErrorLoggingOut || successLoggingOut) resetLogout();
    if (isErrorChangingUsername || successChangingUsername) {
      resetChangeUsernameMutation();
    }
    if (isErrorChangingPassword || successChangingPassword) {
      resetChangePasswordMutation();
    }
  };

  const auth = {
    logout,
    isLoading,
    changeName: (payload) => changeUsername({ name: payload }),
    changePassword: (payload) => changePassword(payload),
  };

  const isUserLoggedIn = user?.isLoggedIn;

  return {
    auth,
    user,
    successLoggingOut,
    successChangingUsername,
    notificationBarProps,
    hasError,
    isSuccess,
    error,
    cleanupState,
    isUserLoggedIn,
    refetchUserDetails,
    accounts,
    useGetCustomer,
  };
};

export default useAuthState;
