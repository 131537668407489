import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LoupeIcon, SearchField } from '@3dk/3style';
import createCludoUrlQuery from './utils/createCludoQueryUrl';

const classes = {
  searchField: {
    width: '100%',
  },
};

const CludoSearchBar = (props) => {
  const { cludoCategory, label, placeholder } = props;
  const history = useHistory();
  const handleOnSearch = (value) => {
    const urlQuery = createCludoUrlQuery({
      category: cludoCategory,
      query: value,
    });

    const url = `/soeg/#${urlQuery}`;
    history.push(url);
  };

  return (
    <SearchField
      css={classes.searchField}
      icon={<LoupeIcon />}
      label={label}
      placeholder={placeholder}
      onSearch={handleOnSearch}
    />
  );
};

CludoSearchBar.propTypes = {
  cludoCategory: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default CludoSearchBar;
