import { useEffect, useRef, useState } from 'react';
import { GlobalHeader, Snackbar } from '@3dk/3style';
import {
  generateUserObject,
  redirectToLogin,
  useConsent,
  useContracts,
  useContractsCms,
  useCustomerInformation,
  GetSelectedAccount,
  SetSelectedAccount,
  useAdminUser,
} from '@3dk/3common';
import { error as logError } from '@hi3g-access/client-logger';
import WidthWrapper from 'components/WidthWrapper';
import PropTypes from 'prop-types';
import dataLayer from '../../../utils/dataLayer/dataLayer';
import useAuthState from './useAuthState';
import usePrevious from '../../../utils/usePreviousHook';
import useGlobalHeaderPanelState from './useGlobalHeaderPanelState';
import useLegacyCart from '../../../utils/hooks/useLegacyCartHook';
import { triggerAccountChangeEvent } from '../../../utils/customEvents/AccountChangeEvent';

const GlobalHeaderComponent = (props) => {
  const { disableAccountLevelDiscount } = props;
  const [open, setOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });

  const { changeNameSidePanelProps, changePasswordSidePanelProps } = useGlobalHeaderPanelState();

  const {
    user,
    auth,
    successLoggingOut,
    successChangingUsername,
    notificationBarProps,
    isSuccess,
    hasError,
    error,
    cleanupState,
    isUserLoggedIn,
    refetchUserDetails,
    accounts,
    useGetCustomer,
  } = useAuthState(
    changeNameSidePanelProps.toggleSidePanelChangeName,
    changePasswordSidePanelProps.toggleSidePanelChangePassword,
  );
  const renderedAccountNumber = useRef();

  const { useGetContracts } = useContracts();
  const { useGetContractsCms } = useContractsCms();
  const { updateAccountLevelDiscount } = useLegacyCart();

  const previousIsLoggedIn = usePrevious(isUserLoggedIn);
  const [isConsentPanelOpen, setIsConsentPanelOpen] = useState(false);

  const { useGetConsents, useUpdateConsents } = useConsent();
  const consentsProps = {
    showSidePanelConsents: isConsentPanelOpen,
    toggleSidePanelConsents: setIsConsentPanelOpen,
    updateConsent: useUpdateConsents,
    getConsents: useGetConsents,
  };

  // Push global tracking event to dataLayer each time user changes login method
  useEffect(() => {
    if (isUserLoggedIn !== previousIsLoggedIn) {
      dataLayer.pushGlobalTrackingEvent(user);
    }
  }, [isUserLoggedIn, previousIsLoggedIn, user]);

  const selectedAccount = GetSelectedAccount();

  useEffect(() => {
    if (!renderedAccountNumber.current && selectedAccount) {
      renderedAccountNumber.current = selectedAccount;
      triggerAccountChangeEvent({ selectedAccount });
      if (!disableAccountLevelDiscount) {
        updateAccountLevelDiscount();
      }
    }
  }, [selectedAccount, disableAccountLevelDiscount, updateAccountLevelDiscount]);

  useEffect(() => {
    if (hasError || isSuccess) {
      setOpen(true);
      setSnackbarProps(notificationBarProps);
      // Updates the username shown in the Profilebar
      if (successChangingUsername) {
        refetchUserDetails();
      }
      cleanupState();
    }
  }, [hasError, isSuccess, notificationBarProps, cleanupState, successChangingUsername, refetchUserDetails]);

  if (hasError) {
    logError(error);
  }

  const onClose = () => {
    setOpen(false);
    if (successLoggingOut) {
      redirectToLogin();
    }
  };

  const { useChangePhoneNumber, useChangeEmail, useVerifyEmail, useGetCustomerInformationCms } =
    useCustomerInformation();

  const userProp = generateUserObject(user);

  const updateAccountNumber = (accountNumber) => {
    triggerAccountChangeEvent({ selectedAccount });
    SetSelectedAccount(accountNumber);
    if (!disableAccountLevelDiscount) {
      updateAccountLevelDiscount();
    }
  };

  return (
    <WidthWrapper>
      <Snackbar isOpen={open} onClose={onClose} {...snackbarProps} />
      {isUserLoggedIn && (
        <GlobalHeader
          user={userProp}
          auth={auth}
          changeNameSidePanelProps={changeNameSidePanelProps}
          changePasswordSidePanelProps={changePasswordSidePanelProps}
          accounts={accounts}
          onAccountSelection={updateAccountNumber}
          selectedAccount={selectedAccount}
          consentsProps={consentsProps}
          getCustomerInformationCms={useGetCustomerInformationCms}
          useGetCustomer={useGetCustomer}
          changePhoneNumberCallback={useChangePhoneNumber}
          changeEmailCallback={useChangeEmail}
          verifyEmailCallback={useVerifyEmail}
          getContracts={useGetContracts}
          getContractsCms={useGetContractsCms}
          useAdminUser={useAdminUser}
        />
      )}
    </WidthWrapper>
  );
};

GlobalHeaderComponent.propTypes = {
  disableAccountLevelDiscount: PropTypes.bool,
};

GlobalHeaderComponent.defaultProps = {
  disableAccountLevelDiscount: false,
};

export default GlobalHeaderComponent;
