// Names of expansion panels
export const CUSTOMER_TYPE = 'customerType';
export const CUSTOMER_INFORMATION = 'customerInformation';
export const CREDIT_CHECK_STEP = 'creditCheckStep';
export const SHIPPING_INFORMATION = 'shippingInformation';
export const ORDER_CONFIRMATION = 'orderConfirmation';
export const PAYMENT = 'payment';

// Get step number from panel name
export const checkoutStepTable = {
  [CUSTOMER_TYPE]: 1,
  [CUSTOMER_INFORMATION]: 2,
  [CREDIT_CHECK_STEP]: 3,
  [SHIPPING_INFORMATION]: 4,
  [ORDER_CONFIRMATION]: 5,
  [PAYMENT]: 6,
};

export const INSTALLMENT_PAYMENT = 'installment';
export const UPFRONT_PAYMENT = 'upfront';
export const MIXED_PAYMENT = 'mixed';

// Order submission requires streetNumber to be set in OrderInfo object.
// If we fail to extract streetNumber from adress string, we fallback to this.
export const NOT_FOUND_STREET_NUMBER = 'NaN';

export const NEMID_UNKNOWN_NAME = 'Pseudonym';

export const AUTH_RESPONSE_PARAMETER = 'authresponse';
export const AUTH_SUCCESS = 'verification_succeeded';
export const AUTH_SUCCESS_UNKNOWN_NAME = 'unknown_first_name';
export const AUTH_FAILED = 'verification_failed';
export const AUTH_FAILED_SSG = 'verification_failed_ssg';
export const AUTH_FAILED_AGE = 'verification_failed_age';
export const AUTH_CANCEL = 'verification_canceled';
export const AUTH_SUCCESS_CONFLICTING_ID = 'verification_id_conflict';
export const AUTH_ABORTED_CONFLICTING_ID = 'verification_aborted_id_conflict';
export const LOGIN_ERROR = [AUTH_FAILED, AUTH_FAILED_SSG, AUTH_FAILED_AGE, AUTH_ABORTED_CONFLICTING_ID];

export const AuthEditorialTexts = {
  customerTypePanelHeadline: 'Log ind eller fortsæt som gæst?',
  customerTypeHeadline: 'Log ind eller fortsæt som gæst?',
  customerTypePickCustomerTypeLabel:
    'Bruger du NemID / MitID kan du købe dit tilbehør på afbetaling - helt rente- og & gebyrfrit.',
  customerTypeExistingCustomerLabel: 'Login med NemID / MitID',
  customerTypeNewCustomerLabel: 'Fortsæt som gæst',
};
