import { gql } from '@apollo/client';

const iframeFragment = gql`
  fragment IFrameFragment on IFrame {
    anchor
    contentDescription
    source
    height
    dataToInject
  }
`;

export default iframeFragment;
