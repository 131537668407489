import { useEffect } from 'react';

/*
 * The SpeedPerform script is hardcoded to look for urls starting with
 * ["/business/hjalp/vejledninger","/hjalp/vejledninger"]
 * Therefore, the route for GuidePage need to be included in that array.
 * If it is a requirement to change it, SpeedPerform will need to update their script.
 */

const GuidePage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.sp-selfservice.com/cs/three-dk/main.js';
    script.type = 'text/javascript';
    script.id = 'speedPerform';
    document.body.appendChild(script);
  }, []);

  return <div id="speedperform" />;
};
export default GuidePage;
