import { gql } from '@apollo/client';

const verifyReCaptchaTokenQuery = gql`
  query verifyReCaptchaToken($token: String!, $expectedAction: String!, $siteKey: String!) {
    verifyReCaptchaToken(token: $token, expectedAction: $expectedAction, siteKey: $siteKey) {
      score
      reasons
      extendedVerdictReasons
    }
  }
`;

export default verifyReCaptchaTokenQuery;
