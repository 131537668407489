import { gql } from '@apollo/client';
import blockFragment from './block';
import carouselFragment from './carousel';
import rowContainerFragment from './rowContainer';

const gridContainerFragment = gql`
  fragment GridContainerFragment on GridContainer {
    grid {
      size
      display {
        width
        offset
        verticalCenter
      }
    }
    useFullWidth
    backgroundImage {
      url
    }
    backgroundImageLargeDevices {
      url
    }
    contentPaddingVertical
    backgroundColorPicker
    blocks {
      __typename
      type
      id
      ...BlockFragment
      ...CarouselFragment
      ...RowContainerFragment
    }
  }
  ${blockFragment}
  ${carouselFragment}
  ${rowContainerFragment}
`;

export default gridContainerFragment;
