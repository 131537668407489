import { useEffect } from 'react';

const IFRAME_INJECT = 'iframe:inject';

export const triggerDataIframeInjection = (data) => {
  window.dispatchEvent(new CustomEvent(IFRAME_INJECT, { detail: data }));
};

const useIframeInjectionEvent = (handler, deps) => {
  useEffect(() => {
    window.addEventListener(IFRAME_INJECT, handler);
    return () => {
      window.removeEventListener(IFRAME_INJECT, handler);
    };
  }, deps);
};

export default useIframeInjectionEvent;
