import { Headline, Body, CircleFilledIcon, Divider, LoadingButton, Paper } from '@3dk/3style';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import Flex from '../Flex/Flex';
import useDotColor from './hooks/useDotColor';
import formatExpectedDelivery from './utils/formatExpectedDelivery';
import LegalText from '../../app/routes/pageContainer/page/Entry/LegalText';

const classes = {
  bottom: {
    marginTop: 'auto',
  },
  button: {
    marginBottom: '30px',
    width: '217px',
  },
  dot: {
    marginRight: '10px',
  },
  legalText: {
    marginBottom: '30px',
  },
  paper: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    width: '100%',
    padding: '30px 0',
    borderRadius: '0',
    [theme.breakpoints.up('lg')]: {
      minHeight: '500px',
      width: '417px',
      padding: '40px 20px',
      borderRadius: '10px',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 15px 0px',
    },
  }),
  title: {
    fontWeight: '700',
  },
  stickyButtonContainer: (theme) => ({
    position: 'fixed',
    top: '0',
    left: '0',
    backgroundColor: theme.palette.PRIMARY_WHITE,
    zIndex: '10',
    height: '110px',
    width: 'fill-available',
    padding: '16px 12px 16px 12px',
    boxShadow: `0px 0px 5px ${theme.palette.SECONDARY_BLACK}`,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
  stickyButton: {
    margin: '0',
  },
};

const Configurator = (props) => {
  const {
    children,
    className,
    delivery,
    isAddToCartButtonDisabled,
    isAddToCartButtonLoading,
    onAddToCartButtonClick,
    subtitle,
    title,
    isBetterAldDiscount,
    useStickyButton,
  } = props;

  const dotColor = useDotColor(delivery);
  const [showStickyButton, setShowStickyButton] = useState(false);
  const loadingButtonRef = useRef(null);

  useEffect(() => {
    if (useStickyButton && loadingButtonRef.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].boundingClientRect.y < 0 && !entries[0].isIntersecting) {
          setShowStickyButton(true);
        } else {
          setShowStickyButton(false);
        }
      });
      const loadingButton = loadingButtonRef.current;
      observer.observe(loadingButton);

      // Cleanup function to unobserve
      return () => observer.disconnect();
    }
    return () => undefined;
  }, [useStickyButton, loadingButtonRef]);

  return (
    <Paper className={className} css={classes.paper} elevation={0}>
      {title && (
        <Headline component="h1" css={classes.title}>
          {title}
        </Headline>
      )}
      {subtitle && <Body type="faded">{subtitle}</Body>}
      <Divider />
      {children}
      <Flex css={classes.bottom} alignItems="center" flexDirection="column">
        <LoadingButton
          ref={loadingButtonRef}
          css={classes.button}
          disabled={isAddToCartButtonDisabled}
          onClick={onAddToCartButtonClick}
          pending={isAddToCartButtonLoading}
        >
          {t('Product_Page.Configurator.Add_To_Cart')}
        </LoadingButton>
        {isBetterAldDiscount && (
          <LegalText
            additionalText={t('Product_Page.Configurator.Account_Level_Discount_Legal_Text')}
            textAlignment="Center"
          />
        )}
        <Flex alignItems="center">
          <CircleFilledIcon css={classes.dot} color={dotColor} />
          <Body>{formatExpectedDelivery(delivery)}</Body>
        </Flex>
      </Flex>
      {showStickyButton && (
        <Flex css={classes.stickyButtonContainer} flexDirection="column" justifyContent="space-between">
          <Headline>{title}</Headline>
          {/* this loadingButton should be the same as the one above except with different styling */}
          <LoadingButton
            css={classes.stickyButton}
            disabled={isAddToCartButtonDisabled}
            onClick={onAddToCartButtonClick}
            pending={isAddToCartButtonLoading}
          >
            {t('Product_Page.Configurator.Add_To_Cart')}
          </LoadingButton>
        </Flex>
      )}
    </Paper>
  );
};

Configurator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  delivery: PropTypes.shape({
    dateInterval: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    deliveryDate: PropTypes.string,
  }),
  isAddToCartButtonDisabled: PropTypes.bool,
  isAddToCartButtonLoading: PropTypes.bool,
  onAddToCartButtonClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  isBetterAldDiscount: PropTypes.bool,
  useStickyButton: PropTypes.bool,
};

Configurator.defaultProps = {
  className: null,
  children: null,
  delivery: null,
  isAddToCartButtonDisabled: false,
  isAddToCartButtonLoading: false,
  onAddToCartButtonClick: () => {},
  subtitle: null,
  title: null,
  isBetterAldDiscount: false,
  useStickyButton: false,
};

export default Configurator;
