import { gql } from '@apollo/client';
import blockFragment from '../fragments/block';

const getUspRibbonQuery = gql`
  query getUspRibbon {
    uspRibbon {
      backgroundColor
      blocks {
        ...BlockFragment
      }
    }
  }
  ${blockFragment}
`;

export default getUspRibbonQuery;
