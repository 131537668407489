import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import LoadingAnimation from 'app/routes/orderVerificationContainer/LoadingAnimation';
import ApolloErrorHandler from 'components/errorHandling/ApolloErrorHandler';
import { useEffect } from 'react';
import createPreDcOrderMutation from '../../apollo/mutations/createPreDcOrder';
import { buildRoute, LEGACY_PORTING, PRE_DC_RECEIPT } from '../../constants/urls';
import runtimeConfig from '../../utils/config';
import dataLayer from '../../utils/dataLayer/dataLayer';
import getPreDcOrderSummaryQuery from '../../apollo/queries/getPreDcOrderSummary';
import useConsent from '../../utils/hooks/useConsent';
import useCart from '../../utils/hooks/useCartHook';

const navigateToLegacyPorting = (orderId, receiptUrl) => {
  const legacyBaseUrl = runtimeConfig.WEB_SHOP_ORIGIN_URL;
  const returnUrl = window.location.origin.concat(receiptUrl);
  const legacyPortingUrl = `${LEGACY_PORTING}?guid=${orderId}&returnUrl=${encodeURIComponent(returnUrl)}`;
  window.location.href = `${legacyBaseUrl}${legacyPortingUrl}`;
};

const PreDcOrderVerification = () => {
  const location = useLocation();
  const history = useHistory();
  const { deleteCart } = useCart();
  const parameters = new URLSearchParams(location.search);
  const cartId = parameters.get('cartId');
  const legacyCartId = parameters.get('legacyCartId');
  const paymentId = parameters.get('paymentId');
  const forceInvoice = parameters.get('forceInvoice') === 'true';
  const signUpOnly = parameters.get('signUpOnly') === 'true';
  ['cartId', 'legacyCartId', 'paymentId', 'forceInvoice', 'signUpOnly', 'clientOrderId'].forEach((param) =>
    parameters.delete(param),
  );

  const { updateMarketingConsentIfAccepted } = useConsent();
  const [getOrderSummary] = useLazyQuery(getPreDcOrderSummaryQuery);

  const handleCreatePreDcOrderCompleted = async (orderId) => {
    if (!orderId) {
      return null;
    }

    const orderSummaryResponse = await getOrderSummary({ variables: { orderId } });
    const { preDcOrderSummary } = orderSummaryResponse?.data ?? {};
    if (!preDcOrderSummary) {
      return null;
    }

    return preDcOrderSummary;
  };

  const [createPreDcOrder, { error: placeOrderError }] = useMutation(createPreDcOrderMutation, {
    onCompleted: async (submitOrderData) => {
      const { orderId, orderHasMissingPortingInfo } = submitOrderData.createPreDcOrder || {};

      const orderSummary = await handleCreatePreDcOrderCompleted(orderId);
      if (orderSummary) {
        // Note: If there's a navigation away from the app, the tracking of the dataLayer event is not guaranteed.
        // This is not an issue when a call occurs after the push.
        // If this changes, include eventCallback and eventTimeout in the event.
        dataLayer.pushPreDcPurchaseToDataLayer(orderSummary);
      }
      await updateMarketingConsentIfAccepted('PreDC Checkout');
      await deleteCart();

      window.history.replaceState(null, null, '/');
      const searchParams = parameters.size > 0 ? `?${parameters}` : '';
      const receiptUrl = `${buildRoute(PRE_DC_RECEIPT, orderId)}${searchParams}`;

      if (orderHasMissingPortingInfo) {
        navigateToLegacyPorting(orderId, receiptUrl);
      } else {
        history.push(receiptUrl);
      }
    },
    update: (cache) => {
      cache.evict({ fieldName: 'legacyCart' });
      cache.evict({ fieldName: 'unifiedCart' });
      cache.gc();
    },
  });

  useEffect(() => {
    if (legacyCartId) {
      createPreDcOrder({ variables: { cartId, legacyCartId, paymentId, forceInvoice, signUpOnly } });
    }
  }, [cartId, legacyCartId, createPreDcOrder]);

  if (placeOrderError) {
    return <ApolloErrorHandler error={placeOrderError} />;
  }

  return <LoadingAnimation />;
};

export default PreDcOrderVerification;
