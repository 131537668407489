import { createContext, useContext } from 'react';
import { CHILDREN_TYPE, CONTEXT_TYPE } from './utils/types';

const SubscriptionPageContext = createContext(null);

const SubscriptionProvider = ({ children, context }) => (
  <SubscriptionPageContext.Provider value={context}>{children}</SubscriptionPageContext.Provider>
);

SubscriptionProvider.propTypes = {
  children: CHILDREN_TYPE.isRequired,
  context: CONTEXT_TYPE,
};

SubscriptionProvider.defaultProps = {
  context: null,
};

export const useSubscriptionPageContext = () => useContext(SubscriptionPageContext);

export default SubscriptionProvider;
