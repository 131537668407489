import { gql } from '@apollo/client';

const searchAddressQuery = gql`
  query SearchAddress($searchQuery: String!, $amount: Int) {
    searchAddress(searchQuery: $searchQuery, amount: $amount) {
      id
      latitude
      longitude
      displayName
    }
  }
`;

export default searchAddressQuery;
