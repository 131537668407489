import { gql } from '@apollo/client';

const videoBlockFragment = gql`
  fragment VideoBlockFragment on VideoBlock {
    title
    video {
      url
    }
    showControls
    loop
    autoPlay
  }
`;

export default videoBlockFragment;
