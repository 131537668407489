import { useQuery } from '@apollo/client';
import getLoginState from '../apollo/queries/getLoginState';
import LOGIN_STATE from '../constants/loginTypes';
import { CUSTOMER_TYPES } from '../constants/customerTypes';

const useIsLoggedIn = (options) => {
  const { skip } = options || {};
  const { data, loading: loginLoading, error: loginError } = useQuery(getLoginState, { skip, ssr: false });
  const loginType = data?.loginState.loginType;
  const isPublicLogin = loginType === LOGIN_STATE.PUBLIC;
  const isLoggedIn = data?.loginState.isLoggedIn;

  // isLoggedIn comes from the SSG service and is false for new costumers
  // loginType comes from redis and represents whether SSO thinks we're logged in

  let customerType = CUSTOMER_TYPES.UNKNOWN;
  if (isLoggedIn) {
    customerType = CUSTOMER_TYPES.EXISTING;
  } else if (isPublicLogin) {
    customerType = CUSTOMER_TYPES.NEW;
  }

  return {
    customerType,
    isLoggedIn,
    isPublicLogin,
    loginType,
    loginLoading,
    loginError,
  };
};

export default useIsLoggedIn;
