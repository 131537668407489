import { gql } from '@apollo/client';

const preDcCheckoutQuery = gql`
  query PreDcCheckout {
    preDcCheckout {
      termsOfSale {
        url
      }
      subscriptionTerms {
        url
      }
      multipleOrderTypesErrorText
      invalidOrderForm {
        source
        height
      }
      subscriptionLimitInfoHeader
      subscriptionLimitInfoText
      creditCheckRejectedText
      creditCheckControlHeader
      creditCheckControlText
      paymentStep {
        errorBoxHeadline
        errorBoxContent
        errorBoxBackgroundColor
        errorBoxFontColor
      }
    }
  }
`;

export default preDcCheckoutQuery;
