import PropTypes from 'prop-types';
import { DISCOUNT_TYPE, PRICE_TYPE } from './utils/types';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  discountText: (theme) => ({
    color: theme.palette.PRIMARY_ORANGE,
    width: '100%',
  }),
  row: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: '10px 0',
    borderBottom: `1px dashed ${theme.palette.PRIMARY_BLACK}`,
  }),
};

const PriceOverviewGrid = ({ className, discount, price }) => {
  const hasDiscount = !!discount;
  const isPlural = discount?.numberOfMonths > 1;
  return (
    <div className={className} css={classes.container}>
      {hasDiscount && (
        <>
          <div css={classes.row}>
            <div>
              Pris {isPlural ? 'de' : 'den'} første {discount.numberOfMonths} {isPlural ? 'måneder' : 'måned'}
            </div>
            <div>{`${discount.price.installment} kr./md.`}</div>
            <div css={classes.discountText}>{discount.text}</div>
          </div>
          <div css={classes.row}>
            <div>Pris herefter</div>
            <div>{price.installment} kr./md.</div>
          </div>
        </>
      )}
      <div css={classes.row}>
        <div>Oprettelse</div>
        <div>{price.activation} kr.</div>
      </div>
    </div>
  );
};

PriceOverviewGrid.propTypes = {
  className: PropTypes.string,
  discount: DISCOUNT_TYPE,
  price: PRICE_TYPE.isRequired,
};

PriceOverviewGrid.defaultProps = {
  className: null,
  discount: null,
};

export default PriceOverviewGrid;
