const SUBSCRIPTION_TYPES = {
  HOME_INTERNET: 'HOME_INTERNET',
  MOBILE_BROADBAND: 'MOBILE_BROADBAND',
};

export const SUBSCRIPTION_SLUGS = {
  HOME_INTERNET: 'internet-til-hjemmet',
  MOBILE_BROADBAND: 'mobilt-internet',
};

export const SUBSCRIPTION_CAPTIONS = {
  HOME_INTERNET: 'Internet til hjemmet',
  MOBILE_BROADBAND: 'Mobilt internet',
};

export const SUBSCRIPTION_PAGE_SUBTITLE = {
  HOME_INTERNET: 'Internet',
  MOBILE_BROADBAND: 'Internet',
  Voice: 'Tale',
  Data: 'Internet',
};

export const ROAMING_TYPES = {
  NATIONAL: 'NATIONAL',
};

export const LEGACY_WEBSHOP_SUBSCRIPTION_TYPES = {
  DATA: 'Data',
  VOICE: 'Voice',
};

export default SUBSCRIPTION_TYPES;
