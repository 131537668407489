import { useMutation } from '@apollo/client';
import { error as logError } from '@hi3g-access/client-logger';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ApolloResponseStatus from '../../apollo/ApolloResponseStatus';
import recoverLegacyCartMutation from '../../apollo/mutations/recoverLegacyBasket';
import getUnifiedCartQuery from '../../apollo/queries/getUnifiedCart';
import legacyCartQuery from '../../apollo/queries/legacyCartQuery';
import eventEmitter from '../eventEmitter';
import useLegacyCart from './useLegacyCartHook';
import useCart from './useCartHook';
import parseString from '../parseString';
import getCartQuery from '../../apollo/queries/getCart';
import recoverCartMutation from '../../apollo/mutations/recoverCart';

const useRecoverBasket = () => {
  const location = useLocation();
  const { softDeleteLegacyCart } = useLegacyCart();
  const { softDeleteCart } = useCart();

  const [recoverCart, { recoverCartLoading, recoverCartError }] = useMutation(recoverCartMutation, {
    // Function that adds product to cache and update badge displaying count of products in cart
    update: (store, { data: updatedCart }) => {
      const data = { cart: { ...updatedCart.recoverCart } };

      const count = data.cart.items.length;
      eventEmitter.updateCountOfProductsInCart(count);

      store.writeQuery({
        query: getCartQuery,
        data,
      });
    },
  });

  const [recoverLegacyCart, { loading: recoverLegacyCartLoading, error: recoverLegacyCartError }] = useMutation(
    recoverLegacyCartMutation,
    {
      update: (cache, { data }) => {
        const { status, response } = data.recoverBasket;
        if (status === ApolloResponseStatus.SUCCESS) {
          cache.writeQuery({
            query: legacyCartQuery,
            data: { legacyCart: response },
          });
        }
      },
      refetchQueries: [getUnifiedCartQuery],
    },
  );

  const recoverBasket = useCallback(
    (softDelete = false) => {
      const parameters = new URLSearchParams(location.search);

      const legacyCartId = parseString(parameters.get('legacyCartId'));
      const cartId = parseString(parameters.get('cartId'));

      if (recoverLegacyCartError || recoverCartError) {
        // there are errors recovering basket;
        logError('Error Recovering Basket', {
          recoverLegacyCartError,
          recoverCartError,
        });
        return false;
      }

      const shouldRecoverLegacyCart = !recoverLegacyCartLoading && legacyCartId;
      const shouldSoftDeleteLegacyCart = softDelete && !legacyCartId;

      const shouldRecoverDcCart = !recoverCartLoading && cartId;
      const shouldSoftDeleteDcCart = softDelete && !cartId;

      let recoverLegacyCartPromise;
      if (shouldRecoverLegacyCart) {
        recoverLegacyCartPromise = recoverLegacyCart({ variables: { basketId: legacyCartId } });
      } else if (shouldSoftDeleteLegacyCart) {
        softDeleteLegacyCart();
      }

      let recoverCartPromise;
      if (shouldRecoverDcCart) {
        recoverCartPromise = recoverCart({ variables: { cartId } });
      } else if (shouldSoftDeleteDcCart) {
        recoverCartPromise = softDeleteCart();
      }

      return {
        recoverCartPromise,
        recoverLegacyCartPromise,
      };
    },
    [
      softDeleteCart,
      softDeleteLegacyCart,
      location.search,
      recoverCart,
      recoverCartError,
      recoverCartLoading,
      recoverLegacyCart,
      recoverLegacyCartError,
      recoverLegacyCartLoading,
    ],
  );

  return { recoverBasket };
};

export default useRecoverBasket;
