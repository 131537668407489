import PropTypes from 'prop-types';
import ErrorBoundary from 'components/ErrorBoundary';
import Entry from '../pageContainer/page/Entry';
import HelpForm from './HelpForm';

const classes = {
  tab: {
    paddingTop: '25px',
    position: 'static',
  },
};

// This is the Id of the formstack Form
// that can be found at https://www.formstack.com/admin/form/dashboard/folder
const HELP_PAGE_FORM_ID = '5591296';

const HelpFooterTab = ({ blocks, activeTab, tabIndex, isBusiness }) => (
  <div role="tabpanel" hidden={activeTab !== tabIndex} css={classes.tab}>
    {blocks?.map((block, index) => (
      <ErrorBoundary key={block?.id}>
        <Entry {...block} />
        {tabIndex === 0 && index === 0 && <HelpForm formstackFormId={HELP_PAGE_FORM_ID} isBusiness={isBusiness} />}
      </ErrorBoundary>
    ))}
  </div>
);

HelpFooterTab.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.shape(Entry.propTypes)).isRequired,
  activeTab: PropTypes.number.isRequired,
  tabIndex: PropTypes.number.isRequired,
  isBusiness: PropTypes.bool.isRequired,
};

export default HelpFooterTab;
