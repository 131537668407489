import PropTypes from 'prop-types';
import { Banner, Box, Small } from '@3dk/3style';
import useCountdown from '../utils/hooks/useCountdownHook';

const makeClasses = (props) => ({
  root: {
    display: props.asInlineEntry ? 'inline-flex' : 'flex',
    width: 'fit-content',
    p: {
      color: props.fontColor,
    },
  },
  timeUnit: {
    margin: '0px 5px',
    color: props.fontColor,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const formatDoubleDigit = (number) => {
  if (!number && number !== 0) return '';
  const numberString = number.toString();

  return numberString.padStart(2, '0');
};

const Countdown = (props) => {
  const { expiration, fontColor, asInlineEntry } = props;

  const classes = makeClasses({ fontColor, asInlineEntry });

  const countdownData = useCountdown({ oneTimeExpiration: expiration });

  if (!countdownData) {
    return null;
  }

  const { remainingDays, remainingHours, remainingMinutes, remainingSeconds } = countdownData;

  return (
    <Box css={classes.root}>
      <Box css={classes.timeUnit}>
        <Banner css={classes.text}>{remainingDays}</Banner>
        <Small>dage</Small>
      </Box>
      <Banner>:</Banner>
      <Box css={classes.timeUnit}>
        <Banner>{formatDoubleDigit(remainingHours)}</Banner>
        <Small>timer</Small>
      </Box>
      <Banner>:</Banner>
      <Box css={classes.timeUnit}>
        <Banner>{formatDoubleDigit(remainingMinutes)}</Banner>
        <Small>minutter</Small>
      </Box>
      <Banner>:</Banner>
      <Box css={classes.timeUnit}>
        <Banner>{formatDoubleDigit(remainingSeconds)}</Banner>
        <Small>sekunder</Small>
      </Box>
    </Box>
  );
};

Countdown.propTypes = {
  expiration: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  asInlineEntry: PropTypes.bool,
};

Countdown.defaultProps = {
  asInlineEntry: false,
};
export default Countdown;
