import { useQuery } from '@apollo/client';
import preDcCheckoutQuery from '../../apollo/queries/preDcCheckout';
import Loading from '../../components/Loading';
import WidthWrapper from '../../components/WidthWrapper';
import ApolloErrorHandler from '../../components/errorHandling/ApolloErrorHandler';
import IFrame from '../../components/IFrame';
import convertRichText from '../../utils/convertRichText';

const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '15px',
  },
};

const ContactCustomerService = () => {
  const { data, loading, error } = useQuery(preDcCheckoutQuery);

  if (loading) return <Loading />;

  if (error) return <ApolloErrorHandler error={error} />;

  const { invalidOrderForm, multipleOrderTypesErrorText } = data.preDcCheckout;
  if (!multipleOrderTypesErrorText) {
    return null;
  }

  const text = convertRichText(multipleOrderTypesErrorText);
  return (
    <WidthWrapper>
      <div css={classes.root}>
        <div>{text}</div>
        <IFrame {...invalidOrderForm} />
      </div>
    </WidthWrapper>
  );
};

export default ContactCustomerService;
