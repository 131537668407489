import { error as errorLog } from '@hi3g-access/client-logger';
import { useQuery } from '@apollo/client';
import getAccountLevelDiscountPreviewQuery from '../apollo/queries/getAccountLevelDiscountPreview';
import FEATURE_FLAG from './feature-flags';
import { useFeatureFlagContext } from '../optimizely';
import { useAccountChangeEvent } from './customEvents/AccountChangeEvent';
import useUnifiedCartHook from './hooks/useUnifiedCartHook';
import { useLogoutEvent } from './customEvents/LogoutEvent';

const useAccountLevelDiscountPreview = (variables, showAld = true) => {
  const aldDisabled = useFeatureFlagContext().getFeatureFlag(FEATURE_FLAG.ALD_DISABLED, false);
  const shouldSkip = aldDisabled || !variables || !showAld;

  const {
    data: accountLevelDiscountData,
    loading: isAldLoading,
    error,
    refetch: refetchAld,
  } = useQuery(getAccountLevelDiscountPreviewQuery, {
    variables,
    skip: shouldSkip,
  });

  if (error) {
    errorLog('ALD_PREVIEW_ERROR', { ...error, variables });
  }

  useAccountChangeEvent(() => {
    if (!shouldSkip) {
      refetchAld({ fetchPolicy: 'network-only' }).then();
    }
  }, [refetchAld]);

  useLogoutEvent(() => {
    if (!shouldSkip) {
      refetchAld({ fetchPolicy: 'network-only' }).then();
    }
  }, [refetchAld]);

  useUnifiedCartHook({
    offersChanged: {
      callback: () => !shouldSkip && refetchAld({ fetchPolicy: 'network-only' }).then(),
      deps: [refetchAld],
    },
  });

  const { getAccountLevelDiscountPreview: accountLevelDiscount } = accountLevelDiscountData || {};
  const { accountLevelDiscountPreview, currentAccountLevelDiscount, products } = accountLevelDiscount || {};
  const accountLevelDiscountChange = (accountLevelDiscountPreview ?? 0) - (currentAccountLevelDiscount ?? 0);
  const isBetterDiscount = accountLevelDiscountChange < 0;
  const subscriptionTotal = Math.abs(
    (products || []).reduce((acc, p) => acc + (p?.subscription?.price?.discountedRecurringPrice ?? 0), 0),
  );

  return {
    isAldLoading,
    error,
    accountLevelDiscount,
    accountLevelDiscountChange,
    isBetterDiscount,
    subscriptionTotal,
  };
};

export default useAccountLevelDiscountPreview;
