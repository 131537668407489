const cartIsEmpty = (cart) => {
  if (!cart) {
    return true;
  }

  const { items } = cart;
  if (!items) {
    return true;
  }

  return items.length === 0;
};

export default cartIsEmpty;
