import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { error as logError } from '@hi3g-access/client-logger';
import { SUBSCRIPTION_PAGE_SUBTITLE } from 'constants/subscriptionTypes';
import { ACCESSORY_VARIANT_TYPE } from 'utils/propTypes/accessories';
import PAGE_TYPE from 'constants/pageTypes';
import SubscriptionPageView from '../../components/pages/SubscriptionPage/SubscriptionPage';
import subscriptionPageQuery from '../../apollo/queries/subscriptionPage';
import useLegacyCart from '../../utils/hooks/useLegacyCartHook';
import ApolloErrorHandler from '../../components/errorHandling/ApolloErrorHandler';
import Loading from '../../components/Loading';
import { buildRoute, SUBSCRIPTION_PRODUCT_PAGE } from '../../constants/urls';
import SubscriptionPageContent from './subscriptionPage/SubscriptionPageContent';
import { triggerUpsaleBridge } from '../../utils/customEvents/BridgeUpsaleEvent';
import dataLayer from '../../utils/dataLayer/dataLayer';
import safeParse from '../../utils/safe-json';
import ContentfulProvider from './pageContainer/ContentfulContext';

const mapVariantToConfigurationOptionDrawerItem = (variant) => {
  const { displayName, price, slug, subscriptionId } = variant;
  return {
    id: subscriptionId,
    label: displayName,
    price: price.installment,
    priceUnit: 'kr./md.',
    // Expected delivery is hardcoded for legacy subscriptions in order to reuse displayed delivery logic
    expectedDelivery: {
      dateInterval: {
        from: 1,
        to: 3,
      },
      deliveryDate: null,
    },
    slug,
  };
};

const extendSubscriptionPage = (subscriptionPage) => {
  const {
    cardHeader,
    cardTitle,
    price,
    variants,
    specificationsJson,
    subscriptionCardTopBarImage,
    ribbonTopText,
    ribbonBottomText,
    dkOnly,
    bindingPeriodMonths,
    type,
  } = subscriptionPage;
  const is3LikeHome = safeParse(specificationsJson)['3LikeHome']?.toLowerCase().trim() === 'ja' || false;
  let finePrint = dkOnly ? 'Kan kun bruges i Danmark.' : '';
  // Not allowing bindingPeriodMonths === 1 is a result of this hotfix (NOV-2573)
  if (bindingPeriodMonths && bindingPeriodMonths !== 1) {
    finePrint = `${`Binding ${bindingPeriodMonths} mdr.`} ${finePrint}`;
  }

  return {
    ...subscriptionPage,
    cardProps: {
      header: cardHeader,
      backgroundImage: is3LikeHome ? 'orange' : 'black',
      topBar: subscriptionCardTopBarImage ? { url: subscriptionCardTopBarImage } : undefined,
      pricePerMonth: price.installment,
      title: cardTitle,
      ribbonText: ribbonTopText,
      smallRibbonText: ribbonBottomText,
    },
    finePrint,
    subtitle: SUBSCRIPTION_PAGE_SUBTITLE[type],
    variants: variants?.map(mapVariantToConfigurationOptionDrawerItem),
  };
};

const SubscriptionPage = () => {
  const history = useHistory();
  const params = useParams();
  const { data, error, loading, previousData } = useQuery(subscriptionPageQuery, { variables: { slug: params.slug } });
  const { addProductToLegacyCart, addProductToLegacyCartLoading } = useLegacyCart();

  useEffect(() => {
    if (data?.subscriptionPage) {
      dataLayer.legacySubscriptionDetails(data.subscriptionPage);
    }
  }, []);

  if (error) {
    return <ApolloErrorHandler error={error} />;
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const { subscriptionPage } = data ?? previousData ?? {};
  if (!subscriptionPage && loading) {
    return <Loading />;
  }

  const context = {
    addToCart: (subscriptionId) => {
      addProductToLegacyCart({ variables: { items: [subscriptionId] } })
        .then(triggerUpsaleBridge)
        .then(() => dataLayer.addLegacySubscriptionToCart(subscriptionPage))
        .catch((e) => {
          logError('Error while adding subscription to cart', { error: e, subscriptionId });
        });
    },
    isAddToCartLoading: addProductToLegacyCartLoading,
    selectVariant: (variant) => {
      // TODO(NOV-2439): URL should have traling slash.
      const newUrl = buildRoute(SUBSCRIPTION_PRODUCT_PAGE, variant.slug);
      history.push(newUrl);
    },
  };

  const extendedSubscriptionPage = extendSubscriptionPage(subscriptionPage);
  return (
    <ContentfulProvider data={subscriptionPage} contentfulPageType={PAGE_TYPE.SUBSCRIPTION_PAGE}>
      <SubscriptionPageView context={context} subscriptionPage={extendedSubscriptionPage}>
        <SubscriptionPageContent subscriptionPage={subscriptionPage} />
      </SubscriptionPageView>
    </ContentfulProvider>
  );
};

extendSubscriptionPage.PropTypes = {
  subscriptionPage: PropTypes.shape({
    cardHeader: PropTypes.string,
    cardTitle: PropTypes.string,
    price: PropTypes.shape({
      installment: PropTypes.number,
    }),
    type: PropTypes.string,
    variants: PropTypes.arrayOf(ACCESSORY_VARIANT_TYPE),
  }),
};

export default SubscriptionPage;
