import { gql } from '@apollo/client';

const getPriceQuote = gql`
  query PriceQuote($questionnaireAnswers: QuestionnaireAnswers) {
    priceQuote(questionnaireAnswers: $questionnaireAnswers) {
      identifierSuitable
      priceQuoteId
      grade
      offeredPrice
      offeredPriceInclVat
      offeredPriceMVAT
      currencyIsoCode
      currencySymbol
      exchangeRate
      potentialCampaign {
        campaignId
        dealBonusInclVat
      }
    }
  }
`;

export default getPriceQuote;
