import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Body, CartLine, Link, LoadingButton, Title } from '@3dk/3style';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { minimumBindingPeriod } from './consts';
import addTradeInToBasketMutation from '../../apollo/mutations/addTradeInToBasket';
import { CampaignType, ProductType, TradeInDeviceType } from './TradeInPropTypes';
import legacyCartQuery from '../../apollo/queries/legacyCartQuery';
import { mapEditingProductToChosen } from './helpers';

const classes = {
  overviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  price: {
    fontSize: '19px',
    lineHeight: '13px',
    marginRight: '3px',
    paddingBottom: '10px',
    paddingTop: '20px',
  },
  spinner: {
    padding: '5px',
  },
  confirmText: (theme) => ({
    '& * hr': {
      width: '100%',
      border: `1px solid ${theme.palette.PRIMARY_GREY}`,
    },
  }),
};

const currencySymbol = 'kr';
const priceDescription = 'Kontant';
const lessThanMinimumBindingPeriodText =
  'Udbetalingen skal mindst spredes over 10 måneder. Skulle du dog opsige efter 6 måneder, så får du det resterende udbetalt på sidste regning.';

const getTotalDiscount = (products) => (products || []).reduce((res, product) => res + product.price || 0, 0);

const TradeInConfirmView = ({
  products,
  device,
  removeProduct,
  addMore,
  campaignDiscount,
  acceptDiscount,
  saveToBasket,
}) => {
  const priceText = useMemo(() => {
    const period = Math.max(minimumBindingPeriod, device.paymentPeriod);
    const totalDiscount = getTotalDiscount(products) + (campaignDiscount?.discount ?? 0);
    return `${(totalDiscount / period).toLocaleString('da-DK', {
      maximumFractionDigits: 2,
    })} ${currencySymbol}./md. i ${period} mdr.`;
  }, [products, device]);

  const [addTradeInDataToBasket, { loading }] = useMutation(addTradeInToBasketMutation, {
    refetchQueries: [legacyCartQuery],
  });

  if (!products || !products.length) {
    return null;
  }

  const updateBasket = () => {
    addTradeInDataToBasket({
      variables: {
        tradeInData: {
          campaignId: device.campaignId ?? null,
          devices: products.map(mapEditingProductToChosen),
          tradeInCampaignDiscount: campaignDiscount
            ? {
                ...campaignDiscount,
                campaignDeviceId: `${device.deviceId}`,
              }
            : null,
          tradeInDiscount: getTotalDiscount(products),
        },
      },
    })
      .then((res) => acceptDiscount(res.data.addTradeInDevicesToBasket))
      .catch(() => alert('Handlingen kan ikke udføres, prøv igen'));
  };

  return (
    <div css={classes.overviewContainer}>
      <div>
        {products.map((product, index) => (
          <CartLine
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            image={
              product.pictureUrl
                ? {
                    url: product.pictureUrl,
                    alt: product.productNameShort,
                    title: product.productNameShort,
                  }
                : undefined
            }
            productTitle={product?.productNameShort}
            removeFromCart={() => removeProduct(index)}
            price={product.price}
            priceUnit={currencySymbol}
            priceDescription={priceDescription}
          />
        ))}
        {campaignDiscount && (
          <CartLine
            key={campaignDiscount.campaignHeading}
            productTitle={`Kampagne: ${campaignDiscount.campaignHeading}`}
            price={campaignDiscount.discount}
            priceUnit={currencySymbol}
            priceDescription={priceDescription}
          />
        )}
      </div>
      <div css={classes.priceContainer}>
        {device.paymentPeriod < minimumBindingPeriod && <Body>{lessThanMinimumBindingPeriodText}</Body>}
        {priceText && <Title css={classes.price}>{priceText}</Title>}

        {saveToBasket && (
          <LoadingButton onClick={updateBasket} pending={loading}>
            {t('Trade_In.General.Accept_Button_Label')}
          </LoadingButton>
        )}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={addMore}>{t('Trade_In.General.Add_Additional_Device_Label')}</Link>
        {t('Trade_In.General.Confirm_Text') && (
          <div css={classes.confirmText}>{t('Trade_In.General.Confirm_Text')}</div>
        )}
      </div>
    </div>
  );
};

TradeInConfirmView.propTypes = {
  device: TradeInDeviceType.isRequired,
  products: PropTypes.arrayOf(ProductType),
  removeProduct: PropTypes.func,
  addMore: PropTypes.func,
  acceptDiscount: PropTypes.func,
  campaignDiscount: CampaignType,
  saveToBasket: PropTypes.bool,
};

TradeInConfirmView.defaultProps = {
  products: [],
  addMore: null,
  campaignDiscount: null,
  acceptDiscount: () => null,
  removeProduct: () => {},
  saveToBasket: false,
};

export default TradeInConfirmView;
