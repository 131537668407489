import { useField } from 'formik';
import { InputField } from '@3dk/3style';
import PropTypes from 'prop-types';

const FormikInputField = ({ errorText, helperText, validate, ...props }) => {
  const [field, meta] = useField({ validate, ...props });
  const showError = !!(meta.touched && meta.error);
  return (
    <InputField
      error={showError}
      helperText={showError ? errorText : helperText}
      fullWidth
      {...field}
      value={field.value || ''}
      {...props}
    />
  );
};

FormikInputField.propTypes = {
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  validate: PropTypes.func,
};

FormikInputField.defaultProps = {
  errorText: 'Required',
  helperText: '',
  validate: () => undefined,
};

export default FormikInputField;
