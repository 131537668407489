import { gql } from '@apollo/client';

const countryListFragment = gql`
  fragment CountryListFragment on CountryList {
    title
    countries {
      country
      isEuCountry
      isEuCovered
    }
  }
`;

export default countryListFragment;
