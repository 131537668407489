import { Body, LoadingDots } from '@3dk/3style';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import generateAuthenticationUrl from '../../auth/helpers/generateAuthenticationUrl';
import AUTHENTICATION from '../../constants/authentication';
import useIsLoggedIn from '../../utils/useIsLoggedIn';
import FEATURE_FLAG from '../../utils/feature-flags';
import withOptimizelyOff from '../../utils/hoc/withOptimizelyOff';
import withSecondPass from '../../utils/hoc/withSecondPass';
import { LANDING_PAGE, SEARCH_BAR, SIM_ALONE } from '../../app/routes/pageContainer/page/constants';
import { accountLevelDiscountPropType } from '../../utils/propTypes/accountLevelDiscount';

const classes = {
  loginLink: {
    fontWeight: 'bold',
  },
};

const aldLoginView = {
  i18nKey: 'Ald.General.Login',
  defaults: 'Allerede Kunde? <login>Log ind</login> for at se evt. samlerabat.',
};

const getI18nKeyForAldInformationLandingPage = (isLoggedIn, isBetterDiscount, accountLevelDiscount) => {
  const hasDiscount = !!accountLevelDiscount;

  if (hasDiscount && isBetterDiscount && !isLoggedIn) {
    return { i18nKey: 'Ald.General.You_Can_Get_Discount' };
  }

  if (hasDiscount && !isBetterDiscount && isLoggedIn) {
    return { i18nKey: 'Ald.General.Buy_More_To_Get_Discount' };
  }

  if (!isLoggedIn) {
    return aldLoginView;
  }
  return null;
};

const getI18nKeyForAldInformation = (isLoggedIn, isBetterDiscount, accountLevelDiscountChange, configuration) => {
  if (isBetterDiscount) {
    if (isLoggedIn) {
      return {
        i18nKey: `Product_Page.${configuration}.Account_Level_Discount_Positive`,
        values: { price: Math.abs(accountLevelDiscountChange) },
      };
    }
    return {
      i18nKey: `Product_Page.${configuration}.Account_Level_Discount_Mixed`,
      values: { price: Math.abs(accountLevelDiscountChange) },
    };
  }
  if (isLoggedIn) {
    return {
      i18nKey: `Product_Page.${configuration}.Account_Level_Discount_Negative`,
    };
  }
  return aldLoginView;
};

const AldInformationView = ({ className, accountLevelDiscountPreview, useCase }) => {
  const { isLoggedIn, loginLoading } = useIsLoggedIn();
  const { accountLevelDiscount, isAldLoading, isBetterDiscount, accountLevelDiscountChange } =
    accountLevelDiscountPreview || {};

  const { pathname, search, hash } = useLocation();
  const authenticationUrl = generateAuthenticationUrl(
    AUTHENTICATION,
    [pathname, search, hash].filter(Boolean).join(''),
  );
  let transData = false;
  switch (useCase) {
    case LANDING_PAGE:
      transData = getI18nKeyForAldInformationLandingPage(isLoggedIn, isBetterDiscount, accountLevelDiscount);
      break;
    case SIM_ALONE:
      transData = getI18nKeyForAldInformation(isLoggedIn, isBetterDiscount, accountLevelDiscountChange, 'Configurator');
      break;
    case SEARCH_BAR:
      transData = getI18nKeyForAldInformation(isLoggedIn, isBetterDiscount, accountLevelDiscountChange, 'Search_Bar');
      break;
    default:
      transData = undefined;
      break;
  }

  return (
    <Body className={className}>
      {isAldLoading || loginLoading ? (
        <LoadingDots size="large" />
      ) : (
        transData && (
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
          <Trans {...transData} components={{ login: <a css={classes.loginLink} href={authenticationUrl} /> }} />
        )
      )}
    </Body>
  );
};

AldInformationView.propTypes = {
  className: PropTypes.string,
  accountLevelDiscountPreview: accountLevelDiscountPropType,
  useCase: PropTypes.oneOf([LANDING_PAGE, SIM_ALONE, SEARCH_BAR]),
};

AldInformationView.defaultProps = {
  className: undefined,
  accountLevelDiscountPreview: {},
  useCase: undefined,
};

export default withSecondPass(withOptimizelyOff(FEATURE_FLAG.ALD_DISABLED, AldInformationView, false));
