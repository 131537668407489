import { gql } from '@apollo/client';
import searchBarFragment from './searchBar';
import tabFragment from './tab';

const navigationBarFragment = gql`
  fragment NavigationBarFragment on NavigationBar {
    headline
    color
    homeButtonLink
    useFullWidth
    navigationSearchBar {
      ...SearchBarFragment
    }
    blocks {
      ...TabFragment
    }
  }
  ${tabFragment}
  ${searchBarFragment}
`;

export default navigationBarFragment;
