import { gql } from '@apollo/client';

const getLoginState = gql`
  query loginState {
    loginState {
      isLoggedIn
      loginType
    }
  }
`;
export default getLoginState;
