import { gql } from '@apollo/client';
import cartFragment from '../fragments/cart';

const recoverCartMutation = gql`
  mutation recoverCartMutation($cartId: String!) {
    recoverCart(cartId: $cartId) {
      ...Cart
    }
  }
  ${cartFragment}
`;

export default recoverCartMutation;
