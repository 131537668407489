import { IconMap, TopBanner } from '@3dk/3style';
import getUspRibbonQuery from 'apollo/queries/getUspRibbon';
import Loading from 'components/Loading';
import { useQuery } from '@apollo/client';
import { getColorFromName } from 'app/routes/pageContainer/page/Entry/Colors/Colors';

const UspRibbon = () => {
  const { data: dataRibbon, loading: loadingRibbon, error: errorRibbon } = useQuery(getUspRibbonQuery);

  if (loadingRibbon) {
    return <Loading colored />;
  }

  if (errorRibbon) {
    return null;
  }

  const uspRibbon = dataRibbon?.uspRibbon;

  const mappedUSPs = uspRibbon?.blocks.map((block) => ({
    ...block,
    icon: block?.icon && IconMap[block.icon],
    label: block?.text,
    color: getColorFromName(block?.color),
    labelColor: getColorFromName(block?.textColor),
  }));

  return <TopBanner backgroundColor={uspRibbon?.backgroundColor} usps={mappedUSPs} />;
};

export default UspRibbon;
