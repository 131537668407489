import { gql } from '@apollo/client';

const roamingCountriesQuery = gql`
  query roamingCountries {
    roamingCountries {
      attributes {
        countries {
          name
        }
      }
    }
  }
`;

export default roamingCountriesQuery;
