import { gql } from '@apollo/client';
import deliveryDetailsFragment from '../fragments/deliveryDetails';
import legacyOrderFragment from '../fragments/legacyOrder';

const getPreDcOrderSummaryQuery = gql`
  query preDcOrderSummary($orderId: ID!) {
    preDcOrderSummary(orderId: $orderId) {
      hasNemId
      orderReferenceId
      forceInvoice
      upfrontPushedToInvoice
      orderHasMissingPortingInfo
      hasNemId
      deliveryDetails {
        ...deliveryDetailsFragment
      }
      legacyCart {
        ...legacyOrderFragment
      }
    }
  }
  ${deliveryDetailsFragment}
  ${legacyOrderFragment}
`;

export default getPreDcOrderSummaryQuery;
