import { useEffect } from 'react';
import Loading from '../../components/Loading';

export const NEM_ID_FALLBACK_FORM_SUBMITTED = 'NEM_ID_FALLBACK_FORM_SUBMITTED';

const classes = {
  center: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const NemIDFallbackVerification = () => {
  const isClient = typeof window !== 'undefined';
  const targetOrigin = isClient && window?.location?.origin;

  useEffect(() => {
    window.parent.postMessage(NEM_ID_FALLBACK_FORM_SUBMITTED, targetOrigin);
  }, [targetOrigin]);

  return (
    <div css={classes.center}>
      <Loading colored />
    </div>
  );
};

export default NemIDFallbackVerification;
