import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import getOrderInfoQuery from '../../apollo/queries/getOrderInfo';
import ApolloErrorHandler from '../../components/errorHandling/ApolloErrorHandler';
import useCart from '../../utils/hooks/useCartHook';

const CustomerContainer = loadable(() => import('./orderVerificationContainer/CustomerContainer'));
const LoadingAnimation = loadable(() => import('./orderVerificationContainer/LoadingAnimation'));

const OrderVerificationContainer = () => {
  const {
    loading: isOrderInfoQueryLoading,
    error: orderInfoQueryError,
    data: orderInfoQueryData,
  } = useQuery(getOrderInfoQuery);

  const { cartData: cartQueryData, cartLoading: isCartQueryLoading, cartError: cartQueryError } = useCart();

  if (isOrderInfoQueryLoading || isCartQueryLoading) return <LoadingAnimation />;

  const error = orderInfoQueryError || cartQueryError;
  if (error) return <ApolloErrorHandler error={error} />;

  const { cart } = cartQueryData || {};

  const { getOrderInfo: orderInfo } = orderInfoQueryData || {};
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{orderInfo ? <CustomerContainer orderInfo={orderInfo} cart={cart} /> : <LoadingAnimation />}</>
  );
};

export default OrderVerificationContainer;
