import { BLOCKS } from '@contentful/rich-text-types';
import { Banner, Body, Headline, Subhead, Title } from '@3dk/3style';
import convertContentfulMarkupToElements from '../ContentfulMarkup/ContentfulMarkupProcessor';

const colorRenderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Body css={{ margin: '0 0 16px 0' }}>{convertContentfulMarkupToElements(children)}</Body>
    ),
    [BLOCKS.HEADING_2]: (node, children) => <Banner as="h2">{convertContentfulMarkupToElements(children)}</Banner>,
    [BLOCKS.HEADING_3]: (node, children) => <Headline as="h3">{convertContentfulMarkupToElements(children)}</Headline>,
    [BLOCKS.HEADING_4]: (node, children) => <Title as="h4">{convertContentfulMarkupToElements(children)}</Title>,
    [BLOCKS.HEADING_5]: (node, children) => <Subhead as="h5">{convertContentfulMarkupToElements(children)}</Subhead>,
  },
};

export default colorRenderOptions;
