const trimSlugAndRemoveStartingSlash = (slug) => {
  if (!slug) return null;

  let formattedSlug = slug.toLowerCase();

  // Removes starting slash
  if (formattedSlug.startsWith('/')) {
    formattedSlug = formattedSlug.slice(1);
  }

  // Trim string (remove white space from both ends of string)
  formattedSlug = formattedSlug.trim();

  // Trim white space inside string
  formattedSlug = formattedSlug.replace(/\s/g, '');

  return formattedSlug;
};

export default trimSlugAndRemoveStartingSlash;
