import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { error as logError } from '@hi3g-access/client-logger';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Body, Link, Subhead } from '@3dk/3style';

/* Options is used as a part of documentToReactComponents
 * See https://www.npmjs.com/package/@contentful/rich-text-react-renderer for more info
 * */
const convertRichText = (richText, options = {}) => {
  let document = null;
  let reactComponent = null;
  if (richText) {
    try {
      document = JSON.parse(richText);
      reactComponent = documentToReactComponents(document, options);
    } catch (e) {
      logError('Error converting rich text to react component', { error: e });
    }
  }

  return reactComponent;
};

/* IT maps hyperlink nodes' uri from contentful directly to data, instead of 'data.uri', as we do.
   convertRichText by default assumes the uri is in data.uri, so we need to parse an option
   that reads the uri directly from data when we get it from the "Product content" contentful space,
   which is managed by IT.
*/
const classes = {
  paragraph: {
    marginBottom: '25px',
  },
  headline: {
    marginBottom: '5px',
  },
};

// TODO(NOV-2612): Ask IT to change their data model so this fix is unnecessary
export function convertRichTextFromProductContent(richText, options = {}) {
  const productContentOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { data: richTextData } = node;
        return <Link href={richTextData}>{children}</Link>;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <Body css={classes.paragraph}>{children}</Body>,
      [BLOCKS.HEADING_3]: (node, children) => <Subhead css={classes.headline}>{children}</Subhead>,
    },
  };
  return convertRichText(richText, { ...productContentOptions, ...options });
}

export default convertRichText;
